// src/screens/AssignCareEntityScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Pagination,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { useAuth } from '../context/AuthContext';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ErrorBoundary from '../components/ErrorBoundary';
import customAlertStyles from '../styles/CustomAlertStyles';

const MySwal = withReactContent(Swal);

const AssignCareEntityScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // Permissions & Auth
  const { permissions = [], loading: authLoading } = useAuth();
  const hasViewPermission = permissions.includes('view_assign-care-entity');
  const hasEditPermission = permissions.includes('edit_assign-care-entity');

  // API Base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Loading states
  const [pageLoading, setPageLoading] = useState(true);  // Page-level loading
  const [saving, setSaving] = useState(false);

  // "Care Entity" details from back end
  const [careEntity, setCareEntity] = useState(null);

  // Radio selection: "staff" or "clients"
  const [selection, setSelection] = useState('staff');

  // Data fetched from server
  const [staffList, setStaffList] = useState([]);
  const [clientList, setClientList] = useState([]);

  // Assigned arrays
  const [assignedStaff, setAssignedStaff] = useState([]);
  const [assignedClients, setAssignedClients] = useState([]);

  // Search
  const [searchTerm, setSearchTerm] = useState('');

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  // Unsaved changes tracking
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  // --------------------------------------------------------------------------------
  //                               Data Fetching
  // --------------------------------------------------------------------------------

  // 1) Fetch the care entity (which also has assigned staff and clients)
  const fetchCareEntity = useCallback(async () => {
    if (!hasViewPermission) {
      // If no permission, block with an alert and redirect
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      }).then(() => {
        navigate('/home');
      });
      setPageLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/careentities/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const entityData = response.data || {};
      setCareEntity(entityData);

      // Pre-populate assigned staff/clients from server data
      setAssignedStaff(entityData.assignedStaff || []);
      setAssignedClients(entityData.assignedClients || []);
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch care entity data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setPageLoading(false);
    }
  }, [API_BASE_URL, id, hasViewPermission, navigate]);

  // 2) Fetch staff (filtered by ?role=staff) - returns { users: [...], total: number }
  const fetchStaff = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/users?role=staff`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStaffList(response.data.users || []);
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch staff data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  // 3) Fetch all clients - the back end returns an array directly
  const fetchClients = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/clients`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // The route returns an array of clients directly
      setClientList(response.data || []);
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch client data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  // On mount, fetch data
  useEffect(() => {
    fetchCareEntity();
    fetchStaff();
    fetchClients();
  }, [fetchCareEntity, fetchStaff, fetchClients]);

  // --------------------------------------------------------------------------------
  //                     BeforeUnload & Unsaved Changes Management
  // --------------------------------------------------------------------------------

  // Warn user about unsaved changes when leaving
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (unsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [unsavedChanges]);

  // --------------------------------------------------------------------------------
  //                        UI Interaction Handlers
  // --------------------------------------------------------------------------------

  // 1) Clicking "Save Assignments"
  const handleSaveAssignments = async () => {
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to edit care entities.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');
      const staffIds = assignedStaff.map((s) => s._id);
      const clientIds = assignedClients.map((c) => c._id);

      const payload = { staff: staffIds, clients: clientIds };
      await axios.post(`${API_BASE_URL}/api/careentities/${id}/assign`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      setUnsavedChanges(false);
      MySwal.fire({
        title: 'Success',
        text: 'Care assignments updated!',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Failed to update care assignments.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // 2) Clicking "Back" with possible unsaved changes
  const handleBackClick = () => {
    if (unsavedChanges) {
      MySwal.fire({
        title: 'Unsaved Changes',
        text: 'You have unsaved changes. Are you sure you want to leave?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, leave',
        cancelButtonText: 'No, stay',
        ...customAlertStyles.sweetAlert,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(-1);
        }
      });
    } else {
      navigate(-1);
    }
  };

  // 3) Updating selection radio
  const handleSelectionChange = (event) => {
    setSelection(event.target.value);
    setCurrentPage(1); // reset page on selection change
    setSearchTerm(''); // clear search
  };

  // 4) Checking/unchecking an item
  const handleAssignToggle = (record) => {
    setUnsavedChanges(true);

    if (selection === 'staff') {
      // If record is already assigned, remove it. Otherwise add it.
      const isAssigned = assignedStaff.some((st) => st._id === record._id);
      if (isAssigned) {
        setAssignedStaff((prev) => prev.filter((st) => st._id !== record._id));
      } else {
        setAssignedStaff((prev) => [...prev, record]);
      }
    } else {
      const isAssigned = assignedClients.some((cl) => cl._id === record._id);
      if (isAssigned) {
        setAssignedClients((prev) => prev.filter((cl) => cl._id !== record._id));
      } else {
        setAssignedClients((prev) => [...prev, record]);
      }
    }
  };

  // --------------------------------------------------------------------------------
  //                           Derived Data & Table
  // --------------------------------------------------------------------------------

  // 1) Retrieve the relevant data array
  const getDataList = () => {
    return selection === 'staff' ? staffList : clientList;
  };

  // 2) Retrieve assigned array
  const getAssignedList = () => {
    return selection === 'staff' ? assignedStaff : assignedClients;
  };

  // 3) Filter & sort data
  const getFilteredRecords = () => {
    const data = getDataList();
    const assigned = getAssignedList();

    // Filter by searchTerm
    const filtered = data.filter((item) => {
      const name = item.firstName
        ? `${item.firstName} ${item.lastName}`
        : item.name || '';
      return name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    // Sort so assigned items appear at top
    const assignedItems = filtered.filter((item) =>
      assigned.some((a) => a._id === item._id)
    );
    const unassignedItems = filtered.filter(
      (item) => !assigned.some((a) => a._id === item._id)
    );

    // Alphabetical within each group
    assignedItems.sort((a, b) => {
      const aName = a.firstName
        ? `${a.firstName} ${a.lastName}`.toLowerCase()
        : (a.name || '').toLowerCase();
      const bName = b.firstName
        ? `${b.firstName} ${b.lastName}`.toLowerCase()
        : (b.name || '').toLowerCase();
      return aName.localeCompare(bName);
    });
    unassignedItems.sort((a, b) => {
      const aName = a.firstName
        ? `${a.firstName} ${a.lastName}`.toLowerCase()
        : (a.name || '').toLowerCase();
      const bName = b.firstName
        ? `${b.firstName} ${b.lastName}`.toLowerCase()
        : (b.name || '').toLowerCase();
      return aName.localeCompare(bName);
    });

    return [...assignedItems, ...unassignedItems];
  };

  // 4) Pagination
  const getPaginatedRecords = () => {
    const allRecords = getFilteredRecords();
    const indexOfLast = currentPage * recordsPerPage;
    const indexOfFirst = indexOfLast - recordsPerPage;
    return {
      totalCount: allRecords.length,
      pageRecords: allRecords.slice(indexOfFirst, indexOfLast),
    };
  };

  // --------------------------------------------------------------------------------
  //                             Render Functions
  // --------------------------------------------------------------------------------

  // Single table that changes based on `selection` (staff/clients)
  const renderAssignmentTable = () => {
    const { totalCount, pageRecords } = getPaginatedRecords();

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell width="100" align="center">Assigned?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageRecords.map((item) => {
              // item can be staff or client
              const name = item.firstName
                ? `${item.firstName} ${item.lastName}`
                : item.name || 'Unnamed';
              const assigned = getAssignedList().some((a) => a._id === item._id);

              return (
                <TableRow key={item._id}>
                  <TableCell>{name}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={assigned}
                      onChange={() => handleAssignToggle(item)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}

            {pageRecords.length === 0 && (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {/* Pagination */}
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
          <Pagination
            count={Math.ceil(totalCount / recordsPerPage)}
            page={currentPage}
            onChange={(e, value) => setCurrentPage(value)}
            color="primary"
            shape="rounded"
          />
        </Box>
      </TableContainer>
    );
  };

  // --------------------------------------------------------------------------------
  //                               Main Render
  // --------------------------------------------------------------------------------

  if (pageLoading || authLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!hasViewPermission) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" color="error">
          You do not have permission to view this page.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <NavigationBar />
      <Box display="flex">
        <Sidebar />

        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
          {/* Header Section */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 3,
            }}
          >
            <IconButton onClick={handleBackClick} aria-label="Go Back">
              <ArrowBackIcon />
            </IconButton>

            <Typography variant="h5">
              {careEntity
                ? `Assign Staff & Clients to ${careEntity.name}`
                : 'Assign Staff & Clients'}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveAssignments}
              disabled={!hasEditPermission || saving}
              startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
            >
              {saving ? 'Saving...' : 'Save Assignments'}
            </Button>
          </Box>

          {/* Summary of assigned */}
          <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
            <Typography variant="subtitle1">
              Assigned Staff: {assignedStaff.length}
            </Typography>
            <Typography variant="subtitle1">
              Assigned Clients: {assignedClients.length}
            </Typography>
          </Box>

          {/* Radio selection for "staff" or "clients" */}
          <FormControl component="fieldset" sx={{ marginBottom: 3 }}>
            <FormLabel component="legend">Assign Type</FormLabel>
            <RadioGroup
              row
              value={selection}
              onChange={handleSelectionChange}
              sx={{ marginTop: 1 }}
            >
              <FormControlLabel
                value="staff"
                control={<Radio />}
                label="Staff"
              />
              <FormControlLabel
                value="clients"
                control={<Radio />}
                label="Clients"
              />
            </RadioGroup>
          </FormControl>

          {/* Search Bar */}
          <Box sx={{ display: 'flex', marginBottom: 2 }}>
            <TextField
              label={`Search ${selection === 'staff' ? 'Staff' : 'Clients'}`}
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1); // reset page
              }}
            />
          </Box>

          {/* Main Table */}
          {renderAssignmentTable()}
        </Box>
      </Box>
    </>
  );
};

// Wrap with ErrorBoundary
const WrappedAssignCareEntityScreen = () => (
  <ErrorBoundary>
    <AssignCareEntityScreen />
  </ErrorBoundary>
);

export default WrappedAssignCareEntityScreen;