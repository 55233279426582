// src/components/Sidebar.js

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { 
  Drawer, 
  List as MUIList, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Divider, 
  Box, 
  IconButton, 
  Collapse 
} from '@mui/material';
import { 
  Dashboard, 
  Group, 
  Build, 
  Person, 
  Business, 
  Visibility, 
  ListAlt, 
  TableChart, 
  Menu, 
  Assignment, 
  People, 
  List as ListIcon, 
  ExpandLess, 
  ExpandMore, 
  Label,
  Description, 
  Save 
} from '@mui/icons-material';
import { styles } from '../styles/styles';
import { useAuth } from '../context/AuthContext';
import { useTheme, useMediaQuery } from '@mui/material';

const Sidebar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust breakpoint as needed
  const [isCollapsed, setIsCollapsed] = useState(isMobile);
  const [configOpen, setConfigOpen] = useState(false);
  const [reportsOpen, setReportsOpen] = useState(false);

  const { permissions = [] } = useAuth();

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleConfigClick = () => {
    setConfigOpen(!configOpen);
  };

  const handleReportsClick = () => {
    setReportsOpen(!reportsOpen);
  };

  // Update isCollapsed state when screen size changes
  useEffect(() => {
    setIsCollapsed(isMobile);
  }, [isMobile]);

  // Helper function to check if user has permission
  const hasPermission = (perm) => permissions.includes(perm);

  // Define which permissions map to which menu items
  // Top-level items
  const showDashboard = true; // No permission check required based on instructions
  const showCareNavigator = hasPermission('view_care-navigator');
  const showMyTeam = hasPermission('view_my-team');
  const showProfile = hasPermission('view_profile');
  const showTemplateManagement = hasPermission('view_template-management');

  // Configuration section items
  const showClients = hasPermission('view_clients');
  const showUserManagement = hasPermission('view_user-management');
  const showCareEntities = hasPermission('view_care-entities');
  const showRoleManager = hasPermission('view_role-manager');
  const showMasterListManagement = hasPermission('view_master-list-management');
  const showCustomTableManagement = hasPermission('view_custom-table-management');
  const showCustomClientFields = hasPermission('view_custom-client-fields'); // Adjust if different
  const showProcessLabelManagement = hasPermission('view_process-label-management');

  // Reports section items
  const showCareVisualizer = hasPermission('view_care-visualizer');

  // Determine if we should show configuration header
  const anyConfigItemVisible = 
    showClients || 
    showUserManagement ||
    showCareEntities ||
    showRoleManager ||
    showMasterListManagement ||
    showCustomTableManagement ||
    showCustomClientFields ||
    showProcessLabelManagement;

  // Determine if we should show reports header
  const anyReportsItemVisible = showCareVisualizer;

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isCollapsed ? 72 : 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: isCollapsed ? 72 : 240,
          boxSizing: 'border-box',
          backgroundColor: styles.sidebarBackground,
          color: '#FFF',
          top: '64px',
          height: 'calc(100% - 64px)',
          transition: 'width 0.3s',
          overflowX: 'hidden', // Prevent horizontal scroll when collapsed
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: isCollapsed ? 'center' : 'flex-end', padding: 2 }}>
        <IconButton onClick={toggleSidebar} sx={{ color: '#FFF' }} aria-label={isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}>
          <Menu />
        </IconButton>
      </Box>

      <MUIList>
        {/* Dashboard */}
        {showDashboard && (
          <ListItem button component={NavLink} to="/home" exact="true">
            <ListItemIcon sx={{ color: '#FFF' }}><Dashboard /></ListItemIcon>
            {!isCollapsed && <ListItemText primary="Dashboard" />}
          </ListItem>
        )}

        {/* Care Navigator */}
        {showCareNavigator && (
          <ListItem button component={NavLink} to="/care-navigator" exact="true">
            <ListItemIcon sx={{ color: '#FFF' }}><Assignment /></ListItemIcon>
            {!isCollapsed && <ListItemText primary="Care Navigator" />}
          </ListItem>
        )}

        {/* My Team */}
        {showMyTeam && (
          <ListItem button component={NavLink} to="/my-team" exact="true">
            <ListItemIcon sx={{ color: '#FFF' }}><People /></ListItemIcon>
            {!isCollapsed && <ListItemText primary="My Team" />}
          </ListItem>
        )}

        {/* Profile */}
        {showProfile && (
          <ListItem button component={NavLink} to="/profile" exact="true">
            <ListItemIcon sx={{ color: '#FFF' }}><Person /></ListItemIcon>
            {!isCollapsed && <ListItemText primary="Profile" />}
          </ListItem>
        )}

        {/* Template Management */}
        {showTemplateManagement && (
          <ListItem button component={NavLink} to="/template-management" exact="true">
            <ListItemIcon sx={{ color: '#FFF' }}><Build /></ListItemIcon>
            {!isCollapsed && <ListItemText primary="Template Management" />}
          </ListItem>
        )}

        {/* Configuration Section */}
        {anyConfigItemVisible && (
          <>
            <ListItem button onClick={handleConfigClick} aria-haspopup="true" aria-expanded={configOpen}>
              <ListItemIcon sx={{ color: '#FFF' }}><Build /></ListItemIcon>
              {!isCollapsed && <ListItemText primary="Configuration" />}
              {!isCollapsed && (configOpen ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            <Collapse in={configOpen && !isCollapsed} timeout="auto" unmountOnExit>
              <MUIList component="div" disablePadding>
                {showClients && (
                  <ListItem button component={NavLink} to="/clients" sx={{ pl: 4 }}>
                    <ListItemIcon sx={{ color: '#FFF' }}><Group /></ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Clients" />}
                  </ListItem>
                )}
                {showUserManagement && (
                  <ListItem button component={NavLink} to="/user-management" sx={{ pl: 4 }}>
                    <ListItemIcon sx={{ color: '#FFF' }}><Person /></ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Users" />}
                  </ListItem>
                )}
                {showCareEntities && (
                  <ListItem button component={NavLink} to="/care-entities" sx={{ pl: 4 }}>
                    <ListItemIcon sx={{ color: '#FFF' }}><Business /></ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Care Entities" />}
                  </ListItem>
                )}
                {showRoleManager && (
                  <ListItem button component={NavLink} to="/role-manager" sx={{ pl: 4 }}>
                    <ListItemIcon sx={{ color: '#FFF' }}><Build /></ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Roles" />}
                  </ListItem>
                )}
                {showMasterListManagement && (
                  <ListItem button component={NavLink} to="/master-list-management" sx={{ pl: 4 }}>
                    <ListItemIcon sx={{ color: '#FFF' }}><ListAlt /></ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Master Lists" />}
                  </ListItem>
                )}
                {showCustomTableManagement && (
                  <ListItem button component={NavLink} to="/custom-table-management" sx={{ pl: 4 }}>
                    <ListItemIcon sx={{ color: '#FFF' }}><TableChart /></ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Custom Tables" />}
                  </ListItem>
                )}
                {showCustomClientFields && (
                  <ListItem button component={NavLink} to="/custom-client-fields" sx={{ pl: 4 }}>
                    <ListItemIcon sx={{ color: '#FFF' }}><ListIcon /></ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Client Custom Fields" />}
                  </ListItem>
                )}
                {showProcessLabelManagement && (
                  <ListItem button component={NavLink} to="/process-label-management" sx={{ pl: 4 }}>
                    <ListItemIcon sx={{ color: '#FFF' }}><Label /></ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Process Label Management" />}
                  </ListItem>
                )}
              </MUIList>
            </Collapse>
          </>
        )}

        <Divider />

        {/* Reports Section */}
        {anyReportsItemVisible && (
          <>
            <ListItem button onClick={handleReportsClick} aria-haspopup="true" aria-expanded={reportsOpen}>
              <ListItemIcon sx={{ color: '#FFF' }}><Visibility /></ListItemIcon>
              {!isCollapsed && <ListItemText primary="Reports" />}
              {!isCollapsed && (reportsOpen ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            <Collapse in={reportsOpen && !isCollapsed} timeout="auto" unmountOnExit>
              <MUIList component="div" disablePadding>
                {showCareVisualizer && (
                  <ListItem button component={NavLink} to="/care-visualizer" sx={{ pl: 4 }}>
                    <ListItemIcon sx={{ color: '#FFF' }}><Visibility /></ListItemIcon>
                    {!isCollapsed && <ListItemText primary="Care Visualizer" />}
                  </ListItem>
                )}
                {/* Custom Reports */}
                <ListItem button component={NavLink} to="/reports" sx={{ pl: 4 }}>
                  <ListItemIcon sx={{ color: '#FFF' }}><Description /></ListItemIcon>
                  {!isCollapsed && <ListItemText primary="Manage Custom Reports" />}
                </ListItem>
                {/* Saved Reports */}
                <ListItem button component={NavLink} to="/saved-reports" sx={{ pl: 4 }}>
                  <ListItemIcon sx={{ color: '#FFF' }}><Save /></ListItemIcon>
                  {!isCollapsed && <ListItemText primary="Saved Reports" />}
                </ListItem>
              </MUIList>
            </Collapse>
          </>
        )}
      </MUIList>
    </Drawer>
  );
};

export default Sidebar;