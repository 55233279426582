// src/components/SavedReports.js

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  PlayArrow as PlayArrowIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import ReactJson from 'react-json-view';
import * as XLSX from 'xlsx';

// Helper functions for CSV export
function convertToCSV(data) {
  if (!Array.isArray(data) || data.length === 0) return '';
  const headers = Object.keys(data[0]);
  const rows = data.map((row) =>
    headers
      .map((field) => {
        const value = row[field];
        if (Array.isArray(value)) {
          return `"${value.map((item) => JSON.stringify(item)).join('; ')}"`;
        } else if (typeof value === 'object' && value !== null) {
          return `"${JSON.stringify(value)}"`;
        } else {
          return `"${value ?? ''}"`;
        }
      })
      .join(',')
  );
  return [headers.join(','), ...rows].join('\n');
}

function downloadFile(content, fileName, mimeType) {
  const blob = new Blob([content], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

const SavedReports = () => {
  // State variables
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [selectedReport, setSelectedReport] = useState(null);
  const [viewDesignOpen, setViewDesignOpen] = useState(false);

  const [runLoading, setRunLoading] = useState(false);
  const [runResults, setRunResults] = useState(null);
  const [runError, setRunError] = useState('');
  const [runResultsOpen, setRunResultsOpen] = useState(false); // New State Variable

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Fetch saved reports on component mount
  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    setLoading(true);
    setError('');
    try {
      const res = await axios.get('/api/reports');
      const data = res.data.data;
      setReports(Array.isArray(data) ? data : []);
    } catch (err) {
      console.error('Error fetching reports:', err);
      setError('Failed to fetch saved reports.');
    } finally {
      setLoading(false);
    }
  };

  // Handle viewing report design
  const handleViewDesign = useCallback((report) => {
    setSelectedReport(report);
    setViewDesignOpen(true);
  }, []);

  const handleCloseViewDesign = () => {
    setSelectedReport(null);
    setViewDesignOpen(false);
  };

  // Handle running a report
  const handleRunReport = useCallback(async (report) => {
    setRunLoading(true);
    setRunError('');
    setRunResults(null);
    try {
      const res = await axios.post(`/api/reports/run/${report._id}`);
      const data = res.data.data;
      setRunResults(data);
      setRunResultsOpen(true); // Open the Results Dialog
      setSnackbar({ open: true, message: 'Report executed successfully!', severity: 'success' });
    } catch (err) {
      console.error('Error running report:', err);
      const serverMessage = err.response?.data?.error || 'Failed to execute report.';
      setRunError(serverMessage);
      setSnackbar({ open: true, message: serverMessage, severity: 'error' });
    } finally {
      setRunLoading(false);
    }
  }, []);

  // Handle deleting a report
  const handleDeleteReport = useCallback((report) => {
    setReportToDelete(report);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDeleteReport = async () => {
    if (!reportToDelete) return;
    try {
      await axios.delete(`/api/reports/${reportToDelete._id}`);
      setSnackbar({ open: true, message: 'Report deleted successfully!', severity: 'success' });
      fetchReports();
    } catch (err) {
      console.error('Error deleting report:', err);
      const serverMessage = err.response?.data?.error || 'Failed to delete report.';
      setSnackbar({ open: true, message: serverMessage, severity: 'error' });
    } finally {
      setDeleteDialogOpen(false);
      setReportToDelete(null);
    }
  };

  const cancelDeleteReport = () => {
    setDeleteDialogOpen(false);
    setReportToDelete(null);
  };

  // Handle exporting report results
  const handleExportCSV = () => {
    if (!Array.isArray(runResults) || runResults.length === 0) {
      setSnackbar({ open: true, message: 'No data available to export.', severity: 'warning' });
      return;
    }
    const csv = convertToCSV(runResults);
    downloadFile(csv, 'report.csv', 'text/csv');
    setSnackbar({ open: true, message: 'CSV exported successfully!', severity: 'success' });
  };

  const handleExportExcel = () => {
    if (!Array.isArray(runResults) || runResults.length === 0) {
      setSnackbar({ open: true, message: 'No data available to export.', severity: 'warning' });
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(runResults);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    downloadFile(
      excelBuffer,
      'report.xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    setSnackbar({ open: true, message: 'Excel exported successfully!', severity: 'success' });
  };

  // Columns for Saved Reports DataGrid
  const savedReportsColumns = useMemo(() => [
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
    { field: 'description', headerName: 'Description', flex: 2, minWidth: 200 },
    { 
      field: 'createdBy', 
      headerName: 'Created By', 
      flex: 1, 
      minWidth: 150, 
      valueGetter: (params) => {
        if (!params || !params.row) return '';
        const firstName = params.row.createdBy?.firstName || '';
        const lastName = params.row.createdBy?.lastName || '';
        return `${firstName} ${lastName}`.trim();
      } 
    },
    { 
      field: 'createdAt', 
      headerName: 'Created At', 
      flex: 1, 
      minWidth: 180, 
      valueFormatter: (params) => {
        if (!params || !params.value) return '';
        return new Date(params.value).toLocaleString();
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      getActions: (params) => {
        if (!params || !params.row) return [];
        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View Design"
            onClick={() => handleViewDesign(params.row)}
            showInMenu
            aria-label={`View design of ${params.row.name}`}
            key="view-design"
          />,
          <GridActionsCellItem
            icon={<PlayArrowIcon />}
            label="Run Report"
            onClick={() => handleRunReport(params.row)}
            showInMenu
            aria-label={`Run ${params.row.name}`}
            key="run-report"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteReport(params.row)}
            showInMenu
            aria-label={`Delete ${params.row.name}`}
            key="delete-report"
          />,
        ];
      },
    },
  ], [handleViewDesign, handleRunReport, handleDeleteReport]);

  // Rows for Saved Reports DataGrid
  const savedReportsRows = useMemo(() => reports
    .filter(report => report && report._id) // Ensure reports have _id
    .map((report) => ({
      id: report._id,
      ...report,
    })), [reports]);

  // Columns for Run Report Results DataGrid
  const runReportColumns = useMemo(() => {
    if (!runResults || runResults.length === 0) return [];
    return Object.keys(runResults[0]).map((key) => ({
      field: key,
      headerName: key.charAt(0).toUpperCase() + key.slice(1),
      flex: 1,
      minWidth: 150,
      sortable: true,
      renderCell: (params) => {
        if (Array.isArray(params.value)) {
          return params.value.join(', ');
        }
        if (typeof params.value === 'object' && params.value !== null) {
          return JSON.stringify(params.value);
        }
        return params.value;
      },
    }));
  }, [runResults]);

  // Rows for Run Report Results DataGrid
  const runReportRows = useMemo(() => {
    if (!runResults || runResults.length === 0) return [];
    return runResults.map((row, idx) => ({
      id: row._id || `row-${idx}`,
      ...row,
    }));
  }, [runResults]);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Saved Reports
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={savedReportsRows}
            columns={savedReportsColumns}
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50]}
            disableSelectionOnClick
            autoHeight
            sx={{
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                lineHeight: '1.5 !important',
                maxWidth: 'unset !important',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#f5f5f5',
              },
            }}
          />
        </Box>
      )}

      {/* View Design Dialog */}
      <Dialog
        open={viewDesignOpen}
        onClose={handleCloseViewDesign}
        fullWidth
        maxWidth="md"
        aria-labelledby="view-design-dialog-title"
      >
        <DialogTitle id="view-design-dialog-title">
          Report Design: {selectedReport?.name}
        </DialogTitle>
        <DialogContent dividers>
          {selectedReport ? (
            <ReactJson
              src={selectedReport.configuration}
              name={null}
              collapsed={false}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          ) : (
            <Typography>No report selected.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewDesign} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Run Report Results Dialog */}
      <Dialog
        open={runResultsOpen}
        onClose={() => setRunResultsOpen(false)}
        fullWidth
        maxWidth="lg"
        aria-labelledby="run-report-dialog-title"
      >
        <DialogTitle id="run-report-dialog-title">
          Report Results
        </DialogTitle>
        <DialogContent dividers>
          {runLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : runError ? (
            <Alert severity="error">{runError}</Alert>
          ) : runResults && Array.isArray(runResults) && runResults.length > 0 ? (
            <>
              <Box sx={{ height: 500, width: '100%' }}>
                <DataGrid
                  rows={runReportRows}
                  columns={runReportColumns}
                  pageSize={10}
                  rowsPerPageOptions={[10, 25, 50]}
                  disableSelectionOnClick
                  autoHeight
                  sx={{
                    '& .MuiDataGrid-cell': {
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      lineHeight: '1.5 !important',
                      maxWidth: 'unset !important',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: '#f5f5f5',
                    },
                  }}
                />
              </Box>
              <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  onClick={handleExportCSV}
                  disabled={runResults.length === 0}
                >
                  Export CSV
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  onClick={handleExportExcel}
                  disabled={runResults.length === 0}
                >
                  Export Excel
                </Button>
              </Box>
            </>
          ) : (
            <Typography>No results found.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRunResultsOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={cancelDeleteReport}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Delete Report</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the report "{reportToDelete?.name}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteReport} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteReport} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default SavedReports;