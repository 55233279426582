// src/components/CareEntityCarePlans.jsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  CircularProgress
} from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from './ErrorBoundary';

const CareEntityCarePlans = ({ careEntityID }) => {
  const navigate = useNavigate();
  const { permissions = [], loading: authLoading } = useAuth();

  const [careEntity, setCareEntity] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOption, setFilterOption] = useState('Active');
  const [processLabels, setProcessLabels] = useState([]);
  const [selectedTab, setSelectedTab] = useState('all');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loadingEntity, setLoadingEntity] = useState(true);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Permission check
  const hasPermissionToView = (templateID) => {
    return permissions.some((perm) => perm.endsWith(`_${templateID}`)) || true;
  };

  // Check if care plan is in its active period
  const isCarePlanActivePeriod = (carePlan) => {
    if (carePlan.activePeriod === 'permanent') {
      return true;
    }
    if (carePlan.activePeriod === 'temporary') {
      const now = new Date();
      const start = new Date(carePlan.startDate);
      const end = new Date(carePlan.endDate);
      return now >= start && now <= end;
    }
    return false;
  };

  // Determine if a care plan should be visible
  const isCarePlanVisible = (carePlan) => {
    if (carePlan.isDraft) return false;

    let matchesFilter = false;
    if (filterOption === 'Active') {
      matchesFilter =
        carePlan.isActive &&
        !carePlan.isArchive &&
        isCarePlanActivePeriod(carePlan);
    } else if (filterOption === 'Archived') {
      matchesFilter = carePlan.isArchive;
    } else if (filterOption === 'All') {
      matchesFilter = true;
    }

    if (!matchesFilter) return false;

    const assignedProcessLabels = carePlan.assignedProcessLabels || [];
    const processLabelIds = assignedProcessLabels.map((label) =>
      typeof label === 'object' ? label._id : label
    );

    if (selectedTab === 'other') {
      if (processLabelIds.length > 0) return false;
    } else if (selectedTab !== 'all') {
      if (!processLabelIds.includes(selectedTab)) return false;
    }

    if (searchTerm) {
      const desc = carePlan.description || '';
      if (!desc.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }
    }

    return hasPermissionToView(carePlan._id);
  };

  // Fetch entity (with "/:id/populate")
  const fetchCareEntity = useCallback(async () => {
    setLoadingEntity(true);
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(
        `${API_BASE_URL}/api/careentities/${careEntityID}/populate`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCareEntity(response.data);
    } catch (error) {
      console.error('[CareEntityCarePlans] Error fetching care entity:', error);
    } finally {
      setLoadingEntity(false);
    }
  }, [careEntityID, API_BASE_URL]);

  // Fetch process labels
  const fetchProcessLabels = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/processlabels`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProcessLabels(response.data);
    } catch (error) {
      console.error('[CareEntityCarePlans] Error fetching process labels:', error);
    }
  }, [API_BASE_URL]);

  useEffect(() => {
    fetchCareEntity();
    fetchProcessLabels();
  }, [fetchCareEntity, fetchProcessLabels]);

  // Navigate to static or dynamic screen, passing careEntityID as param
  const handleOpenCarePlan = (templateID, isStatic) => {
    if (isStatic) {
      // Use the entity route
      navigate(`/static-care-plan-entity/${templateID}/${careEntityID}`);
    } else {
      // Use the entity route
      navigate(`/dynamic-care-plan-entity/${templateID}/${careEntityID}`);
    }
  };

  if (authLoading || loadingEntity) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const directCarePlans = careEntity.assignedCarePlanTemplates || [];
  const visibleDirectCarePlans = directCarePlans.filter(isCarePlanVisible);

  const tabs = [
    { label: 'All', value: 'all' },
    ...processLabels.map((label) => ({ label: label.name, value: label._id })),
    { label: 'Other', value: 'other' },
  ];

  const paginatedDirectCarePlans = visibleDirectCarePlans.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ marginTop: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <TextField
          label="Search Care Plans"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginRight: 2 }}
        />
        <FormControl variant="outlined" sx={{ marginRight: 2 }}>
          <InputLabel id="filter-label">Filter</InputLabel>
          <Select
            labelId="filter-label"
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
            label="Filter"
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Archived">Archived</MenuItem>
            <MenuItem value="All">All</MenuItem>
          </Select>
        </FormControl>
        {tabs.length > 2 && (
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            aria-label="Process Tabs"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ flexGrow: 1 }}
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        )}
      </Box>

      <Typography variant="h6">Care Entity Care Plans</Typography>
      <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ py: 1 }}>Description</TableCell>
              <TableCell sx={{ py: 1 }}>Type</TableCell>
              <TableCell sx={{ py: 1 }}>Update Freq.</TableCell>
              <TableCell sx={{ py: 1 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedDirectCarePlans.length > 0 ? (
              paginatedDirectCarePlans.map((carePlan, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ py: 1 }}>{carePlan.description}</TableCell>
                  <TableCell sx={{ py: 1 }}>
                    {carePlan.isStatic ? 'Static' : 'Dynamic'}
                  </TableCell>
                  <TableCell sx={{ py: 1 }}>
                    {carePlan.updateFrequency || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ py: 1 }}>
                    <IconButton
                      onClick={() => handleOpenCarePlan(carePlan._id, carePlan.isStatic)}
                    >
                      <OpenInNew />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>No care plans assigned</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={visibleDirectCarePlans.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Box>
  );
};

const WrappedCareEntityCarePlans = (props) => (
  <ErrorBoundary>
    <CareEntityCarePlans {...props} />
  </ErrorBoundary>
);

export default WrappedCareEntityCarePlans;