// src/screens/SavedReportsScreen.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ErrorBoundary from '../components/ErrorBoundary';
import SavedReports from '../components/SavedReports';

const SavedReportsScreen = () => {
  return (
    <ErrorBoundary>
      <NavigationBar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            marginTop: '64px', // Adjust if your navbar height differs
            width: { sm: `calc(100% - 240px)` }, // Adjust based on sidebar width
          }}
        >
          <SavedReports />
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default SavedReportsScreen;