// src/components/Navbar.js

import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Box, 
  Menu, 
  MenuItem, 
  Fade 
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { NavLink } from 'react-router-dom';
import logo from '../shared/logo.png';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';

const Navbar = () => {
  const { logout } = useAuth(); // Get logout function from AuthContext
  const [permissions, setPermissions] = useState([]); // User permissions state

  // Anchor elements for menus
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);

  // Menu "open" states
  const isAccountMenuOpen = Boolean(accountAnchorEl);
  const isNotificationsMenuOpen = Boolean(notificationsAnchorEl);

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Fetch user permissions on component mount
  useEffect(() => {
    const fetchPermissions = async () => {
      const token = localStorage.getItem('userToken');
      if (!token) {
        logout(); // Log out if no token is available
        return;
      }

      try {
        const response = await axios.get(`${API_BASE_URL}/api/roles/user/permissions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPermissions(response.data.permissions || []);
      } catch (error) {
        console.error('Error fetching permissions:', error);
        if (error.response && error.response.status === 401) {
          logout(); // Log out if token is expired or invalid
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Failed to fetch user permissions. Please try again later.',
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
        }
      }
    };

    fetchPermissions();
  }, [logout, API_BASE_URL]);

  // Check if the user has "view_profile" permission
  const hasViewPermission = (resource) => {
    return permissions.includes(`view_${resource}`);
  };

  // Handlers for Account menu
  const handleAccountMenuOpen = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountAnchorEl(null);
  };

  // Handlers for Notifications menu
  const handleNotificationsMenuOpen = (event) => {
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleNotificationsMenuClose = () => {
    setNotificationsAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 1201,
        background: 'linear-gradient(to right, white, #549F93)',
        boxShadow: 'none',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Logo */}
        <Box
          component="img"
          src={logo}
          alt="CarePlan AI"
          sx={{ height: 50 }}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* Notifications Icon & Menu */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="notifications"
            onClick={handleNotificationsMenuOpen}
          >
            <NotificationsIcon />
          </IconButton>
          <Menu
            anchorEl={notificationsAnchorEl}
            open={isNotificationsMenuOpen}
            onClose={handleNotificationsMenuClose}
            TransitionComponent={Fade}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              elevation: 3,
              sx: { mt: 1.5, minWidth: 200 },
            }}
          >
            <MenuItem disabled>No new notifications</MenuItem>
          </Menu>

          {/* Account Icon & Menu */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="account"
            onClick={handleAccountMenuOpen}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={accountAnchorEl}
            open={isAccountMenuOpen}
            onClose={handleAccountMenuClose}
            TransitionComponent={Fade}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              elevation: 3,
              sx: { mt: 1.5, minWidth: 200 },
            }}
          >
            {hasViewPermission('profile') && (
              <MenuItem
                component={NavLink}
                to="/profile"
                onClick={handleAccountMenuClose}
              >
                Profile
              </MenuItem>
            )}
            <MenuItem
              component={NavLink}
              to="/password-and-security"
              onClick={handleAccountMenuClose}
            >
              Password and Security
            </MenuItem>
          </Menu>

          {/* Logout Button */}
          <IconButton edge="end" color="inherit" aria-label="logout" onClick={logout}>
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;