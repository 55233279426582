// src/screens/ClientProfileScreen.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Collapse,
  Button,
  Avatar,
  Slider
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ClientCarePlans from '../components/ClientCarePlans';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

// Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import customAlertStyles from '../styles/CustomAlertStyles';

import AvatarEditor from 'react-avatar-editor';

// --- Phone number formatter function ---
function formatPhoneNumber(value) {
  const cleaned = (value || '').replace(/[^\d]/g, '');
  const length = cleaned.length;

  if (length < 4) {
    return cleaned;
  } else if (length < 7) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  } else {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
  }
}

// --- Helper to extract the blob name from a full Azure URL (strip query params) ---
function extractBlobName(fileUrl) {
  if (!fileUrl) return '';
  const urlWithoutQuery = fileUrl.split('?')[0];
  return urlWithoutQuery.split('/').pop();
}

// --- Utility function to convert dataURL to Blob ---
function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',');
  const mimeMatch = arr[0].match(/:(.*?);/);
  if (!mimeMatch) return null;
  const mime = mimeMatch[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const MySwal = withReactContent(Swal);

const ClientProfileScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { permissions = [], loading: authLoading } = useAuth();

  // Main client data
  const [client, setClient] = useState({});

  // Custom Fields
  const [customFields, setCustomFields] = useState([]);

  // UI state
  const [activeTab, setActiveTab] = useState(0);
  const [openAddMedication, setOpenAddMedication] = useState(false);
  const [newMedication, setNewMedication] = useState({ name: '', dosage: '', frequency: '' });
  const [openAddAllergy, setOpenAddAllergy] = useState(false);
  const [newAllergy, setNewAllergy] = useState('');
  const [openAddCondition, setOpenAddCondition] = useState(false);
  const [newCondition, setNewCondition] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);

  // Assigned care entities
  const [assignedCareEntities, setAssignedCareEntities] = useState([]);

  // Profile picture cropping
  const [profileImage, setProfileImage] = useState(null); // The raw file
  const [previewImage, setPreviewImage] = useState(null); // Base64 preview for AvatarEditor
  const [scale, setScale] = useState(1);
  const editorRef = useRef(null);

  // Permissions
  const hasViewPermission = permissions.includes('view_client-profile');
  const hasEditPermission = permissions.includes('edit_client-profile');

  // Env variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // ---------------------------
  //  Fetch Data
  // ---------------------------
  const fetchClient = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/clients/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setClient(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching client:', error);
      setLoading(false);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch client data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    }
  }, [id, API_BASE_URL]);

  const fetchCustomFields = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/customfields`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCustomFields(response.data);
    } catch (error) {
      console.error('Error fetching custom fields:', error);
    }
  }, [API_BASE_URL]);

  const fetchAssignedCareEntities = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/clients/${id}/assigned-careentities`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAssignedCareEntities(response.data || []);
    } catch (error) {
      console.error('Error fetching assigned care entities:', error);
      setAssignedCareEntities([]);
    }
  }, [id, API_BASE_URL]);

  useEffect(() => {
    fetchClient();
    fetchCustomFields();
    fetchAssignedCareEntities();
  }, [fetchClient, fetchCustomFields, fetchAssignedCareEntities]);

  // ---------------------------
  //  Handlers for UI state
  // ---------------------------
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleEditClick = () => {
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this client.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
      return;
    }
    setEditMode(true);
  };

  // ---------------------------
  //  Save changes
  // ---------------------------
  const handleSaveClick = async () => {
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to save changes.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
      return;
    }
    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.put(`${API_BASE_URL}/api/clients/${id}`, client, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        setClient(response.data);
        MySwal.fire({
          title: 'Success',
          text: 'Client profile saved successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert
        });
        setEditMode(false);
      } else {
        MySwal.fire({
          title: 'Error',
          text: 'Failed to save client data.',
          icon: 'error',
          ...customAlertStyles.sweetAlert
        });
      }
    } catch (error) {
      console.error('Error saving client data:', error);
      MySwal.fire({
        title: 'Error',
        text: 'An unknown error occurred. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    } finally {
      setSaving(false);
    }
  };

  // ---------------------------
  //  Form input handlers
  // ---------------------------
  const handleInputChange = (e, field = '', isNested = false) => {
    const { name, value } = e.target;

    if (isNested) {
      // For something like client.medicalHistory.{ ... }
      setClient((prevClient) => ({
        ...prevClient,
        [field]: {
          ...prevClient[field],
          [name]: value
        }
      }));
    } else {
      // For top-level fields or phoneNumber formatting
      if (name === 'phoneNumber') {
        setClient((prevClient) => ({
          ...prevClient,
          [name]: formatPhoneNumber(value)
        }));
      } else {
        setClient((prevClient) => ({
          ...prevClient,
          [name]: value
        }));
      }
    }
  };

  const handleCustomFieldChange = (e, fieldLabel) => {
    setClient((prevClient) => ({
      ...prevClient,
      customFields: {
        ...prevClient.customFields,
        [fieldLabel]: e.target.value
      }
    }));
  };

  // ---------------------------
  //  Profile Picture + Cropping
  // ---------------------------
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setProfileImage(file);

    // Create a base64 preview
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleScaleChange = (event, newValue) => {
    setScale(newValue);
  };

  const handleProfileImageUpload = async () => {
    if (!profileImage) {
      MySwal.fire({
        title: 'No file selected',
        text: 'Please choose a file before saving.',
        icon: 'warning',
        ...customAlertStyles.sweetAlert
      });
      return;
    }
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this profile.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
      return;
    }

    const token = localStorage.getItem('userToken');

    try {
      // 1) If client already has a profile picture, delete from Azure
      if (client.profilePicture) {
        const oldBlobName = extractBlobName(client.profilePicture);
        if (oldBlobName) {
          await axios.delete(`${API_BASE_URL}/api/files/delete`, {
            headers: { Authorization: `Bearer ${token}` },
            data: { blobName: oldBlobName },
          });
        }
      }

      // 2) Crop/scale the image via AvatarEditor
      if (!editorRef.current) {
        MySwal.fire({
          title: 'Error',
          text: 'Image editor not available. Please try again.',
          icon: 'error',
          ...customAlertStyles.sweetAlert
        });
        return;
      }
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      const blob = dataURLtoBlob(canvas);

      // 3) Upload the new file
      const formData = new FormData();
      formData.append('file', blob, profileImage.name);

      const uploadResponse = await axios.post(`${API_BASE_URL}/api/files/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      // 4) Update DB if upload succeeded
      if (uploadResponse.data && uploadResponse.data.fileUrl) {
        const dbResponse = await axios.put(
          `${API_BASE_URL}/api/clients/${id}`,
          { profilePicture: uploadResponse.data.fileUrl },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (dbResponse.data) {
          setClient(dbResponse.data);
          MySwal.fire({
            title: 'Success',
            text: 'Profile picture updated successfully.',
            icon: 'success',
            ...customAlertStyles.sweetAlert
          });
          setProfileImage(null);
          setPreviewImage(null);
        } else {
          MySwal.fire({
            title: 'Error',
            text: 'Failed to update profile picture in the database.',
            icon: 'error',
            ...customAlertStyles.sweetAlert
          });
        }
      } else {
        MySwal.fire({
          title: 'Error',
          text: 'Failed to upload profile picture to Azure.',
          icon: 'error',
          ...customAlertStyles.sweetAlert
        });
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      MySwal.fire({
        title: 'Error',
        text: 'An error occurred while uploading the profile picture. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    }
  };

  const handleProfileImageRemove = async () => {
    if (!client.profilePicture) {
      MySwal.fire({
        title: 'No picture to remove',
        text: 'There is no current profile picture set.',
        icon: 'info',
        ...customAlertStyles.sweetAlert
      });
      return;
    }
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this profile.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      const oldBlobName = extractBlobName(client.profilePicture);

      // 1) Delete from Azure
      if (oldBlobName) {
        await axios.delete(`${API_BASE_URL}/api/files/delete`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { blobName: oldBlobName },
        });
      }

      // 2) Update DB: remove the profilePicture
      const dbResponse = await axios.put(
        `${API_BASE_URL}/api/clients/${id}`,
        { profilePicture: '' },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (dbResponse.data) {
        setClient(dbResponse.data);
        setProfileImage(null);
        setPreviewImage(null);
        MySwal.fire({
          title: 'Success',
          text: 'Profile picture removed successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert
        });
      } else {
        MySwal.fire({
          title: 'Error',
          text: 'Failed to remove profile picture from the database.',
          icon: 'error',
          ...customAlertStyles.sweetAlert
        });
      }
    } catch (error) {
      console.error('Error removing profile picture:', error);
      MySwal.fire({
        title: 'Error',
        text: 'An error occurred while removing the picture. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    }
  };

  // ---------------------------
  //  Medication, Allergy, Condition
  // ---------------------------
  const handleAddMedication = () => {
    setClient((prevClient) => ({
      ...prevClient,
      medicalHistory: {
        ...prevClient.medicalHistory,
        medications: [...(prevClient.medicalHistory?.medications || []), newMedication]
      }
    }));
    setOpenAddMedication(false);
    setNewMedication({ name: '', dosage: '', frequency: '' });
  };

  const handleRemoveMedication = (index) => {
    setClient((prevClient) => ({
      ...prevClient,
      medicalHistory: {
        ...prevClient.medicalHistory,
        medications: prevClient.medicalHistory.medications.filter((_, i) => i !== index)
      }
    }));
  };

  const handleAddAllergy = () => {
    setClient((prevClient) => ({
      ...prevClient,
      medicalHistory: {
        ...prevClient.medicalHistory,
        allergies: [...(prevClient.medicalHistory?.allergies || []), newAllergy]
      }
    }));
    setOpenAddAllergy(false);
    setNewAllergy('');
  };

  const handleRemoveAllergy = (index) => {
    setClient((prevClient) => ({
      ...prevClient,
      medicalHistory: {
        ...prevClient.medicalHistory,
        allergies: prevClient.medicalHistory.allergies.filter((_, i) => i !== index)
      }
    }));
  };

  const handleAddCondition = () => {
    setClient((prevClient) => ({
      ...prevClient,
      medicalHistory: {
        ...prevClient.medicalHistory,
        chronicConditions: [
          ...(prevClient.medicalHistory?.chronicConditions || []),
          newCondition
        ]
      }
    }));
    setOpenAddCondition(false);
    setNewCondition('');
  };

  const handleRemoveCondition = (index) => {
    setClient((prevClient) => ({
      ...prevClient,
      medicalHistory: {
        ...prevClient.medicalHistory,
        chronicConditions: prevClient.medicalHistory.chronicConditions.filter((_, i) => i !== index)
      }
    }));
  };

  // ---------------------------
  //  Rendering
  // ---------------------------
  if (loading || authLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!hasViewPermission) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" color="error">
          You do not have permission to view this page.
        </Typography>
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <NavigationBar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
          <Box
            sx={{
              marginBottom: 2,
              border: '1px solid #549F93',
              padding: '16px',
              borderRadius: '4px',
              background: 'linear-gradient(to right, #258EA6, #549F93)',
              position: 'relative'
            }}
          >
            <Avatar
              alt={`${client.firstName} ${client.lastName}`}
              src={previewImage || client.profilePicture || undefined}
              sx={{
                width: 160,
                height: 160,
                position: 'absolute',
                top: '50%',
                right: '24px',
                transform: 'translateY(-50%)',
                border: '4px solid #FFFFFF',
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                fontSize: '36px',
                backgroundColor: (previewImage || client.profilePicture) ? 'transparent' : '#BDBDBD',
                color: '#FFFFFF'
              }}
            >
              {/* If no picture, show initials */}
              {!client.profilePicture && !previewImage && `${client.firstName?.[0] || ''}${client.lastName?.[0] || ''}`}
            </Avatar>

            <Box sx={{ marginRight: '180px' }}>
              <Typography variant="h6" sx={{ color: '#FFFFFF', marginBottom: '10px' }}>
                {client.firstName} {client.lastName}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#EAEAEA', marginBottom: '8px' }}>
                Phone: {client.phoneNumber || 'N/A'}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#EAEAEA', marginBottom: '20px' }}>
                Address: {client.address || 'N/A'}
              </Typography>
            </Box>

            <Box
              sx={{
                position: 'absolute',
                right: '200px',
                top: '50%',
                transform: 'translateY(-50%)',
                textAlign: 'right',
                lineHeight: '1.8'
              }}
            >
              <Typography variant="subtitle1" sx={{ color: '#EAEAEA' }}>
                Age: {client.age || 'N/A'}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#EAEAEA' }}>
                Sex: {client.sex || 'N/A'}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#EAEAEA' }}>
                Gender Identity: {client.genderIdentity || 'N/A'}
              </Typography>
            </Box>

            <IconButton
              onClick={toggleExpand}
              sx={{ color: '#FFFFFF', position: 'absolute', bottom: '8px', left: '12px' }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>

            {expanded && hasEditPermission && (
              <>
                <IconButton
                  onClick={handleEditClick}
                  sx={{ color: '#FFFFFF', position: 'absolute', bottom: '8px', left: '52px' }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={handleSaveClick}
                  sx={{ color: '#FFFFFF', position: 'absolute', bottom: '8px', left: '92px' }}
                  disabled={saving}
                >
                  {saving ? <CircularProgress size={24} /> : <SaveIcon />}
                </IconButton>
              </>
            )}
          </Box>

          {/* Collapsible Tabs */}
          <Collapse in={expanded}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Client Profile Tabs"
            >
              <Tab label="Personal Information" />
              <Tab label="Medical History" />
              <Tab label="Risks" />
              <Tab label="Profile Picture" />
              <Tab label="Custom Fields" />
              <Tab label="Assigned Care Entities" />
            </Tabs>

            <Box sx={{ marginTop: 2 }}>
              {/* Personal Information */}
              {activeTab === 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="First Name"
                      name="firstName"
                      value={client.firstName || ''}
                      onChange={handleInputChange}
                      required
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Last Name"
                      name="lastName"
                      value={client.lastName || ''}
                      onChange={handleInputChange}
                      required
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      value={client.phoneNumber || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Address"
                      name="address"
                      value={client.address || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Org Client ID"
                      name="orgClientID"
                      value={client.orgClientID || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Email"
                      name="email"
                      value={client.email || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Age"
                      name="age"
                      value={client.age || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      select
                      label="Preferred Contact Method"
                      name="preferredContactMethod"
                      value={client.preferredContactMethod || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    >
                      <MenuItem value="Phone">Phone</MenuItem>
                      <MenuItem value="Email">Email</MenuItem>
                      <MenuItem value="Text">Text</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      select
                      label="Sex"
                      name="sex"
                      value={client.sex || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                      <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      select
                      label="Gender Identity"
                      name="genderIdentity"
                      value={client.genderIdentity || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Non-binary">Non-binary</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                      <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      select
                      label="Status"
                      name="status"
                      value={client.status || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                      <MenuItem value="Away">Away</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              )}

              {/* Medical History */}
              {activeTab === 1 && (
                <Box>
                  <Typography variant="h6">Medical History</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddMedication(true)}
                    startIcon={<AddIcon />}
                    sx={{ marginBottom: 2 }}
                    disabled={!editMode}
                  >
                    Add Medication
                  </Button>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Medication Name</TableCell>
                          <TableCell>Dosage</TableCell>
                          <TableCell>Frequency</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {client.medicalHistory?.medications?.map((med, index) => (
                          <TableRow key={index}>
                            <TableCell>{med.name}</TableCell>
                            <TableCell>{med.dosage}</TableCell>
                            <TableCell>{med.frequency}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handleRemoveMedication(index)}
                                disabled={!editMode}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddAllergy(true)}
                    startIcon={<AddIcon />}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                    disabled={!editMode}
                  >
                    Add Allergy
                  </Button>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Allergy</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {client.medicalHistory?.allergies?.map((allergy, index) => (
                          <TableRow key={index}>
                            <TableCell>{allergy}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handleRemoveAllergy(index)}
                                disabled={!editMode}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddCondition(true)}
                    startIcon={<AddIcon />}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                    disabled={!editMode}
                  >
                    Add Chronic Condition
                  </Button>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Chronic Condition</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {client.medicalHistory?.chronicConditions?.map((condition, index) => (
                          <TableRow key={index}>
                            <TableCell>{condition}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handleRemoveCondition(index)}
                                disabled={!editMode}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}

              {/* Risks */}
              {activeTab === 2 && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Living Situation"
                      name="livingSituation"
                      value={client.environmentalRisk?.livingSituation || ''}
                      onChange={(e) => handleInputChange(e, 'environmentalRisk', true)}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Home Safety"
                      name="homeSafety"
                      value={client.environmentalRisk?.homeSafety || ''}
                      onChange={(e) => handleInputChange(e, 'environmentalRisk', true)}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Substance Use"
                      name="substanceUse"
                      value={client.behavioralRisk?.substanceUse || ''}
                      onChange={(e) => handleInputChange(e, 'behavioralRisk', true)}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Diet and Nutrition"
                      name="dietAndNutrition"
                      value={client.behavioralRisk?.dietAndNutrition || ''}
                      onChange={(e) => handleInputChange(e, 'behavioralRisk', true)}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Exercise and Mobility"
                      name="exerciseAndMobility"
                      value={client.behavioralRisk?.exerciseAndMobility || ''}
                      onChange={(e) => handleInputChange(e, 'behavioralRisk', true)}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Support System"
                      name="supportSystem"
                      value={client.socialRisk?.supportSystem || ''}
                      onChange={(e) => handleInputChange(e, 'socialRisk', true)}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Occupation"
                      name="occupation"
                      value={client.socialRisk?.occupation || ''}
                      onChange={(e) => handleInputChange(e, 'socialRisk', true)}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Social Activities"
                      name="socialActivities"
                      value={client.socialRisk?.socialActivities || ''}
                      onChange={(e) => handleInputChange(e, 'socialRisk', true)}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                </Grid>
              )}

              {/* Profile Picture (cropping) */}
              {activeTab === 3 && (
                <Box>
                  <Typography component="h3" variant="h6" aria-label="Profile Picture Header">
                    Profile Picture
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2 }}>
                    {/* Show cropping editor if there's a newly selected file */}
                    {profileImage && (
                      <>
                        <AvatarEditor
                          ref={editorRef}
                          image={previewImage}
                          width={250}
                          height={250}
                          border={50}
                          borderRadius={125}
                          color={[255, 255, 255, 0.6]}
                          scale={scale}
                          rotate={0}
                        />
                        <Box sx={{ width: 200, marginTop: 2 }}>
                          <Typography variant="body2" gutterBottom>
                            Zoom
                          </Typography>
                          <Slider
                            value={scale}
                            min={1}
                            max={3}
                            step={0.1}
                            onChange={handleScaleChange}
                          />
                        </Box>
                      </>
                    )}

                    {/* If no new file selected, just display the current or fallback avatar */}
                    {!profileImage && (
                      <Avatar
                        alt={`${client.firstName} ${client.lastName}`}
                        src={client.profilePicture || '/default-avatar.png'}
                        sx={{ width: 140, height: 140, marginBottom: 2 }}
                      >
                        {!client.profilePicture && `${client.firstName?.[0] || ''}${client.lastName?.[0] || ''}`}
                      </Avatar>
                    )}

                    {editMode && (
                      <>
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<PhotoCameraIcon />}
                          sx={{ marginBottom: 2 }}
                          aria-label="Upload New Picture"
                        >
                          {profileImage ? 'Change Picture' : 'Upload New Picture'}
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleProfileImageChange}
                          />
                        </Button>

                        {profileImage && editMode && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleProfileImageUpload}
                            sx={{ marginBottom: 2 }}
                          >
                            Save Picture
                          </Button>
                        )}

                        {client.profilePicture && !profileImage && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleProfileImageRemove}
                            startIcon={<DeleteIcon />}
                            aria-label="Remove Current Picture"
                          >
                            Remove Current Picture
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              )}

              {/* Custom Fields */}
              {activeTab === 4 && (
                <Box>
                  <Typography variant="h6">Custom Fields</Typography>
                  <Grid container spacing={2}>
                    {customFields.map((field) => (
                      <Grid item xs={12} md={6} key={field._id}>
                        <TextField
                          label={field.label}
                          name={field.label}
                          value={client.customFields?.[field.label] || ''}
                          onChange={(e) => handleCustomFieldChange(e, field.label)}
                          fullWidth
                          disabled={!editMode}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}

              {/* Assigned Care Entities */}
              {activeTab === 5 && (
                <Box>
                  <Typography variant="h6">Assigned Care Entities</Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Care Entity Name</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Address</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {assignedCareEntities.map((entity) => (
                          <TableRow key={entity._id}>
                            <TableCell>{entity.name}</TableCell>
                            <TableCell>{entity.type}</TableCell>
                            <TableCell>{entity.address}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Box>
          </Collapse>

          <ClientCarePlans clientID={id} />

          {/* Add Medication Dialog */}
          <Dialog open={openAddMedication} onClose={() => setOpenAddMedication(false)}>
            <DialogTitle>Add Medication</DialogTitle>
            <DialogContent>
              <TextField
                label="Name"
                name="name"
                value={newMedication.name}
                onChange={(e) => setNewMedication({ ...newMedication, name: e.target.value })}
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Dosage"
                name="dosage"
                value={newMedication.dosage}
                onChange={(e) => setNewMedication({ ...newMedication, dosage: e.target.value })}
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Frequency"
                name="frequency"
                value={newMedication.frequency}
                onChange={(e) => setNewMedication({ ...newMedication, frequency: e.target.value })}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAddMedication(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddMedication} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>

          {/* Add Allergy Dialog */}
          <Dialog open={openAddAllergy} onClose={() => setOpenAddAllergy(false)}>
            <DialogTitle>Add Allergy</DialogTitle>
            <DialogContent>
              <TextField
                label="Allergy"
                name="allergy"
                value={newAllergy}
                onChange={(e) => setNewAllergy(e.target.value)}
                fullWidth
                sx={{ marginBottom: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAddAllergy(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddAllergy} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>

          {/* Add Chronic Condition Dialog */}
          <Dialog open={openAddCondition} onClose={() => setOpenAddCondition(false)}>
            <DialogTitle>Add Chronic Condition</DialogTitle>
            <DialogContent>
              <TextField
                label="Chronic Condition"
                name="condition"
                value={newCondition}
                onChange={(e) => setNewCondition(e.target.value)}
                fullWidth
                sx={{ marginBottom: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAddCondition(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddCondition} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

const WrappedClientProfileScreen = () => (
  <ErrorBoundary>
    <ClientProfileScreen />
  </ErrorBoundary>
);

export default WrappedClientProfileScreen;