// src/screens/AssignScreen.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Collapse,
  IconButton,
  TextField,
  Tooltip,
  Pagination,
  Button,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import customAlertStyles from '../styles/CustomAlertStyles';
import './SweetAlertCustom.css';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

// Import icons directly
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// Add axios so we can call the `/assign` route
import axios from 'axios';

const MySwal = withReactContent(Swal);

const AssignScreen = ({ templateDetails, setTemplateDetails }) => {
  // State Variables
  const [selection, setSelection] = useState('clients'); // Default to 'clients'
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [careEntities, setCareEntities] = useState([]);
  const [roles, setRoles] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState(templateDetails.assignedUsers || []);
  const [assignedClients, setAssignedClients] = useState(templateDetails.assignedClients || []);
  const [assignedCareEntities, setAssignedCareEntities] = useState(
    templateDetails.assignedCareEntities || []
  );
  const [localAssignedRoles, setLocalAssignedRoles] = useState(
    templateDetails.assignedRoles || []
  );
  const [permissionsForRoles, setPermissionsForRoles] = useState(
    templateDetails.permissionsForRoles || {}
  ); // Store permissions for each role locally
  const [userSearch, setUserSearch] = useState('');
  const [clientSearch, setClientSearch] = useState('');
  const [careEntitySearch, setCareEntitySearch] = useState('');
  const [expanded, setExpanded] = useState({
    assignmentOptions: false,
    roles: false,
  });

  // Separate pagination states for assignments and roles
  const [currentPageAssignments, setCurrentPageAssignments] = useState(1);
  const [currentPageRoles, setCurrentPageRoles] = useState(1);
  const recordsPerPage = 25;

  const carePlanTemplateID = templateDetails._id; // Use the actual template ID
  const actions = ['view', 'add', 'edit', 'delete']; // Actions for role permissions

  const { permissions = [], loading: authLoading } = useAuth();

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');

  // Ref to store initial templateDetails to avoid infinite loop
  const initialTemplateDetailsRef = useRef(templateDetails);

  // Mapping of selection values to display labels
  const selectionLabels = {
    users: 'Users',
    clients: 'Clients',
    careEntities: 'Care Entities',
  };

  // Fetch data for users, clients, care entities, and roles
  const fetchUsers = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_BASE_URL}/api/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch users.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  const fetchClients = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_BASE_URL}/api/clients`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setClients(data);
    } catch (error) {
      console.error('Error fetching clients:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch clients.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  const fetchCareEntities = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_BASE_URL}/api/careentities`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setCareEntities(data);
    } catch (error) {
      console.error('Error fetching care entities:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch care entities.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  const fetchRoles = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_BASE_URL}/api/roles/permissions`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setRoles(data);
    } catch (error) {
      console.error('Error fetching roles:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch roles.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  // Process roles to extract existing permissions for the current template
  useEffect(() => {
    if (carePlanTemplateID && roles.length > 0) {
      const updatedPermissionsForRoles = {};

      roles.forEach((role) => {
        const rolePermissions = role.permissions || [];
        // Filter out only the ones for this template
        const templatePermissions = rolePermissions.filter((permission) =>
          permission.endsWith(`_${carePlanTemplateID}`)
        );

        if (templatePermissions.length > 0) {
          const actionsForRole = templatePermissions.map((permission) => {
            const [action] = permission.split('_');
            return action;
          });
          updatedPermissionsForRoles[role._id] = actionsForRole.map(
            (action) => `${action}_${carePlanTemplateID}`
          ); // Use actual carePlanTemplateID
        }
      });

      setPermissionsForRoles((prev) => ({ ...prev, ...updatedPermissionsForRoles }));
    }
  }, [carePlanTemplateID, roles]);

  // Initial data fetch
  useEffect(() => {
    if (!authLoading && hasViewPermission) {
      fetchUsers();
      fetchClients();
      fetchCareEntities();
      fetchRoles();
    } else if (!authLoading && !hasViewPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authLoading,
    hasViewPermission,
    fetchUsers,
    fetchClients,
    fetchCareEntities,
    fetchRoles,
  ]);

  // Handle permission changes for roles (locally only)
  const handlePermissionChange = (role, action, checked) => {
    const permissionKey = `${action}_${carePlanTemplateID}`; // Use actual carePlanTemplateID

    setPermissionsForRoles((prev) => {
      const updatedPermissions = checked
        ? [...(prev[role._id] || []), permissionKey]
        : (prev[role._id] || []).filter((perm) => perm !== permissionKey);

      return {
        ...prev,
        [role._id]: updatedPermissions,
      };
    });
  };

  // Handle "Assign All" functionality
  const handleAssignAll = (records, assigned, setAssigned) => {
    if (assigned.length === records.length) {
      // Unassign all
      setAssigned([]);
    } else {
      // Assign all
      const allIds = records.map((record) => record._id);
      setAssigned(allIds);
    }
  };

  // Handle sorting and pagination
  const getSortedRecords = (records, assigned, searchField) => {
    const filteredRecords = records.filter((record) => {
      const name = record.name || `${record.firstName} ${record.lastName}`;
      return name.toLowerCase().includes(searchField.toLowerCase());
    });

    // Sort assigned records first, then alphabetical
    const assignedRecords = filteredRecords.filter((record) => assigned.includes(record._id));
    const unassignedRecords = filteredRecords.filter((record) => !assigned.includes(record._id));

    assignedRecords.sort((a, b) => {
      const nameA = (a.name || `${a.firstName} ${a.lastName}`).toLowerCase();
      const nameB = (b.name || `${b.firstName} ${b.lastName}`).toLowerCase();
      return nameA.localeCompare(nameB);
    });

    unassignedRecords.sort((a, b) => {
      const nameA = (a.name || `${a.firstName} ${a.lastName}`).toLowerCase();
      const nameB = (b.name || `${b.firstName} ${b.lastName}`).toLowerCase();
      return nameA.localeCompare(nameB);
    });

    return [...assignedRecords, ...unassignedRecords];
  };

  const renderTable = (
    records,
    assigned,
    setAssigned,
    searchField,
    setSearchField,
    paginationSetter,
    currentPageState
  ) => {
    const sortedRecords = getSortedRecords(records, assigned, searchField);

    const indexOfLastRecord = currentPageState * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = sortedRecords.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(sortedRecords.length / recordsPerPage);

    return (
      <Collapse in={expanded.assignmentOptions} timeout="auto" unmountOnExit>
        <TableContainer component={Paper}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
            <TextField
              variant="outlined"
              placeholder="Search..."
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              sx={{ width: '70%' }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={assigned.length === sortedRecords.length}
                  onChange={() => handleAssignAll(sortedRecords, assigned, setAssigned)}
                />
              }
              label="Assign All"
            />
          </Box>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentRecords.map((record) => (
                <TableRow key={record._id}>
                  <TableCell>{record.name || `${record.firstName} ${record.lastName}`}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={assigned.includes(record._id)}
                      onChange={(e) => {
                        const { checked } = e.target;
                        setAssigned((prev) =>
                          checked ? [...prev, record._id] : prev.filter((id) => id !== record._id)
                        );
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
            <Pagination
              count={totalPages}
              page={currentPageState}
              onChange={(e, value) => paginationSetter(value)}
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </TableContainer>
      </Collapse>
    );
  };

  const renderAssignmentOptions = () => {
    let records = [];
    let assigned = [];
    let setAssigned;
    let searchField;
    let setSearchField;
    let paginationSetter;
    let currentPageState;

    if (selection === 'users') {
      records = users;
      assigned = assignedUsers;
      setAssigned = setAssignedUsers;
      searchField = userSearch;
      setSearchField = setUserSearch;
      paginationSetter = setCurrentPageAssignments;
      currentPageState = currentPageAssignments;
    } else if (selection === 'clients') {
      records = clients;
      assigned = assignedClients;
      setAssigned = setAssignedClients;
      searchField = clientSearch;
      setSearchField = setClientSearch;
      paginationSetter = setCurrentPageAssignments;
      currentPageState = currentPageAssignments;
    } else if (selection === 'careEntities') {
      records = careEntities;
      assigned = assignedCareEntities;
      setAssigned = setAssignedCareEntities;
      searchField = careEntitySearch;
      setSearchField = setCareEntitySearch;
      paginationSetter = setCurrentPageAssignments;
      currentPageState = currentPageAssignments;
    } else {
      return null;
    }

    return renderTable(
      records,
      assigned,
      setAssigned,
      searchField,
      setSearchField,
      paginationSetter,
      currentPageState
    );
  };

  const renderRoleAssignment = () => {
    const assignedRoleIds = Object.keys(permissionsForRoles);
    const assignedRolesList = roles.filter((role) => assignedRoleIds.includes(role._id));
    const unassignedRolesList = roles.filter((role) => !assignedRoleIds.includes(role._id));

    assignedRolesList.sort((a, b) => a.name.localeCompare(b.name));
    unassignedRolesList.sort((a, b) => a.name.localeCompare(b.name));

    const sortedRoles = [...assignedRolesList, ...unassignedRolesList];

    const indexOfLastRecord = currentPageRoles * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRoles = sortedRoles.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(sortedRoles.length / recordsPerPage);

    return (
      <Box sx={{ marginTop: '32px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Provide Access</Typography>
          <IconButton
            onClick={() => setExpanded((prev) => ({ ...prev, roles: !prev.roles }))}
          >
            {expanded.roles ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>

        <Collapse in={expanded.roles} timeout="auto" unmountOnExit>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Role</TableCell>
                  {actions.map((action) => (
                    <TableCell key={action}>
                      {action.charAt(0).toUpperCase() + action.slice(1)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentRoles.map((role) => {
                  const hasPermissions =
                    permissionsForRoles[role._id] && permissionsForRoles[role._id].length > 0;
                  return (
                    <TableRow key={role._id}>
                      <TableCell>{role.name}</TableCell>
                      {actions.map((action) => {
                        const permissionKey = `${action}_${carePlanTemplateID}`;
                        const hasPermission = (permissionsForRoles[role._id] || []).includes(
                          permissionKey
                        );

                        return (
                          <TableCell key={action}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={hasPermission}
                                  onChange={(e) =>
                                    handlePermissionChange(role, action, e.target.checked)
                                  }
                                />
                              }
                              label=""
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
              <Pagination
                count={totalPages}
                page={currentPageRoles}
                onChange={(e, value) => setCurrentPageRoles(value)}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </TableContainer>
        </Collapse>
      </Box>
    );
  };

  // Update templateDetails when any assignment changes (locally only)
  useEffect(() => {
    const updatedDetails = {
      ...initialTemplateDetailsRef.current,
      assignedClients,
      assignedUsers,
      assignedCareEntities,
      assignedRoles: localAssignedRoles,
      permissionsForRoles,
    };
    setTemplateDetails(updatedDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assignedClients,
    assignedUsers,
    assignedCareEntities,
    localAssignedRoles,
    permissionsForRoles,
    setTemplateDetails,
  ]);

  // Determine if there is data assigned to users or care entities
  const hasAssignedUsers = assignedUsers.length > 0;
  const hasAssignedCareEntities = assignedCareEntities.length > 0;

  // ---------------
  // Save Assignments
  // ---------------
  const handleSaveAssignments = async () => {
    if (!templateDetails._id) {
      MySwal.fire({
        title: 'Error',
        text: 'No Template ID found. Please create or load a valid template first.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    try {
      const token = localStorage.getItem('userToken');

      // Send updated assignment data (including roles) to the server
      await axios.post(
        `${API_BASE_URL}/api/careplantemplates/assign`,
        {
          _id: templateDetails._id,
          assignedUsers,
          assignedClients,
          assignedCareEntities,
          // Passing the roles object so the server can run handleRolePermissions
          roles: permissionsForRoles,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      MySwal.fire({
        title: 'Success',
        text: 'Assignments (including role permissions) saved successfully.',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      console.error('Error saving assignments:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to save assignments. Please try again.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  if (authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (!hasViewPermission) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" color="error">
          You do not have permission to view this page.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '24px' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>
        Assign CarePlan Template
      </Typography>

      <FormControl component="fieldset" sx={{ marginBottom: '24px' }}>
        <FormLabel component="legend">Assign to</FormLabel>
        <RadioGroup value={selection} onChange={(e) => setSelection(e.target.value)}>
          <FormControlLabel
            value="users"
            control={<Radio />}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: hasAssignedUsers ? 'primary.main' : 'text.secondary',
                }}
              >
                Users
                {hasAssignedUsers && (
                  <Tooltip title="Users have assigned data">
                    <IconButton size="small" sx={{ marginLeft: '4px' }}>
                      <HelpOutlineIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            }
          />
          <FormControlLabel value="clients" control={<Radio />} label="Clients" />
          <FormControlLabel
            value="careEntities"
            control={<Radio />}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: hasAssignedCareEntities ? 'primary.main' : 'text.secondary',
                }}
              >
                Care Entities
                {hasAssignedCareEntities && (
                  <Tooltip title="Care Entities have assigned data">
                    <IconButton size="small" sx={{ marginLeft: '4px' }}>
                      <HelpOutlineIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            }
          />
        </RadioGroup>
      </FormControl>

      {/* Expand/Collapse Assignment */}
      <Box sx={{ marginBottom: '16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            {selectionLabels[selection] || 'Assignment'}
          </Typography>
          <IconButton
            onClick={() =>
              setExpanded((prev) => ({ ...prev, assignmentOptions: !prev.assignmentOptions }))
            }
          >
            {expanded.assignmentOptions ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        {renderAssignmentOptions()}
      </Box>

      {renderRoleAssignment()}

      <Box sx={{ marginTop: '16px' }}>
        <Button variant="contained" color="primary" onClick={handleSaveAssignments}>
          Save Assignments
        </Button>
      </Box>
    </Box>
  );
};

// Wrap the component with ErrorBoundary
const WrappedAssignScreen = (props) => (
  <ErrorBoundary>
    <AssignScreen {...props} />
  </ErrorBoundary>
);

export default WrappedAssignScreen;