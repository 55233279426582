// src/screens/CareNavigatorScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  Modal,
  Tooltip,
  Button,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { styles } from '../styles/styles';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

import SearchIcon from '@mui/icons-material/Search';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ProgramIcon from '@mui/icons-material/School';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';

const CareNavigatorScreen = () => {
  const navigate = useNavigate();
  const { permissions = [] } = useAuth();

  // =======================
  // Main: Care Entities
  // =======================
  const [careEntities, setCareEntities] = useState([]);
  const [careEntitiesTotal, setCareEntitiesTotal] = useState(0);
  const [careEntitiesOffset, setCareEntitiesOffset] = useState(0);

  // =======================
  // Main: Clients
  // =======================
  const [clients, setClients] = useState([]);
  const [clientsTotal, setClientsTotal] = useState(0);
  const [clientsOffset, setClientsOffset] = useState(0);

  // =======================
  // Modal State
  // =======================
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  // Modal pagination & searching
  const [modalClients, setModalClients] = useState([]);
  const [modalTotal, setModalTotal] = useState(0);
  const [modalOffset, setModalOffset] = useState(0);
  const modalLimit = 15;
  const [modalSearchTerm, setModalSearchTerm] = useState('');
  const [modalDebouncedSearch, setModalDebouncedSearch] = useState('');
  const [modalLoading, setModalLoading] = useState(false);

  // =======================
  // Shared
  // =======================
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [loadingEntities, setLoadingEntities] = useState(true);
  const [loadingClients, setLoadingClients] = useState(true);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  const limitCareEntities = 12;
  const limitClients = 18;

  // =======================
  // fetchCareEntities
  // =======================
  const fetchCareEntities = useCallback(
    async (append = false, offsetOverride) => {
      try {
        setLoadingEntities(true);
        const token = localStorage.getItem('userToken');
        // Decide which offset to use
        const offsetToUse =
          typeof offsetOverride === 'number' ? offsetOverride : append ? careEntitiesOffset : 0;

        const response = await axios.get(`${API_BASE_URL}/api/careentities/assigned-careentities`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            limit: limitCareEntities,
            offset: offsetToUse,
            search: debouncedSearch,
          },
        });

        const newEntities = response.data.careEntities || [];
        const total = response.data.total || 0;

        setCareEntitiesTotal(total);
        if (append) {
          setCareEntities((prev) => [...prev, ...newEntities]);
        } else {
          setCareEntities(newEntities);
        }
      } catch (error) {
        console.error('Error fetching care entities:', error);
        if (!append) setCareEntities([]);
      } finally {
        setLoadingEntities(false);
      }
    },
    // IMPORTANT: Exclude offsets from dependencies to avoid re-fetch loops
    [API_BASE_URL, debouncedSearch]
  );

  // =======================
  // fetchClients
  // =======================
  const fetchClients = useCallback(
    async (append = false, offsetOverride) => {
      try {
        setLoadingClients(true);
        const token = localStorage.getItem('userToken');
        // Decide which offset to use
        const offsetToUse =
          typeof offsetOverride === 'number' ? offsetOverride : append ? clientsOffset : 0;

        const response = await axios.get(`${API_BASE_URL}/api/careentities/assigned-clients`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            limit: limitClients,
            offset: offsetToUse,
            search: debouncedSearch,
          },
        });

        const newClients = response.data.clients || [];
        const total = response.data.total || 0;

        setClientsTotal(total);
        if (append) {
          setClients((prev) => [...prev, ...newClients]);
        } else {
          setClients(newClients);
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
        if (!append) setClients([]);
      } finally {
        setLoadingClients(false);
      }
    },
    // IMPORTANT: Exclude offsets from dependencies to avoid re-fetch loops
    [API_BASE_URL, debouncedSearch]
  );

  // =======================
  // Effect: for search changes
  // Reset offsets & fetch fresh data
  // =======================
  useEffect(() => {
    // Each time debouncedSearch changes, reset offset and fetch fresh data
    setCareEntitiesOffset(0);
    setClientsOffset(0);

    fetchCareEntities(false, 0);
    fetchClients(false, 0);
  }, [debouncedSearch, fetchCareEntities, fetchClients]);

  // =======================
  // Main: handleEntityClick (open modal)
  // =======================
  const handleEntityClick = (entityId) => {
    setSelectedEntity(entityId);
    setModalOffset(0);
    setModalClients([]);
    setModalTotal(0);
    setModalSearchTerm('');
    setModalDebouncedSearch('');
    setOpenModal(true);
  };

  // =======================
  // Modal: fetchEntityClients (paginated + search)
  // =======================
  const fetchEntityClients = useCallback(
    async (append = false, offsetOverride) => {
      if (!selectedEntity) return;
      setModalLoading(true);

      try {
        const token = localStorage.getItem('userToken');
        const offsetToUse =
          typeof offsetOverride === 'number' ? offsetOverride : append ? modalOffset : 0;

        const response = await axios.get(
          `${API_BASE_URL}/api/careentities/${selectedEntity}/clients`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              limit: modalLimit,
              offset: offsetToUse,
              search: modalDebouncedSearch,
            },
          }
        );

        // 1) If your backend returns an array, do this:
        //    e.g. response.data = [ {...}, {...}, ... ]
        // 2) If your backend returns an object { clients: [...], total: N }, then keep the old logic.
        // We'll try to handle BOTH gracefully:
        let newClients = [];
        let total = 0;

        if (Array.isArray(response.data)) {
          // If the server returns an array directly
          newClients = response.data;
          total = newClients.length; // or use a separate endpoint for the total, if needed
        } else {
          // If the server returns an object with 'clients' array
          newClients = response.data.clients || [];
          total = response.data.total || newClients.length;
        }

        if (append) {
          setModalClients((prev) => [...prev, ...newClients]);
        } else {
          setModalClients(newClients);
        }
        setModalTotal(total);
      } catch (error) {
        console.error('Error fetching entity clients:', error);
        if (!append) setModalClients([]);
      } finally {
        setModalLoading(false);
      }
    },
    [API_BASE_URL, selectedEntity, modalOffset, modalDebouncedSearch, modalLimit]
  );

  // =======================
  // Effect: When modal is opened or modal search changes
  // We always fetch the first batch
  // =======================
  useEffect(() => {
    if (openModal && selectedEntity) {
      // Start fresh
      setModalOffset(0);
      fetchEntityClients(false, 0);
    }
  }, [openModal, selectedEntity, modalDebouncedSearch, fetchEntityClients]);

  // =======================
  // Modal: "Load More"
  // =======================
  const loadMoreModalClients = () => {
    const newOffset = modalClients.length; // next offset is current # of clients loaded
    setModalOffset(newOffset);
    fetchEntityClients(true, newOffset);
  };

  // =======================
  // Main: handleClientClick
  // =======================
  const handleClientClick = (clientId) => {
    navigate(`/client-profile/${clientId}`);
  };

  // =======================
  // Main: Search Input
  // =======================
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCareEntitiesOffset(0);
    setClientsOffset(0);
  };

  // Debounce main searchTerm
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchTerm.trim());
    }, 300);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  // =======================
  // Modal: Search Input
  // =======================
  const handleModalSearchChange = (e) => {
    setModalSearchTerm(e.target.value);
  };

  // Debounce modal search
  useEffect(() => {
    const timer = setTimeout(() => {
      setModalOffset(0);
      setModalDebouncedSearch(modalSearchTerm.trim());
    }, 300);
    return () => clearTimeout(timer);
  }, [modalSearchTerm]);

  // =======================
  // Permissions
  // =======================
  const hasViewPermission = (resource) => {
    return permissions.includes(`view_${resource}`);
  };

  if (!permissions.length) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6">Loading permissions...</Typography>
      </Box>
    );
  }

  if (!hasViewPermission('care-navigator')) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" color="error">
          You do not have permission to view this page.
        </Typography>
      </Box>
    );
  }

  // =======================
  // Main: "Load More" logic
  // =======================
  const canLoadMoreEntities = careEntities.length < careEntitiesTotal;
  const canLoadMoreClients = clients.length < clientsTotal;

  const loadMoreEntities = () => {
    const newOffset = careEntities.length;
    setCareEntitiesOffset(newOffset);
    fetchCareEntities(true, newOffset);
  };

  const loadMoreClients = () => {
    const newOffset = clients.length;
    setClientsOffset(newOffset);
    fetchClients(true, newOffset);
  };

  // =======================
  // Render
  // =======================
  return (
    <>
      <NavigationBar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
          {/* Search */}
          <Box sx={{ display: 'flex', gap: '16px', marginBottom: '24px' }}>
            <TextField
              variant="outlined"
              placeholder="Search care entities or clients..."
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon aria-label="Search icon" />,
              }}
              inputProps={{
                'aria-label': 'Search care entities or clients',
              }}
            />
          </Box>

          {/* Care Entities Section */}
          <Typography component="h2" variant="h5" sx={{ marginBottom: '24px' }} aria-label="Care Entities Title">
            Care Entities
          </Typography>
          {loadingEntities && careEntities.length === 0 ? (
            <Box sx={{ textAlign: 'center', margin: '24px 0' }}>
              <CircularProgress aria-label="Loading care entities..." />
            </Box>
          ) : (
            <Grid container spacing={3} sx={{ marginBottom: '24px' }} aria-label="Care Entities List">
              {careEntities.map((entity) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={entity._id} aria-label={`Care Entity ${entity.name}`}>
                  <Box
                    sx={{
                      backgroundColor: styles.lightBackground,
                      borderColor: styles.border,
                      borderWidth: 1,
                      borderStyle: 'solid',
                      background: `linear-gradient(145deg, ${styles.lightBackground}, #FFFFFF)`,
                      padding: '16px',
                      borderRadius: '8px',
                      color: styles.textPrimary,
                      transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                  >
                    <Typography variant="h6" sx={{ marginBottom: '8px' }} aria-label="Entity Name">
                      {entity.name}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }} aria-label="Entity Type">
                      {entity.type && (
                        <>
                          {entity.type === 'location' && (
                            <LocationOnIcon
                              sx={{ color: styles.accent, marginRight: '8px' }}
                              aria-label="Location Icon"
                            />
                          )}
                          {entity.type === 'program' && (
                            <ProgramIcon
                              sx={{ color: styles.accent, marginRight: '8px' }}
                              aria-label="Program Icon"
                            />
                          )}
                          {entity.type === 'service' && (
                            <BuildIcon
                              sx={{ color: styles.accent, marginRight: '8px' }}
                              aria-label="Service Icon"
                            />
                          )}
                          <Typography
                            variant="body2"
                            sx={{ color: styles.textSecondary }}
                            aria-label={`Entity Type ${entity.type}`}
                          >
                            {entity.type.charAt(0).toUpperCase() + entity.type.slice(1)}
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Typography variant="body2" sx={{ marginBottom: '16px' }} aria-label="Entity Description">
                      {entity.description}
                    </Typography>

                    {/* Two icon buttons */}
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                      {/* 1) Group icon to open the modal (existing handleEntityClick) */}
                      <Tooltip title="View Assigned Clients" arrow>
                        <IconButton
                          color="primary"
                          onClick={() => handleEntityClick(entity._id)}
                          sx={{ backgroundColor: styles.primary, color: styles.background }}
                          aria-label="View Assigned Clients"
                        >
                          <GroupIcon />
                        </IconButton>
                      </Tooltip>

                      {/* 2) OpenInNew icon to navigate to entity profile */}
                      <Tooltip title="Open Entity Profile" arrow>
                        <IconButton
                          color="primary"
                          onClick={() => navigate(`/care-entity-profile/${entity._id}`)}
                          sx={{ backgroundColor: styles.primary, color: styles.background }}
                          aria-label="Open Entity Profile"
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
          {canLoadMoreEntities && (
            <Box sx={{ textAlign: 'center', marginBottom: '48px' }}>
              <Button variant="contained" onClick={loadMoreEntities} disabled={loadingEntities}>
                {loadingEntities ? 'Loading...' : 'Load More Care Entities'}
              </Button>
            </Box>
          )}

          {/* Clients Section */}
          <Typography component="h2" variant="h5" sx={{ marginBottom: '24px' }} aria-label="Clients Title">
            Clients
          </Typography>
          {loadingClients && clients.length === 0 ? (
            <Box sx={{ textAlign: 'center', margin: '24px 0' }}>
              <CircularProgress aria-label="Loading clients..." />
            </Box>
          ) : (
            <Grid container spacing={3} aria-label="Clients List" sx={{ marginBottom: '24px' }}>
              {clients.map((client) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={2}
                  key={client._id}
                  onClick={() => handleClientClick(client._id)}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    '&:hover .profile-circle': {
                      transform: 'scale(1.1)',
                      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  aria-label={`Client ${client.firstName} ${client.lastName}`}
                >
                  <Box
                    className="profile-circle"
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: '50%',
                      backgroundColor: styles.accent,
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: '8px',
                      transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    }}
                    aria-label={`Client Initials ${client.firstName[0]}${client.lastName[0]}`}
                  >
                    <Typography variant="h6" sx={{ color: styles.background }}>
                      {client.firstName[0]}
                      {client.lastName[0]}
                    </Typography>
                  </Box>
                  <Typography variant="body1" sx={{ color: styles.textPrimary }} aria-label="Client Name">
                    {client.firstName} {client.lastName}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
          {canLoadMoreClients && (
            <Box sx={{ textAlign: 'center', marginBottom: '48px' }}>
              <Button variant="contained" onClick={loadMoreClients} disabled={loadingClients}>
                {loadingClients ? 'Loading...' : 'Load More Clients'}
              </Button>
            </Box>
          )}

          {/* Modal for displaying clients under a specific care entity */}
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="entity-clients-modal"
            aria-describedby="entity-clients-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxHeight: '80vh',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '8px',
                overflowY: 'auto', // ensures scroll if content is long
              }}
              aria-label="Entity Clients Modal"
            >
              {/* Modal Header */}
              <Typography variant="h5" sx={{ marginBottom: '16px' }} id="entity-clients-modal">
                Clients under {careEntities.find((entity) => entity._id === selectedEntity)?.name}
              </Typography>

              {/* Modal Search */}
              <Box sx={{ display: 'flex', gap: 2, marginBottom: '16px' }}>
                <TextField
                  variant="outlined"
                  placeholder="Search assigned clients..."
                  value={modalSearchTerm}
                  onChange={handleModalSearchChange}
                  InputProps={{
                    startAdornment: <SearchIcon aria-label="Modal Search icon" />,
                  }}
                  inputProps={{
                    'aria-label': 'Search assigned clients',
                  }}
                  fullWidth
                />
              </Box>

              {/* Modal List of Clients */}
              <Box id="entity-clients-description">
                {modalLoading && modalClients.length === 0 ? (
                  <Box sx={{ textAlign: 'center', margin: '24px 0' }}>
                    <CircularProgress aria-label="Loading entity clients..." />
                  </Box>
                ) : modalClients.length > 0 ? (
                  <>
                    {modalClients.map((client) => (
                      <Typography
                        key={client._id}
                        variant="body1"
                        onClick={() => {
                          // Navigate to client profile and close modal
                          setOpenModal(false);
                          handleClientClick(client._id);
                        }}
                        sx={{
                          cursor: 'pointer',
                          padding: '8px 0',
                          borderBottom: '1px solid',
                          borderColor: styles.border,
                          borderRadius: '4px',
                          '&:hover': { backgroundColor: styles.lightBackground },
                        }}
                        aria-label={`Client ${client.firstName} ${client.lastName}`}
                      >
                        {client.firstName} {client.lastName}
                      </Typography>
                    ))}

                    {/* Load More inside Modal */}
                    {modalClients.length < modalTotal && (
                      <Box sx={{ textAlign: 'center', marginTop: '16px' }}>
                        <Button variant="contained" onClick={loadMoreModalClients} disabled={modalLoading}>
                          {modalLoading ? 'Loading...' : 'Load More'}
                        </Button>
                      </Box>
                    )}
                  </>
                ) : (
                  <Typography variant="body1" aria-label="No clients assigned to this care entity.">
                    No clients found under this care entity.
                  </Typography>
                )}
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

const WrappedCareNavigatorScreen = () => (
  <ErrorBoundary>
    <CareNavigatorScreen />
  </ErrorBoundary>
);

export default WrappedCareNavigatorScreen;