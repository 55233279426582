// src/AppRoutes.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// Screens
import LoginScreen from '../screens/LoginScreen';
import ForgotPassword from '../screens/ForgotPassword';
import ChooseOrganization from '../screens/ChooseOrganization';
import SignUpCompletion from '../screens/SignUpCompletion';
import HomeScreen from '../screens/HomeScreen';
import ProfileScreen from '../screens/ProfileScreen';
import UserProfileScreen from '../screens/UserProfileScreen';
import UserManagementScreen from '../screens/UserManagementScreen';
import CareEntitiesScreen from '../screens/CareEntitiesScreen';
import AssignCareEntityScreen from '../screens/AssignCareEntityScreen';
import ClientManagementScreen from '../screens/ClientManagementScreen';
import RoleManagerScreen from '../screens/RoleManagerScreen';
import CareVisualizerScreen from '../screens/CareVisualizerScreen';
import CarePlanTemplateBuilder from '../screens/CarePlanTemplateBuilder';
import TemplateManagement from '../screens/TemplateManagement';
import ClientProfileScreen from '../screens/ClientProfileScreen';
import CreateScreen from '../screens/CreateScreen';
import DefineScreen from '../screens/DefineScreen';
import AssignScreen from '../screens/AssignScreen';
import WorkflowScreen from '../screens/WorkflowScreen';
import MasterListManagementScreen from '../screens/MasterListManagementScreen';
import CustomTableManagementScreen from '../screens/CustomTableManagementScreen';
import StaticCarePlanScreen from '../screens/StaticCarePlanScreen';
import DynamicCarePlanScreen from '../screens/DynamicCarePlanScreen';
import CareEntityProfileScreen from '../screens/CareEntityProfileScreen';
import CareNavigator from '../screens/CareNavigator';
import MyTeamScreen from '../screens/MyTeamScreen';
import CustomClientFieldsScreen from '../screens/CustomClientFieldsScreen';
import PermissionsScreen from '../screens/PermissionsScreen';
import PasswordAndSecurity from '../screens/PasswordAndSecurity';
import ProcessLabelManagementScreen from '../screens/ProcessLabelManagementScreen';
import SavedReportsScreen from '../screens/SavedReportsScreen'; // Corrected Import Path
import ReportsScreen from '../screens/ReportsScreen'; // Example

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<PublicRoute><LoginScreen /></PublicRoute>} />
      <Route path="/login" element={<PublicRoute><LoginScreen /></PublicRoute>} />
      <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
      <Route path="/choose-organization" element={<PublicRoute><ChooseOrganization /></PublicRoute>} />
      <Route path="/signup-completion" element={<PublicRoute><SignUpCompletion /></PublicRoute>} />

      {/* Private / Auth-protected routes */}
      <Route path="/home" element={<PrivateRoute><HomeScreen /></PrivateRoute>} />
      <Route path="/profile" element={<PrivateRoute><ProfileScreen /></PrivateRoute>} />
      <Route path="/profile/:userId" element={<PrivateRoute><UserProfileScreen /></PrivateRoute>} />
      <Route path="/user-management" element={<PrivateRoute><UserManagementScreen /></PrivateRoute>} />
      <Route path="/care-entities" element={<PrivateRoute><CareEntitiesScreen /></PrivateRoute>} />
      <Route path="/clients" element={<PrivateRoute><ClientManagementScreen /></PrivateRoute>} />
      <Route path="/client-profile/:id" element={<PrivateRoute><ClientProfileScreen /></PrivateRoute>} />
      <Route path="/edit-client/:id" element={<PrivateRoute><ClientProfileScreen /></PrivateRoute>} />
      <Route path="/assign-care-entity/:id" element={<PrivateRoute><AssignCareEntityScreen /></PrivateRoute>} />
      <Route path="/care-plan-template-builder" element={<PrivateRoute><CarePlanTemplateBuilder /></PrivateRoute>} />
      <Route path="/role-manager" element={<PrivateRoute><RoleManagerScreen /></PrivateRoute>} />
      <Route path="/care-visualizer" element={<PrivateRoute><CareVisualizerScreen /></PrivateRoute>} />
      <Route path="/template-management" element={<PrivateRoute><TemplateManagement /></PrivateRoute>} />
      <Route path="/create" element={<PrivateRoute><CreateScreen /></PrivateRoute>} />
      <Route path="/define" element={<PrivateRoute><DefineScreen /></PrivateRoute>} />
      <Route path="/assign" element={<PrivateRoute><AssignScreen /></PrivateRoute>} />
      <Route path="/workflow" element={<PrivateRoute><WorkflowScreen /></PrivateRoute>} />
      <Route path="/master-list-management" element={<PrivateRoute><MasterListManagementScreen /></PrivateRoute>} />
      <Route path="/custom-table-management" element={<PrivateRoute><CustomTableManagementScreen /></PrivateRoute>} />
      
      {/* Corrected Route for Saved Reports */}
      <Route path="/saved-reports" element={
        <PrivateRoute>
          <SavedReportsScreen /> {/* Corrected Component */}
        </PrivateRoute>
      } />

      <Route
        path="/static-care-plan/:templateID/:clientID"
        element={<PrivateRoute><StaticCarePlanScreen /></PrivateRoute>}
      />
      <Route
        path="/dynamic-care-plan/:templateID/:clientID"
        element={<PrivateRoute><DynamicCarePlanScreen /></PrivateRoute>}
      />

      <Route
        path="/static-care-plan-entity/:templateID/:careEntityID"
        element={<PrivateRoute><StaticCarePlanScreen /></PrivateRoute>}
      />
      <Route
        path="/dynamic-care-plan-entity/:templateID/:careEntityID"
        element={<PrivateRoute><DynamicCarePlanScreen /></PrivateRoute>}
      />

      <Route
        path="/care-entity-profile/:id"
        element={<PrivateRoute><CareEntityProfileScreen /></PrivateRoute>}
      />
      <Route
        path="/care-navigator"
        element={<PrivateRoute><CareNavigator /></PrivateRoute>}
      />
      <Route
        path="/my-team"
        element={<PrivateRoute><MyTeamScreen /></PrivateRoute>}
      />
      <Route
        path="/custom-client-fields"
        element={<PrivateRoute><CustomClientFieldsScreen /></PrivateRoute>}
      />
      <Route
        path="/permissions/:roleId"
        element={<PrivateRoute><PermissionsScreen /></PrivateRoute>}
      />
      <Route
        path="/password-and-security"
        element={<PrivateRoute><PasswordAndSecurity /></PrivateRoute>}
      />
      <Route
        path="/process-label-management"
        element={<PrivateRoute><ProcessLabelManagementScreen /></PrivateRoute>}
      />

      {/* Reports Section */}
      <Route
        path="/reports"
        element={<PrivateRoute><ReportsScreen /></PrivateRoute>}
      />

    </Routes>
  );
};

export default AppRoutes;