// src/screens/MyTeamScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Modal,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { styles } from '../styles/styles';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
// Remove or use CollapseAllIcon as needed
// import CollapseAllIcon from '@mui/icons-material/UnfoldLess'; // Choose an appropriate icon

const MyTeamScreen = () => {
  const navigate = useNavigate();
  const { user, permissions = [], loading: authLoading } = useAuth(); // Assuming 'user' is provided
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersTotal, setTeamMembersTotal] = useState(0);
  const [teamMembersOffset, setTeamMembersOffset] = useState(0);

  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState({});
  const [selectedContact, setSelectedContact] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  const limit = 12; // Number of care entities/team members to load per request

  // Fetch team members with sorting and filtering
  const fetchTeamMembers = useCallback(
    async (append = false) => {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get(`${API_BASE_URL}/api/careentities/team-members`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            limit,
            offset: teamMembersOffset,
            search, // Optional: Implement backend search if supported
          },
        });

        // Expecting response in the format: { teamMembers: [...], total: number }
        let newMembers = response.data.teamMembers || [];
        const total = response.data.total || 0;

        // Filter out the authenticated user and sort staff members alphabetically by first name
        newMembers = newMembers.map((entity) => {
          const filteredStaff = entity.staff
            .filter((member) => member._id !== user.id) // Exclude authenticated user
            .sort((a, b) => a.firstName.localeCompare(b.firstName)); // Sort A-Z by first name
          return { ...entity, staff: filteredStaff };
        });

        setTeamMembersTotal(total);
        if (append) {
          setTeamMembers((prev) => [...prev, ...newMembers]);
        } else {
          setTeamMembers(newMembers);
        }

        // Initialize expanded state if not appending
        if (!append) {
          const initialExpandedState = newMembers.reduce((acc, entity) => {
            acc[entity.careEntity._id] = true;
            return acc;
          }, {});
          setExpanded(initialExpandedState);
        }
      } catch (error) {
        console.error('Error fetching team members:', error);
        setTeamMembers([]);
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch team members.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    },
    [API_BASE_URL, teamMembersOffset, limit, user.id, search]
  );

  useEffect(() => {
    fetchTeamMembers();
  }, [fetchTeamMembers]);

  // Handle search input change with debouncing
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setTeamMembersOffset(0);
    setTeamMembers([]);
    setExpanded({});
  };

  // Debounce the search effect
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchTeamMembers(false);
    }, 300); // 300ms debounce

    return () => clearTimeout(timer);
  }, [search, fetchTeamMembers]);

  // Toggle expand/collapse for a single care entity
  const handleExpandClick = (entityId) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [entityId]: !prevExpanded[entityId],
    }));
  };

  // Collapse all care entities
  const handleCollapseAll = () => {
    const allCollapsed = {};
    teamMembers.forEach((entity) => {
      allCollapsed[entity.careEntity._id] = false;
    });
    setExpanded(allCollapsed);
  };

  // Handle contact click to open modal
  const handleContactClick = (member) => {
    setSelectedContact(member);
  };

  const handleCloseModal = () => {
    setSelectedContact(null);
  };

  // Filter team members based on search (optional if backend handles it)
  const filteredTeamMembers = teamMembers.filter((entity) => {
    return entity.staff.some((member) =>
      `${member.firstName} ${member.lastName}`.toLowerCase().includes(search.toLowerCase())
    );
  });

  // Check if the user has view permission
  const hasViewPermission = permissions.includes('view_my-team');

  if (authLoading) { // Changed from `if (loading || authLoading)`
    // Always render Navbar and Sidebar to prevent flashing
    return (
      <>
        <NavigationBar />
        <Box sx={{ display: 'flex' }}>
          <Sidebar />
          <Box
            flexGrow={1}
            sx={{
              padding: '24px',
              marginTop: '64px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
            }}
          >
            <Typography variant="h6">Loading...</Typography>
          </Box>
        </Box>
      </>
    );
  }

  if (!hasViewPermission) {
    return (
      <>
        <NavigationBar />
        <Box sx={{ display: 'flex' }}>
          <Sidebar />
          <Box sx={{ padding: '24px', marginTop: '64px' }}>
            <Typography variant="h6" color="error">
              You do not have permission to view this page.
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  const canLoadMore = teamMembers.length < teamMembersTotal;

  const loadMoreTeamMembers = () => {
    setTeamMembersOffset((prevOffset) => prevOffset + limit);
    fetchTeamMembers(true);
  };

  return (
    <>
      <NavigationBar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
          {/* Search and Collapse All */}
          <Box sx={{ display: 'flex', gap: '16px', marginBottom: '24px', alignItems: 'center' }}>
            <TextField
              variant="outlined"
              placeholder="Search team members..."
              value={search}
              onChange={handleSearchChange}
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon aria-label="Search icon" />,
              }}
              aria-label="Search Team Members"
            />
            <Tooltip title="Collapse All" arrow>
              <IconButton aria-label="Collapse All Care Entities" onClick={handleCollapseAll}>
                {/* Use CollapseAllIcon if preferred */}
                <ExpandLessIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Typography component="h2" variant="h5" sx={{ marginBottom: '24px' }}>
            My Team
          </Typography>

          {filteredTeamMembers.map((entity) => (
            <Box key={entity.careEntity._id} sx={{ marginBottom: '24px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <Typography
                  variant="h6"
                  sx={{ flexGrow: 1 }}
                  aria-label={`Care Entity: ${entity.careEntity.name || 'No Care Entity'}`}
                >
                  {entity.careEntity.name || 'No Care Entity'}
                </Typography>
                <IconButton
                  onClick={() => handleExpandClick(entity.careEntity._id)}
                  aria-label={`Expand ${entity.careEntity.name}`}
                >
                  {expanded[entity.careEntity._id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>

              {expanded[entity.careEntity._id] && (
                <Grid container spacing={3} sx={{ marginBottom: '48px' }}>
                  {entity.staff.map((member) => (
                    <Grid
                      item
                      xs={12}
                      sm={4} // Increased from 6 to 4 for small screens (3 per row)
                      md={3} // Increased from 4 to 3 for medium screens (4 per row)
                      lg={2} // Increased from 3 to 2 for large screens (6 per row)
                      key={member._id}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '&:hover .profile-circle': {
                          transform: 'scale(1.1)',
                          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                        },
                      }}
                      onClick={() => handleContactClick(member)}
                      aria-label={`Team Member: ${member.firstName} ${member.lastName}`}
                    >
                      <Box
                        className="profile-circle"
                        sx={{
                          width: 80,
                          height: 80,
                          borderRadius: '50%',
                          backgroundColor: styles.accent,
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '8px',
                          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                        }}
                      >
                        <Typography variant="h6" sx={{ color: styles.background }}>
                          {member.firstName[0]}{member.lastName[0]}
                        </Typography>
                      </Box>
                      <Typography variant="body1" sx={{ color: styles.textPrimary }}>
                        {member.firstName} {member.lastName}
                      </Typography>
                      <Typography variant="body2" sx={{ color: styles.textSecondary }}>
                        {member.jobTitle || ''}
                      </Typography>
                      <Box sx={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
                        <Tooltip title={member.phoneNumber || 'No record found'} arrow>
                          <IconButton
                            sx={{ color: styles.textPrimary, fontSize: '1.2rem' }}
                            aria-label={`Phone number of ${member.firstName} ${member.lastName}`}
                          >
                            <PhoneIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={member.email || 'No record found'} arrow>
                          <IconButton
                            sx={{ color: styles.textPrimary, fontSize: '1.2rem' }}
                            aria-label={`Email of ${member.firstName} ${member.lastName}`}
                          >
                            <EmailIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          ))}

          {canLoadMore && (
            <Box sx={{ textAlign: 'center', marginBottom: '48px' }}>
              <Button
                variant="contained"
                onClick={loadMoreTeamMembers}
                disabled={teamMembers.length >= teamMembersTotal}
              >
                {teamMembers.length >= teamMembersTotal ? 'No More Team Members' : 'Load More'}
              </Button>
            </Box>
          )}

          {/* Contact Information Modal */}
          <Modal
            open={!!selectedContact}
            onClose={handleCloseModal}
            aria-labelledby="contact-modal-title"
            aria-describedby="contact-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '8px',
              }}
            >
              <Typography id="contact-modal-title" variant="h6" component="h2">
                Contact Information
              </Typography>
              {selectedContact && (
                <>
                  <Typography id="contact-modal-description" sx={{ mt: 2 }}>
                    <strong>Name:</strong> {selectedContact.firstName} {selectedContact.lastName}
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    <strong>Job Title:</strong> {selectedContact.jobTitle || 'No record found'}
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    <strong>Phone:</strong> {selectedContact.phoneNumber || 'No record found'}
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    <strong>Email:</strong> {selectedContact.email || 'No record found'}
                  </Typography>
                </>
              )}
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

// Wrap the component with ErrorBoundary for better error handling
const WrappedMyTeamScreen = () => (
  <ErrorBoundary>
    <MyTeamScreen />
  </ErrorBoundary>
);

export default WrappedMyTeamScreen;