// components/ReportBuilder.js
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

// Material UI imports
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  TextField,
  Divider,
  Alert,
  CircularProgress,
  Snackbar,
  Modal,
  Tooltip,
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import ReactJson from 'react-json-view';

// For pivot or other advanced UI features (unchanged)
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';

/** 
 * STEP ENUM for clarity. 
 */
const STEP = {
  SELECT_COLLECTION: 0,
  FIELDS_SORT: 1,
  FILTERS_AGG: 2,
  LAYOUT_PIVOT: 3,
  FINAL_REVIEW: 4,
};

const stepLabels = [
  'Select Collection',
  'Pick Fields & Sorting',
  'Filters & Grouping',
  'Layout & Pivot',
  'Final Review',
];

/**
 * Convert array-of-objects to CSV
 */
function convertToCSV(data) {
  if (!Array.isArray(data) || data.length === 0) return '';
  const headers = Object.keys(data[0]);
  const rows = data.map((row) =>
    headers
      .map((field) => {
        const value = row[field];
        if (Array.isArray(value)) {
          return `"${value.map((item) => JSON.stringify(item)).join('; ')}"`;
        } else if (typeof value === 'object' && value !== null) {
          return `"${JSON.stringify(value)}"`;
        } else {
          return `"${value ?? ''}"`;
        }
      })
      .join(',')
  );
  return [headers.join(','), ...rows].join('\n');
}

function downloadFile(content, fileName, mimeType) {
  const blob = new Blob([content], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

/** Simple cell renderer for DataGrid cells */
const renderCellCustom = (params) => {
  const { value } = params;
  if (Array.isArray(value)) {
    if (value.length > 0 && typeof value[0] === 'object' && value[0] !== null) {
      if (value[0].hasOwnProperty('name')) {
        return value.map((item) => item.name).join(', ');
      } else {
        return value.map((item) => JSON.stringify(item)).join(', ');
      }
    }
    return value.join(', ');
  }
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value);
  }
  return value;
};

export default function ReportBuilder() {
  const [activeStep, setActiveStep] = useState(STEP.SELECT_COLLECTION);

  // Collections
  const [collections] = useState([
    { value: 'CarePlanData', label: 'CarePlanData' },
    { value: 'CarePlanTemplate', label: 'CarePlanTemplate' },
    { value: 'Client', label: 'Client' },
    { value: 'User', label: 'User' },
    { value: 'AuditLog', label: 'AuditLog' },
    { value: 'CareEntity', label: 'CareEntity' },
    { value: 'CustomField', label: 'CustomField' },
    { value: 'CustomTable', label: 'CustomTable' },
    { value: 'MasterList', label: 'MasterList' },
    { value: 'ProcessLabel', label: 'ProcessLabel' },
    { value: 'RoleType', label: 'RoleType' },
  ]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [availableFields, setAvailableFields] = useState([]);

  // Our main config
  const [reportConfig, setReportConfig] = useState({
    fields: [],
    // We'll store sorting in a table-based approach:
    sorting: [], // each: { field: string, order: 'asc'|'desc' }
    relationships: [], // renamed from "lookups"
    filters: [],
    groupBy: [],
    aggregations: [],
    expandArrays: [],
    limit: 100,
    // Layout & Pivot
    layout: {
      columns: [],
    },
    pivot: {
      rows: [],
      cols: [],
      aggregatorName: 'Count',
      vals: [],
      rendererName: 'Table',
    },
  });

  // Additional UI states
  const [reportName, setReportName] = useState('');
  const [reportResults, setReportResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [previewModal, setPreviewModal] = useState({ open: false, pipeline: [], sampleData: [] });

  // ======================
  // COLLECTION SELECTION
  // ======================
  const handleCollectionChange = (value) => {
    const newCol = collections.find((c) => c.value === value) || null;
    setSelectedCollection(newCol);
    // Reset pipeline fields
    setReportResults([]);
    setReportConfig({
      fields: [],
      sorting: [],
      relationships: [],
      filters: [],
      groupBy: [],
      aggregations: [],
      expandArrays: [],
      limit: 100,
      layout: { columns: [] },
      pivot: {
        rows: [],
        cols: [],
        aggregatorName: 'Count',
        vals: [],
        rendererName: 'Table',
      },
    });
  };

  // Fetch fields
  useEffect(() => {
    if (!selectedCollection) return;
    setErrorMessage('');
    setIsLoading(true);
    axios
      .get(`/api/reports/metadata/fields?collection=${selectedCollection.value}`)
      .then((res) => {
        const fieldsFromServer = res.data.fields || [];
        setAvailableFields(fieldsFromServer);
      })
      .catch((err) => {
        console.error('Error fetching fields:', err);
        setErrorMessage('Failed to fetch fields.');
      })
      .finally(() => setIsLoading(false));
  }, [selectedCollection]);

  // ======================
  // WIZARD NAV
  // ======================
  const handleNext = () => {
    if (!checkCanProceed()) return;

    // If moving from Step 1 to Step 2, we can initialize the layout columns
    // with whatever the user has chosen in "reportConfig.fields".
    if (activeStep === STEP.FIELDS_SORT) {
      setReportConfig((prev) => {
        if (!prev.layout.columns || prev.layout.columns.length === 0) {
          const newCols = prev.fields.map((f, idx) => ({
            field: f,
            label: f,
            hidden: false,
            order: idx,
          }));
          return {
            ...prev,
            layout: {
              ...prev.layout,
              columns: newCols,
            },
          };
        }
        return prev;
      });
    }

    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setActiveStep(STEP.SELECT_COLLECTION);
    setSelectedCollection(null);
    setAvailableFields([]);
    setReportResults([]);
    setReportName('');
    setReportConfig({
      fields: [],
      sorting: [],
      relationships: [],
      filters: [],
      groupBy: [],
      aggregations: [],
      expandArrays: [],
      limit: 100,
      layout: { columns: [] },
      pivot: {
        rows: [],
        cols: [],
        aggregatorName: 'Count',
        vals: [],
        rendererName: 'Table',
      },
    });
  };

  // Minimal step-based validation
  const checkCanProceed = () => {
    switch (activeStep) {
      case STEP.SELECT_COLLECTION:
        if (!selectedCollection) {
          setSnackbar({
            open: true,
            message: 'Please select a collection before proceeding.',
            severity: 'error',
          });
          return false;
        }
        return true;
      case STEP.FIELDS_SORT:
        if (reportConfig.fields.length === 0) {
          setSnackbar({
            open: true,
            message: 'Please pick at least one field.',
            severity: 'error',
          });
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  // ======================
  // PIPELINE EXECUTION
  // ======================
  const flattenData = (data, expandFields) => {
    if (!Array.isArray(data)) return [];
    let flattened = [...data];
    expandFields.forEach((fieldPath) => {
      if (fieldPath === 'auto') return;
      flattened = flattened.flatMap((doc) => {
        if (Array.isArray(doc[fieldPath])) {
          return doc[fieldPath].map((item) => ({ ...doc, [fieldPath]: item }));
        }
        return [doc];
      });
    });
    return flattened;
  };

  const runPipeline = async () => {
    if (!selectedCollection) return;
    setIsLoading(true);
    setReportResults([]);
    setErrorMessage('');
    try {
      // Construct the final payload
      const payload = {
        targetCollection: selectedCollection.value,
        fields: reportConfig.fields,
        sort: reportConfig.sorting.map((s) => ({ field: s.field, order: s.order })),
        // ADDED: Pass useLookup to the server for each relationship
        lookups: reportConfig.relationships.map((rel) => ({
          localField: rel.localField,
          from: rel.joinCollection,
          projectFields: rel.projectFields || [],
          as: rel.alias,
          useLookup: rel.useLookup !== false, // default is true unless explicitly false
        })),
        unwind: reportConfig.expandArrays,
        filters: reportConfig.filters.map((f) => ({
          field: f.field,
          operator: f.operator,
          value: f.value,
        })),
        groupBy: reportConfig.groupBy,
        aggregations: reportConfig.aggregations,
        limit: reportConfig.limit,
      };

      const res = await axios.post('/api/reports/execute', payload);
      let rawData = res.data.data || [];
      rawData = flattenData(rawData, reportConfig.expandArrays);
      setReportResults(rawData);
      setSnackbar({ open: true, message: 'Report executed successfully!', severity: 'success' });
    } catch (error) {
      console.error(error);
      const serverMessage = error.response?.data?.error || 'Error running report.';
      setSnackbar({ open: true, message: serverMessage, severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  // Preview pipeline (sample)
  const handlePreviewPipeline = async () => {
    if (!selectedCollection) return;
    setIsLoading(true);
    setPreviewModal({ open: false, pipeline: [], sampleData: [] });
    try {
      const payload = {
        targetCollection: selectedCollection.value,
        fields: reportConfig.fields,
        sort: reportConfig.sorting.map((s) => ({ field: s.field, order: s.order })),
        // ADDED: Pass useLookup
        lookups: reportConfig.relationships.map((rel) => ({
          localField: rel.localField,
          from: rel.joinCollection,
          projectFields: rel.projectFields || [],
          as: rel.alias,
          useLookup: rel.useLookup !== false,
        })),
        unwind: reportConfig.expandArrays,
        filters: reportConfig.filters.map((f) => ({
          field: f.field,
          operator: f.operator,
          value: f.value,
        })),
        groupBy: reportConfig.groupBy,
        aggregations: reportConfig.aggregations,
        limit: 5,
      };
      const res = await axios.post('/api/reports/preview', payload);
      const { pipeline, sampleData } = res.data;
      setPreviewModal({ open: true, pipeline, sampleData });
    } catch (error) {
      console.error(error);
      const serverMessage = error.response?.data?.error || 'Error previewing pipeline.';
      setSnackbar({ open: true, message: serverMessage, severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  // Save the report
  const handleSaveReport = async () => {
    if (!selectedCollection) {
      setSnackbar({
        open: true,
        message: 'Please select a collection before saving.',
        severity: 'error',
      });
      return;
    }
    if (reportConfig.fields.length === 0) {
      setSnackbar({ open: true, message: 'Please pick at least one field.', severity: 'error' });
      return;
    }
    if (!reportName.trim()) {
      setSnackbar({ open: true, message: 'Please provide a report name.', severity: 'warning' });
      return;
    }
    setIsLoading(true);
    try {
      const payload = {
        name: reportName.trim(),
        configuration: {
          targetCollection: selectedCollection.value,
          fields: reportConfig.fields,
          sorting: reportConfig.sorting,
          // ADDED: Save useLookup in relationships
          relationships: reportConfig.relationships.map((rel) => ({
            ...rel,
            useLookup: rel.useLookup !== false,
          })),
          filters: reportConfig.filters,
          groupBy: reportConfig.groupBy,
          aggregations: reportConfig.aggregations,
          expandArrays: reportConfig.expandArrays,
          limit: reportConfig.limit,
          layout: reportConfig.layout,
          pivot: reportConfig.pivot,
        },
      };
      await axios.post('/api/reports', payload);
      setSnackbar({ open: true, message: 'Report saved successfully!', severity: 'success' });
    } catch (error) {
      console.error(error);
      const serverMessage = error.response?.data?.error || 'Error saving report.';
      setSnackbar({ open: true, message: serverMessage, severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  // Exporting
  const applyLayout = (data) => {
    if (!reportConfig.layout?.columns) return data;
    const layoutMap = {};
    reportConfig.layout.columns.forEach((col) => {
      layoutMap[col.field] = col;
    });
    return data.map((rowObj) => {
      const newObj = {};
      Object.entries(rowObj).forEach(([k, v]) => {
        if (layoutMap[k] && !layoutMap[k].hidden) {
          newObj[layoutMap[k].label || k] = v;
        }
      });
      return newObj;
    });
  };

  const handleExportCSV = () => {
    if (!Array.isArray(reportResults) || reportResults.length === 0) {
      setSnackbar({ open: true, message: 'No valid data to export.', severity: 'warning' });
      return;
    }
    const processedRows = applyLayout(reportResults);
    const csv = convertToCSV(processedRows);
    downloadFile(csv, 'report.csv', 'text/csv');
    setSnackbar({ open: true, message: 'CSV exported successfully!', severity: 'success' });
  };

  const handleExportExcel = () => {
    if (!Array.isArray(reportResults) || reportResults.length === 0) {
      setSnackbar({ open: true, message: 'No valid data to export.', severity: 'warning' });
      return;
    }
    const processedRows = applyLayout(reportResults);
    const worksheet = XLSX.utils.json_to_sheet(processedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    downloadFile(
      excelBuffer,
      'report.xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    setSnackbar({ open: true, message: 'Excel exported successfully!', severity: 'success' });
  };

  // ======================
  // DataGrid columns/rows
  // ======================
  const columns = useMemo(() => {
    if (!Array.isArray(reportResults) || reportResults.length === 0) return [];
    const processedRows = applyLayout(reportResults);

    const uniqueFields = new Set();
    processedRows.forEach((obj) => {
      Object.keys(obj).forEach((k) => uniqueFields.add(k));
    });

    if (!reportConfig.layout?.columns) {
      // alphabetical
      return Array.from(uniqueFields)
        .sort()
        .map((f) => ({
          field: f,
          headerName: f,
          flex: 1,
          minWidth: 150,
          renderCell: renderCellCustom,
        }));
    }

    const layoutCols = reportConfig.layout.columns
      .filter((c) => !c.hidden)
      .sort((a, b) => a.order - b.order)
      .map((col) => {
        const colKey = col.label || col.field;
        return {
          field: colKey,
          headerName: colKey,
          flex: 1,
          minWidth: 150,
          renderCell: renderCellCustom,
        };
      });

    const extraCols = Array.from(uniqueFields)
      .filter((f) => !layoutCols.some((lc) => lc.field === f))
      .map((f) => ({
        field: f,
        headerName: f,
        flex: 1,
        minWidth: 150,
        renderCell: renderCellCustom,
      }));

    return [...layoutCols, ...extraCols];
  }, [reportResults, reportConfig.layout]);

  const rows = useMemo(() => {
    if (!Array.isArray(reportResults) || reportResults.length === 0) return [];
    return reportResults.map((row, idx) => ({
      id: row._id || `row-${idx}`,
      ...row,
    }));
  }, [reportResults]);

  // ======================
  // PARTIAL FILTER PREVIEW
  // ======================
  const handlePartialPreview = async () => {
    if (!selectedCollection) return;
    setIsLoading(true);
    try {
      const payload = {
        targetCollection: selectedCollection.value,
        fields: reportConfig.fields,
        filters: reportConfig.filters.map((f) => ({
          field: f.field,
          operator: f.operator,
          value: f.value,
        })),
        limit: 5,
      };
      const res = await axios.post('/api/reports/preview', payload);
      setSnackbar({
        open: true,
        message: `Preview returned ${res.data.sampleData.length} rows (see console).`,
        severity: 'info',
      });
      console.log('Partial preview data:', res.data.sampleData);
    } catch (error) {
      console.error(error);
      const serverMessage = error.response?.data?.error || 'Error partial preview.';
      setSnackbar({ open: true, message: serverMessage, severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  // ======================
  // RENDER STEP CONTENT
  // ======================
  const renderStepContent = () => {
    switch (activeStep) {
      case STEP.SELECT_COLLECTION:
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">STEP 0: Select Collection</Typography>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="collection-label">Collection</InputLabel>
              <Select
                labelId="collection-label"
                value={selectedCollection?.value || ''}
                label="Collection"
                onChange={(e) => handleCollectionChange(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {collections.map((c) => (
                  <MenuItem key={c.value} value={c.value}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        );

      case STEP.FIELDS_SORT:
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              STEP 1: Pick Fields & Sorting (Table-Based)
            </Typography>
            <Divider sx={{ mb: 2 }} />

            {/* Pick fields */}
            <Typography variant="subtitle2">Choose Fields</Typography>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="fields-label">Fields</InputLabel>
              <Select
                labelId="fields-label"
                id="fields-select"
                multiple
                value={reportConfig.fields}
                onChange={(e) =>
                  setReportConfig((prev) => ({ ...prev, fields: e.target.value }))
                }
                input={<OutlinedInput label="Fields" />}
                renderValue={(selected) => selected.join(', ')}
              >
                {availableFields.map((field) => (
                  <MenuItem key={field} value={field}>
                    <Checkbox checked={reportConfig.fields.indexOf(field) > -1} />
                    <ListItemText primary={field} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Divider sx={{ my: 2 }} />

            {/* Table-based sorting */}
            <Typography variant="subtitle2" gutterBottom>
              Sorting (Add multiple criteria below)
            </Typography>
            {reportConfig.sorting.map((sortItem, idx) => (
              <Box
                key={idx}
                sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center', flexWrap: 'wrap' }}
              >
                <FormControl sx={{ minWidth: 160 }}>
                  <InputLabel id={`sort-field-${idx}-label`}>Sort Field</InputLabel>
                  <Select
                    labelId={`sort-field-${idx}-label`}
                    id={`sort-field-${idx}`}
                    value={sortItem.field || ''}
                    label="Sort Field"
                    onChange={(e) => {
                      const updated = [...reportConfig.sorting];
                      updated[idx].field = e.target.value;
                      setReportConfig((prev) => ({ ...prev, sorting: updated }));
                    }}
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {availableFields.map((f) => (
                      <MenuItem key={f} value={f}>
                        {f}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 100 }}>
                  <InputLabel id={`sort-order-${idx}-label`}>Order</InputLabel>
                  <Select
                    labelId={`sort-order-${idx}-label`}
                    id={`sort-order-${idx}`}
                    value={sortItem.order || 'asc'}
                    label="Order"
                    onChange={(e) => {
                      const updated = [...reportConfig.sorting];
                      updated[idx].order = e.target.value;
                      setReportConfig((prev) => ({ ...prev, sorting: updated }));
                    }}
                  >
                    <MenuItem value="asc">ASC</MenuItem>
                    <MenuItem value="desc">DESC</MenuItem>
                  </Select>
                </FormControl>

                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    const updated = reportConfig.sorting.filter((_, i2) => i2 !== idx);
                    setReportConfig((prev) => ({ ...prev, sorting: updated }));
                  }}
                >
                  Remove
                </Button>
              </Box>
            ))}

            <Button
              variant="outlined"
              onClick={() =>
                setReportConfig((prev) => ({
                  ...prev,
                  sorting: [...prev.sorting, { field: '', order: 'asc' }],
                }))
              }
            >
              Add Sort Criterion
            </Button>

            <Divider sx={{ my: 2 }} />

            {/* Relationships (previously "Lookups") */}
            <Typography variant="subtitle2">
              Join Another Collection (Optional)
              <Tooltip
                title="Use this to fetch related documents from a second collection, e.g. localField: managerID, from: 'User'"
                arrow
              >
                <span style={{ marginLeft: 8, cursor: 'help', color: '#888' }}>?</span>
              </Tooltip>
            </Typography>
            {reportConfig.relationships.map((rel, idx) => (
              <Box
                key={idx}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  mt: 1,
                  alignItems: 'flex-start',
                }}
              >
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
                  {/* Local Field */}
                  <FormControl sx={{ minWidth: 160 }}>
                    <TextField
                      select
                      label="Local Field"
                      value={rel.localField}
                      onChange={(e) => {
                        const updated = [...reportConfig.relationships];
                        updated[idx].localField = e.target.value;
                        setReportConfig((prev) => ({ ...prev, relationships: updated }));
                      }}
                      helperText="Field in this collection referencing another collection"
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {availableFields.map((f) => (
                        <MenuItem key={f} value={f}>
                          {f}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>

                  {/* joinCollection */}
                  <FormControl sx={{ minWidth: 160 }}>
                    <TextField
                      select
                      label="Join Collection"
                      value={rel.joinCollection}
                      onChange={(e) => {
                        const updated = [...reportConfig.relationships];
                        updated[idx].joinCollection = e.target.value;
                        setReportConfig((prev) => ({ ...prev, relationships: updated }));
                      }}
                      helperText="Which collection to join?"
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {collections.map((c) => (
                        <MenuItem key={c.value} value={c.value}>
                          {c.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>

                  {/* projectFields */}
                  <Tooltip title="Select which fields you want from the joined collection" arrow>
                    <FormControl sx={{ minWidth: 160 }}>
                      <TextField
                        label="Project Fields"
                        placeholder="e.g. _id, name"
                        value={(rel.projectFields || []).join(',')}
                        onChange={(e) => {
                          const updated = [...reportConfig.relationships];
                          updated[idx].projectFields = e.target.value
                            .split(',')
                            .map((s) => s.trim())
                            .filter(Boolean);
                          setReportConfig((prev) => ({ ...prev, relationships: updated }));
                        }}
                      />
                    </FormControl>
                  </Tooltip>

                  {/* alias (as) */}
                  <FormControl sx={{ minWidth: 140 }}>
                    <TextField
                      label="Alias"
                      placeholder="e.g. managerDetails"
                      value={rel.alias || ''}
                      onChange={(e) => {
                        const updated = [...reportConfig.relationships];
                        updated[idx].alias = e.target.value;
                        setReportConfig((prev) => ({ ...prev, relationships: updated }));
                      }}
                    />
                  </FormControl>

                  {/* ADDED: Checkbox for useLookup */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rel.useLookup !== false}
                        onChange={(e) => {
                          const updated = [...reportConfig.relationships];
                          updated[idx].useLookup = e.target.checked;
                          setReportConfig((prev) => ({ ...prev, relationships: updated }));
                        }}
                      />
                    }
                    label="Use Lookup?"
                  />

                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      const updated = reportConfig.relationships.filter((_, i2) => i2 !== idx);
                      setReportConfig((prev) => ({ ...prev, relationships: updated }));
                    }}
                  >
                    Remove
                  </Button>
                </Box>
              </Box>
            ))}

            <Button
              variant="outlined"
              sx={{ mt: 2 }}
              onClick={() =>
                setReportConfig((prev) => ({
                  ...prev,
                  relationships: [
                    ...prev.relationships,
                    {
                      localField: '',
                      joinCollection: '',
                      projectFields: [],
                      alias: '',
                      useLookup: true, // ADDED default
                    },
                  ],
                }))
              }
            >
              Add Relationship
            </Button>
          </Box>
        );

      case STEP.FILTERS_AGG:
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              STEP 2: Filters & Grouping
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              A visual approach to building filters. Each filter row is "Field → Operator → Value".
              You can do partial previews to see how filters reduce your data.
            </Typography>

            {/* VISUAL FILTER BUILDER */}
            {reportConfig.filters.map((flt, idx) => (
              <Box
                key={idx}
                sx={{ display: 'flex', gap: 1, mt: 1, flexWrap: 'wrap', alignItems: 'center' }}
              >
                {/* Field */}
                <FormControl sx={{ width: 180 }}>
                  <InputLabel>Field</InputLabel>
                  <Select
                    value={flt.field || ''}
                    label="Field"
                    onChange={(e) => {
                      const updated = [...reportConfig.filters];
                      updated[idx].field = e.target.value;
                      setReportConfig((prev) => ({ ...prev, filters: updated }));
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {availableFields.map((f) => (
                      <MenuItem key={f} value={f}>
                        {f}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Operator */}
                <FormControl sx={{ width: 140 }}>
                  <InputLabel>Operator</InputLabel>
                  <Select
                    value={flt.operator || 'equals'}
                    label="Operator"
                    onChange={(e) => {
                      const updated = [...reportConfig.filters];
                      updated[idx].operator = e.target.value;
                      setReportConfig((prev) => ({ ...prev, filters: updated }));
                    }}
                  >
                    <MenuItem value="equals">Equals</MenuItem>
                    <MenuItem value="not_equals">Not Equals</MenuItem>
                    <MenuItem value="greater_than">Greater Than</MenuItem>
                    <MenuItem value="less_than">Less Than</MenuItem>
                    <MenuItem value="gte">≥</MenuItem>
                    <MenuItem value="lte">≤</MenuItem>
                    <MenuItem value="in">In</MenuItem>
                    <MenuItem value="not_in">Not In</MenuItem>
                    <MenuItem value="exists">Exists</MenuItem>
                    <MenuItem value="regex">Regex</MenuItem>
                    <MenuItem value="and">AND</MenuItem>
                    <MenuItem value="or">OR</MenuItem>
                  </Select>
                </FormControl>

                {/* Value input */}
                {['and', 'or'].includes(flt.operator) ? (
                  <TextField
                    label="Value (JSON Array of subfilters)"
                    value={flt.value ? JSON.stringify(flt.value) : ''}
                    onChange={(e) => {
                      try {
                        const parsed = JSON.parse(e.target.value);
                        const updated = [...reportConfig.filters];
                        updated[idx].value = parsed;
                        setReportConfig((prev) => ({ ...prev, filters: updated }));
                      } catch {
                        // ignore parse errors
                      }
                    }}
                    sx={{ width: 300 }}
                  />
                ) : (
                  <TextField
                    label="Value"
                    value={flt.value || ''}
                    onChange={(e) => {
                      const updated = [...reportConfig.filters];
                      updated[idx].value = e.target.value;
                      setReportConfig((prev) => ({ ...prev, filters: updated }));
                    }}
                    sx={{ width: 200 }}
                  />
                )}

                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    const updated = reportConfig.filters.filter((_, i2) => i2 !== idx);
                    setReportConfig((prev) => ({ ...prev, filters: updated }));
                  }}
                >
                  Remove
                </Button>
              </Box>
            ))}
            <Button
              variant="outlined"
              sx={{ mt: 1 }}
              onClick={() =>
                setReportConfig((prev) => ({
                  ...prev,
                  filters: [...prev.filters, { field: '', operator: 'equals', value: '' }],
                }))
              }
            >
              + Add Another Filter
            </Button>

            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                onClick={handlePartialPreview}
                disabled={!selectedCollection || isLoading}
              >
                Quick Preview with Filters
              </Button>
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* Group By */}
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Group By & Aggregations
            </Typography>
            {reportConfig.groupBy.map((gb, idx) => (
              <Box key={idx} sx={{ display: 'flex', gap: 1, mt: 1, alignItems: 'center' }}>
                <FormControl sx={{ width: 180 }}>
                  <InputLabel>Group Field</InputLabel>
                  <Select
                    value={gb.field || ''}
                    label="Group Field"
                    onChange={(e) => {
                      const updated = [...reportConfig.groupBy];
                      updated[idx].field = e.target.value;
                      setReportConfig((prev) => ({ ...prev, groupBy: updated }));
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {availableFields.map((f) => (
                      <MenuItem key={f} value={f}>
                        {f}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    const updated = reportConfig.groupBy.filter((_, i2) => i2 !== idx);
                    setReportConfig((prev) => ({ ...prev, groupBy: updated }));
                  }}
                >
                  Remove
                </Button>
              </Box>
            ))}
            <Button
              variant="outlined"
              sx={{ mt: 1 }}
              onClick={() =>
                setReportConfig((prev) => ({
                  ...prev,
                  groupBy: [...prev.groupBy, { field: '' }],
                }))
              }
            >
              + Add Group Field
            </Button>

            <Divider sx={{ my: 2 }} />

            {/* Aggregations */}
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Aggregations
            </Typography>
            {reportConfig.aggregations.map((agg, idx) => (
              <Box key={idx} sx={{ display: 'flex', gap: 1, mt: 1, alignItems: 'center' }}>
                <FormControl sx={{ width: 180 }}>
                  <InputLabel>Aggregation Field</InputLabel>
                  <Select
                    value={agg.field || ''}
                    label="Aggregation Field"
                    onChange={(e) => {
                      const updated = [...reportConfig.aggregations];
                      updated[idx].field = e.target.value;
                      setReportConfig((prev) => ({ ...prev, aggregations: updated }));
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {availableFields.map((f) => (
                      <MenuItem key={f} value={f}>
                        {f}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: 120 }}>
                  <InputLabel>Operation</InputLabel>
                  <Select
                    value={agg.operation || 'count'}
                    label="Operation"
                    onChange={(e) => {
                      const updated = [...reportConfig.aggregations];
                      updated[idx].operation = e.target.value;
                      setReportConfig((prev) => ({ ...prev, aggregations: updated }));
                    }}
                  >
                    <MenuItem value="count">Count</MenuItem>
                    <MenuItem value="sum">Sum</MenuItem>
                    <MenuItem value="average">Average</MenuItem>
                    <MenuItem value="min">Min</MenuItem>
                    <MenuItem value="max">Max</MenuItem>
                  </Select>
                </FormControl>

                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    const updated = reportConfig.aggregations.filter((_, i2) => i2 !== idx);
                    setReportConfig((prev) => ({ ...prev, aggregations: updated }));
                  }}
                >
                  Remove
                </Button>
              </Box>
            ))}
            <Button
              variant="outlined"
              sx={{ mt: 1 }}
              onClick={() =>
                setReportConfig((prev) => ({
                  ...prev,
                  aggregations: [...prev.aggregations, { field: '', operation: 'count' }],
                }))
              }
            >
              + Add Aggregation
            </Button>

            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Expand Arrays
            </Typography>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="expandArrays-label">Select Array Fields to Expand</InputLabel>
              <Select
                labelId="expandArrays-label"
                id="expandArrays-select"
                multiple
                value={reportConfig.expandArrays}
                onChange={(e) =>
                  setReportConfig((prev) => ({
                    ...prev,
                    expandArrays: e.target.value,
                  }))
                }
                input={<OutlinedInput label="Expand Arrays" />}
                renderValue={(selected) => selected.join(', ')}
              >
                {availableFields.map((f) => (
                  <MenuItem key={f} value={f}>
                    <Checkbox checked={reportConfig.expandArrays.includes(f)} />
                    <ListItemText primary={f} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        );

      case STEP.LAYOUT_PIVOT:
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              STEP 3: Layout & Pivot
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Adjust column layout (rename, hide, reorder) and do a front-end pivot if desired.
            </Typography>

            <Divider sx={{ my: 2 }} />

            {/* Layout editing */}
            <Typography variant="subtitle2" gutterBottom>
              Column Layout
            </Typography>
            {reportConfig.layout.columns.map((col, i) => (
              <Box key={col.field} sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                <TextField
                  label="Order"
                  type="number"
                  value={col.order}
                  onChange={(e) => {
                    const newCols = [...reportConfig.layout.columns];
                    newCols[i].order = parseInt(e.target.value, 10) || 0;
                    setReportConfig((prev) => ({
                      ...prev,
                      layout: { ...prev.layout, columns: newCols },
                    }));
                  }}
                  sx={{ width: 60 }}
                />
                <TextField label="Original Field" value={col.field} disabled sx={{ width: 150 }} />
                <TextField
                  label="Display Label"
                  value={col.label}
                  onChange={(e) => {
                    const newCols = [...reportConfig.layout.columns];
                    newCols[i].label = e.target.value;
                    setReportConfig((prev) => ({
                      ...prev,
                      layout: { ...prev.layout, columns: newCols },
                    }));
                  }}
                  sx={{ width: 160 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!col.hidden}
                      onChange={(e) => {
                        const newCols = [...reportConfig.layout.columns];
                        newCols[i].hidden = !e.target.checked;
                        setReportConfig((prev) => ({
                          ...prev,
                          layout: { ...prev.layout, columns: newCols },
                        }));
                      }}
                    />
                  }
                  label="Visible"
                />
              </Box>
            ))}

            <Divider sx={{ my: 2 }} />

            {/* Pivot section */}
            <Typography variant="subtitle2" gutterBottom>
              Front-End Pivot (React PivotTable)
            </Typography>
            <Button
              variant="contained"
              onClick={runPipeline}
              disabled={!selectedCollection || isLoading}
              sx={{ mb: 2 }}
            >
              Run/Refresh Data for Pivot
            </Button>
            {reportResults.length > 0 ? (
              <PivotTableUI
                data={reportResults}
                onChange={(s) =>
                  setReportConfig((prev) => ({
                    ...prev,
                    pivot: s,
                  }))
                }
                {...reportConfig.pivot}
              />
            ) : (
              <Typography variant="body2">
                No data to pivot. Click "Run/Refresh" above to get results.
              </Typography>
            )}
          </Box>
        );

      case STEP.FINAL_REVIEW:
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              STEP 4: Final Review
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Provide a name, see the pipeline flowchart, run final or save.
            </Typography>

            <TextField
              label="Report Name"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
              placeholder="e.g. My Client Aggregation"
            />

            <TextField
              label="Limit"
              type="number"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              value={reportConfig.limit}
              onChange={(e) => {
                const val = parseInt(e.target.value, 10) || 1;
                setReportConfig((prev) => ({ ...prev, limit: Math.min(val, 10000) }));
              }}
              helperText="Maximum 10,000"
            />

            <Divider sx={{ mb: 2 }} />

            {/* Visual Pipeline Summary (Flowchart) */}
            <Typography variant="subtitle2" gutterBottom>
              Visual Pipeline Summary
            </Typography>
            <Box
              sx={{
                backgroundColor: '#fafafa',
                p: 2,
                border: '1px solid #ccc',
                borderRadius: 1,
                mb: 2,
              }}
            >
              <Typography variant="body2">
                <strong>Collection:</strong> {selectedCollection?.label} →{' '}
                {reportConfig.relationships.length > 0 &&
                  ` [Join x${reportConfig.relationships.length}] → `}
                {reportConfig.filters.length > 0 && '[Filter] → '}
                {reportConfig.groupBy.length > 0 && '[GroupBy/Agg] → '}
                {reportConfig.expandArrays.length > 0 && '[Expand Arrays] → '}
                {`[Project Fields]`}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Fields: {reportConfig.fields.join(', ')}
              </Typography>
            </Box>

            <Button variant="outlined" onClick={handlePreviewPipeline} disabled={isLoading} sx={{ mb: 2 }}>
              Preview Pipeline (5 Rows)
            </Button>

            <Modal
              open={previewModal.open}
              onClose={() => setPreviewModal({ open: false, pipeline: [], sampleData: [] })}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '90%',
                  maxHeight: '80vh',
                  backgroundColor: 'white',
                  boxShadow: 24,
                  p: 4,
                  overflowY: 'auto',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Pipeline Preview
                </Typography>
                <ReactJson
                  src={previewModal.pipeline}
                  name={null}
                  collapsed={false}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                />
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle2" gutterBottom>
                  Sample Data (5 Rows)
                </Typography>
                {previewModal.sampleData.length ? (
                  <DataGrid
                    rows={previewModal.sampleData.map((row, idx) => ({
                      id: row._id || `sample-${idx}`,
                      ...row,
                    }))}
                    columns={Object.keys(previewModal.sampleData[0] || {}).map((key) => ({
                      field: key,
                      headerName: key,
                      flex: 1,
                      minWidth: 150,
                    }))}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    autoHeight
                  />
                ) : (
                  <Typography>No sample data returned.</Typography>
                )}
                <Box sx={{ mt: 2, textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    onClick={() => setPreviewModal({ open: false, pipeline: [], sampleData: [] })}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Box>
        );

      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Custom Report Builder (Enhanced)
      </Typography>
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
        {stepLabels.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep <= STEP.FINAL_REVIEW ? (
        <>
          {renderStepContent()}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              disabled={activeStep === STEP.SELECT_COLLECTION}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box>
              <Button variant="outlined" onClick={handleReset} sx={{ mr: 1 }}>
                Reset Wizard
              </Button>
              <Button variant="contained" onClick={handleNext}>
                {activeStep === STEP.FINAL_REVIEW ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <Typography sx={{ mt: 2 }}>
          All steps completed. Use the buttons below to run or save the report.
        </Typography>
      )}

      {activeStep === STEP.FINAL_REVIEW && (
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={runPipeline}
            sx={{ mr: 2 }}
            disabled={isLoading}
          >
            Run Report
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleSaveReport}
            sx={{ mr: 2 }}
            disabled={isLoading}
          >
            Save Report
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleExportCSV} sx={{ mr: 2 }}>
            Export CSV
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleExportExcel}>
            Export Excel
          </Button>
        </Box>
      )}

      {isLoading && (
        <Box sx={{ mt: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {reportResults.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Final Results ({reportResults.length} rows)
          </Typography>
          <Box sx={{ height: 600, width: '100%', overflow: 'auto' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 25, 50]}
              disableSelectionOnClick
              sx={{
                width: '100%',
                '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f5f5f5' },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  lineHeight: '1.5 !important',
                },
              }}
            />
          </Box>
        </Box>
      )}
      {reportResults.length === 0 && activeStep === STEP.FINAL_REVIEW && (
        <Typography sx={{ mt: 2 }}>No data yet. Click "Run Report" above to fetch data.</Typography>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}