/**********************************************
 * src/components/templateBuilder/TemplateBuilder.jsx
 **********************************************/

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  FormHelperText,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import TemplatePreview from './TemplatePreview';
import ErrorBoundary from '../ErrorBoundary';
import Swal from 'sweetalert2';
import customAlertStyles from '../../styles/CustomAlertStyles';
import { useAuth } from '../../context/AuthContext';

const TemplateBuilder = ({ fields, setFields }) => {
  const [fieldLabel, setFieldLabel] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [selectedField, setSelectedField] = useState(null);
  const [openCustomizeField, setOpenCustomizeField] = useState(false);
  const [errors, setErrors] = useState({});
  const [masterLists, setMasterLists] = useState([]);
  const [customTables, setCustomTables] = useState([]);
  const [openNewListDialog, setOpenNewListDialog] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [newListOptions, setNewListOptions] = useState([{ label: '', value: '' }]);
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentField, setCurrentField] = useState(null);
  const [charCount, setCharCount] = useState(0);

  const { permissions = [], loading: authLoading } = useAuth();

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  /**
   * Helper: Generate a unique localId if needed.
   */
  const generateLocalId = () => {
    return (
      typeof crypto !== 'undefined' && crypto.randomUUID
        ? crypto.randomUUID()
        : `temp-${Date.now()}-${Math.random().toString(36).substring(2, 7)}`
    );
  };

  const handleMenuClick = (event, field) => {
    setAnchorEl(event.currentTarget);
    setCurrentField(field);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentField(null);
  };

  const handleDuplicate = () => {
    if (!currentField) return;
    const duplicatedField = {
      ...currentField,
      // Clear out DB-related IDs and mark as new
      _id: undefined,
      isNew: true,
      localId: generateLocalId(),
    };
    setFields([...fields, duplicatedField]);
    handleMenuClose();
  };

  /**
   * handleRemoveOrArchive:
   * - If the field is new (not from DB), just remove it.
   * - Otherwise, mark it as Archived.
   */
  const handleRemoveOrArchive = () => {
    if (!currentField) return;
    if (currentField.isNew) {
      // Just remove from local fields
      setFields((prevFields) => prevFields.filter((f) => f.localId !== currentField.localId));
    } else {
      // Mark as archived so it doesn't appear
      setFields((prevFields) =>
        prevFields.map((f) =>
          f.localId === currentField.localId
            ? { ...f, state: 'Archived' }
            : f
        )
      );
    }
    handleMenuClose();
  };

  const handleReactivate = () => {
    if (!currentField) return;
    setFields((prevFields) =>
      prevFields.map((f) =>
        f.localId === currentField.localId
          ? { ...f, state: 'Active' }
          : f
      )
    );
    handleMenuClose();
  };

  // Fetch Master Lists
  const fetchMasterLists = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/masterlists`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMasterLists(response.data);
    } catch (error) {
      console.error('Error fetching master lists:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch master lists.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  // Fetch Custom Tables
  const fetchCustomTables = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/customtables`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomTables(response.data);
    } catch (error) {
      console.error('Error fetching custom tables:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch custom tables.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  useEffect(() => {
    if (!authLoading && hasViewPermission) {
      fetchMasterLists();
      fetchCustomTables();
    } else if (!authLoading && !hasViewPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [authLoading, hasViewPermission, fetchMasterLists, fetchCustomTables]);

  /**
   * Add a new field with a unique localId.
   */
  const addField = () => {
    if (!fieldLabel) {
      setErrors({ ...errors, fieldLabel: 'Field label is required' });
      return;
    }

    const newField = {
      label: fieldLabel.trim(),
      type: fieldType,
      isNew: true,
      state: 'Active',
      localId: generateLocalId(),
    };

    setFields([...fields, newField]);
    setFieldLabel('');
    setFieldType('');
    setErrors({});
    setCharCount(0);
  };

  const openFieldCustomization = (field) => {
    setSelectedField(field);
    setOpenCustomizeField(true);
  };

  /**
   * Whenever we update something on the selectedField, we store it in state.
   * If the field is 'select' or 'radio' referencing a master list,
   * or 'nested' referencing a custom table, ensure we store just the ID.
   */
  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    // If we’re changing the customTable field or masterList field,
    // store just the ID (not the entire object).
    setSelectedField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /**
   * Save the edited field’s custom properties.
   * Match by localId to avoid collisions.
   */
  const saveFieldCustomization = () => {
    setFields((prevFields) =>
      prevFields.map((f) =>
        f.localId === selectedField.localId ? selectedField : f
      )
    );
    setOpenCustomizeField(false);
    setSelectedField(null);
  };

  /**
   * Drag and drop logic — we store the localId in the dataTransfer.
   */
  const handleDragStart = (event, field) => {
    event.dataTransfer.setData('draggedFieldLocalId', field.localId);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const draggedFieldLocalId = event.dataTransfer.getData('draggedFieldLocalId');
    if (!draggedFieldLocalId) return;

    const dropTarget = event.target.closest('.field-item');
    if (!dropTarget) return;

    const dropIndex = Array.from(dropTarget.parentNode.children).indexOf(dropTarget);
    const draggedField = fields.find((f) => f.localId === draggedFieldLocalId);
    if (!draggedField) return;

    // Remove the dragged field from the list first
    const filteredFields = fields.filter((f) => f.localId !== draggedFieldLocalId);

    // Then insert it at the dropIndex
    const updatedFields = [
      ...filteredFields.slice(0, dropIndex),
      draggedField,
      ...filteredFields.slice(dropIndex),
    ];

    setFields(updatedFields);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Handlers for building a new Master List
  const handleNewListOptionChange = (index, key, value) => {
    const updatedOptions = [...newListOptions];
    updatedOptions[index][key] = value;
    setNewListOptions(updatedOptions);
  };

  const addNewListOption = () => {
    setNewListOptions([...newListOptions, { label: '', value: '' }]);
  };

  const removeNewListOption = (index) => {
    const updatedOptions = newListOptions.filter((_, idx) => idx !== index);
    setNewListOptions(updatedOptions);
  };

  const saveNewList = async () => {
    const options = newListOptions;
    const masterListData = { name: newListName, options };
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.post(`${API_BASE_URL}/api/masterlists`, masterListData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const newMasterList = response.data.masterList;
      setMasterLists([...masterLists, newMasterList]);
      setNewListName('');
      setNewListOptions([{ label: '', value: '' }]);
      setOpenNewListDialog(false);

      // If we opened this dialog from a selected field, automatically assign the newly created list
      if (selectedField) {
        setSelectedField((prev) => ({
          ...prev,
          masterList: newMasterList._id,
        }));
      }
    } catch (error) {
      console.error('Error adding master list:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to save new master list.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  // Filter the Master Lists on user search
  const filteredMasterLists = masterLists.filter((list) =>
    list.name.toLowerCase().includes(search.toLowerCase())
  );

  if (authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (!hasViewPermission) {
    return null; // Or a redirect, if you wish
  }

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Typography variant="h6">Add Elements</Typography>
      <Box display="flex" gap="16px">
        <FormControl fullWidth error={Boolean(errors.fieldLabel)}>
          <TextField
            label="Field Label"
            value={fieldLabel}
            onChange={(e) => {
              setFieldLabel(e.target.value);
              setCharCount(e.target.value.length);
            }}
            inputProps={{ maxLength: 250 }}
          />
          {errors.fieldLabel ? (
            <FormHelperText>{errors.fieldLabel}</FormHelperText>
          ) : (
            <FormHelperText>{`${charCount}/250 characters`}</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Field Type</InputLabel>
          <Select
            value={fieldType}
            onChange={(e) => setFieldType(e.target.value)}
            label="Field Type"
          >
            <MenuItem value="header">Header</MenuItem>
            <MenuItem value="bodyText">Body Text</MenuItem>
            <MenuItem value="checkbox">Checkbox</MenuItem>
            <MenuItem value="date">Date Picker</MenuItem>
            <MenuItem value="time">Time Picker</MenuItem>
            <MenuItem value="fileUpload">File Upload</MenuItem>
            <MenuItem value="textarea">Large Text</MenuItem>
            <MenuItem value="nested">Custom Table</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="slider">Slider</MenuItem>
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="select">Select</MenuItem>
            <MenuItem value="radio">Radio Button</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={addField}>
          Add Field
        </Button>
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Arrange Elements</Typography>
        <Paper
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          sx={{
            padding: '16px',
            minHeight: '200px',
            maxWidth: '100%',
            overflowX: 'auto',
          }}
        >
          {fields.map((field) => (
            <Box
              key={field.localId}
              className="field-item"
              draggable
              onDragStart={(event) => handleDragStart(event, field)}
              sx={{
                padding: '8px',
                marginBottom: '4px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                backgroundColor: field.state === 'Archived' ? '#d3d3d3' : 'transparent',
                maxWidth: '100%',
              }}
            >
              <Box>
                <DragIndicatorIcon />
              </Box>
              <Box
                onClick={() => openFieldCustomization(field)}
                sx={{
                  flexGrow: 1,
                  minWidth: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                }}
              >
                <Typography variant="body1">
                  {field.label.length > 100
                    ? `${field.label.substring(0, 100)}...`
                    : field.label}{' '}
                  ({field.type})
                </Typography>
              </Box>
              <IconButton onClick={(e) => handleMenuClick(e, field)}>
                <MoreVertIcon />
              </IconButton>
            </Box>
          ))}
        </Paper>
      </Box>

      <Typography variant="h6" mt={4}>
        Template Preview
      </Typography>
      <TemplatePreview fields={fields} masterLists={masterLists} customTables={customTables} />

      {/* Field Customization Dialog */}
      <Dialog open={openCustomizeField} onClose={() => setOpenCustomizeField(false)}>
        <DialogTitle>Customize Field</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            fullWidth
            label="Field Label"
            name="label"
            value={selectedField?.label || ''}
            onChange={handleFieldChange}
            inputProps={{ maxLength: 250 }}
          />
          {/* Additional settings for specific field types */}
          {selectedField?.type === 'checkbox' && (
            <TextField
              margin="normal"
              fullWidth
              label="Check List Option"
              name="checkListOption"
              value={selectedField?.checkListOption || ''}
              onChange={handleFieldChange}
            />
          )}
          {selectedField?.type === 'fileUpload' && (
            <TextField
              margin="normal"
              fullWidth
              label="File Types"
              name="fileTypes"
              value={selectedField?.fileTypes || ''}
              onChange={handleFieldChange}
            />
          )}
          {selectedField?.type === 'textarea' && (
            <TextField
              margin="normal"
              fullWidth
              label="Number of Lines"
              name="numLines"
              type="number"
              value={selectedField?.numLines || 4}
              onChange={handleFieldChange}
              inputProps={{ min: 4, max: 12 }}
            />
          )}

          {/* MasterList selection for select/radio */}
          {(selectedField?.type === 'select' || selectedField?.type === 'radio') && (
            <Box sx={{ marginTop: '16px' }}>
              <Button onClick={() => setOpenNewListDialog(true)}>Create New List</Button>
              <Typography variant="h6" sx={{ marginTop: '16px' }}>
                Import Existing List
              </Typography>
              <TextField
                fullWidth
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{ marginBottom: '16px', marginTop: '8px' }}
              />
              <FormControl fullWidth>
                <InputLabel>Select Master List</InputLabel>
                <Select
                  fullWidth
                  name="masterList"
                  // If 'masterList' is an object, use its _id; otherwise, use the string.
                  value={
                    typeof selectedField?.masterList === 'object'
                      ? selectedField?.masterList?._id || ''
                      : selectedField?.masterList || ''
                  }
                  onChange={handleFieldChange}
                  label="Select Master List"
                >
                  {filteredMasterLists.length > 0 ? (
                    filteredMasterLists.map((list) => (
                      <MenuItem key={list._id} value={list._id}>
                        {list.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled>
                      No lists available
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          )}

          {/* Custom Table selection for nested fields */}
          {selectedField?.type === 'nested' && (
            <Box sx={{ marginTop: '16px' }}>
              <InputLabel>Select Custom Table</InputLabel>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  name="customTable"
                  // If 'customTable' is an object, use its _id; otherwise, use the string.
                  value={
                    typeof selectedField?.customTable === 'object'
                      ? selectedField?.customTable?._id || ''
                      : selectedField?.customTable || ''
                  }
                  onChange={handleFieldChange}
                >
                  {customTables.map((table) => (
                    <MenuItem key={table._id} value={table._id}>
                      {table.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCustomizeField(false)}>Cancel</Button>
          <Button onClick={saveFieldCustomization}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* New List Dialog */}
      <Dialog open={openNewListDialog} onClose={() => setOpenNewListDialog(false)}>
        <DialogTitle>Create New List</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            fullWidth
            label="List Name"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
          />
          {newListOptions.map((option, index) => (
            <Box key={index} display="flex" gap="8px" alignItems="center">
              <TextField
                margin="normal"
                fullWidth
                label={`Option ${index + 1} Label`}
                value={option.label}
                onChange={(e) => handleNewListOptionChange(index, 'label', e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                label={`Option ${index + 1} Value`}
                value={option.value}
                onChange={(e) => handleNewListOptionChange(index, 'value', e.target.value)}
              />
              <IconButton onClick={() => removeNewListOption(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={addNewListOption}>Add Another Option</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewListDialog(false)}>Cancel</Button>
          <Button onClick={saveNewList}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const WrappedTemplateBuilder = (props) => (
  <ErrorBoundary>
    <TemplateBuilder {...props} />
  </ErrorBoundary>
);

export default WrappedTemplateBuilder;