/***********************************
 * src/components/templateBuilder/TemplatePreviewModal.jsx
 ***********************************/

import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  Slider,
  CircularProgress
} from '@mui/material';
import ErrorBoundary from '../ErrorBoundary'; // Adjust import path if needed
import { useAuth } from '../../context/AuthContext';
import Swal from 'sweetalert2';
import customAlertStyles from '../../styles/CustomAlertStyles';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const TemplatePreviewModal = ({ fields }) => {
  const { permissions = [], loading: authLoading } = useAuth();

  // -- Permission checks
  const hasViewPermission = permissions.includes('view_template-management');

  // -- Local State for fetched entities
  const [masterLists, setMasterLists] = useState([]);
  const [customTables, setCustomTables] = useState([]);
  const [loadingEntities, setLoadingEntities] = useState(true);

  /**
   * Fetch Master Lists
   */
  const fetchMasterLists = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/masterlists`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMasterLists(response.data);
    } catch (error) {
      console.error('Error fetching master lists:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch master lists.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, []);

  /**
   * Fetch Custom Tables
   */
  const fetchCustomTables = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/customtables`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCustomTables(response.data);
    } catch (error) {
      console.error('Error fetching custom tables:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch custom tables.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, []);

  /**
   * On mount, fetch both Master Lists and Custom Tables
   */
  useEffect(() => {
    const loadData = async () => {
      setLoadingEntities(true);
      await fetchMasterLists();
      await fetchCustomTables();
      setLoadingEntities(false);
    };
    loadData();
  }, [fetchMasterLists, fetchCustomTables]);

  if (authLoading) {
    return null; // Or a spinner if you prefer
  }

  if (!hasViewPermission) {
    Swal.fire({
      title: 'Access Denied',
      text: 'You do not have permission to view this content.',
      icon: 'error',
      ...customAlertStyles.sweetAlert,
    });
    return null;
  }

  /**
   * If still loading Master Lists / Custom Tables, show a spinner
   */
  if (loadingEntities) {
    return (
      <Box textAlign="center" sx={{ marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  /**
   * Helper: Renders one single field. This allows you to reuse code for nested fields.
   */
  const renderField = (field, keyIndex) => {
    // Safely handle if field.masterList is an object or a string
    const masterListId =
      typeof field.masterList === 'object'
        ? field.masterList?._id
        : field.masterList;

    const masterListData = masterLists.find((list) => list._id === masterListId);

    // If this field has customTable, do the same logic
    const customTableId =
      typeof field.customTable === 'object'
        ? field.customTable?._id
        : field.customTable;
    const customTableData = customTables.find((table) => table._id === customTableId);

    return (
      <Box key={keyIndex} sx={{ marginBottom: 2 }}>
        {/* Field Label */}
        {field.label && (
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            {field.label}
          </Typography>
        )}

        {/* For text */}
        {field.type === 'text' && (
          <TextField
            variant="outlined"
            fullWidth
            disabled
            placeholder="Text Input"
            value={field.value || ''}
          />
        )}

        {/* Text area */}
        {field.type === 'textarea' && (
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            disabled
            placeholder="Text Area"
            value={field.value || ''}
          />
        )}

        {/* Select / Dropdown */}
        {field.type === 'select' && (
          <FormControl fullWidth variant="outlined" disabled>
            <InputLabel>{field.label}</InputLabel>
            <Select label={field.label} value={field.value || ''}>
              {masterListData?.options?.map((option, idx) => (
                <MenuItem key={idx} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Radio */}
        {field.type === 'radio' && (
          <FormControl component="fieldset" disabled sx={{ width: '100%' }}>
            <FormLabel component="legend" sx={{ fontSize: 14, mb: 1 }}>
              {field.label}
            </FormLabel>
            <RadioGroup value={field.value || ''}>
              {masterListData?.options?.map((option, idx) => (
                <FormControlLabel
                  key={idx}
                  value={option.value}
                  control={<Radio disabled />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}

        {/* Checkbox */}
        {field.type === 'checkbox' && (
          <FormControlLabel
            control={<Checkbox disabled checked={!!field.value} />}
            label="Checkbox"
          />
        )}

        {/* Date */}
        {field.type === 'date' && (
          <TextField
            variant="outlined"
            type="date"
            fullWidth
            disabled
            value={field.value || ''}
          />
        )}

        {/* Number */}
        {field.type === 'number' && (
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            disabled
            placeholder="Number Input"
            value={field.value || ''}
          />
        )}

        {/* Switch */}
        {field.type === 'switch' && (
          <FormControlLabel
            control={<Switch disabled checked={!!field.value} />}
            label="Toggle Switch"
          />
        )}

        {/* Time Picker */}
        {field.type === 'time' && (
          <TextField
            variant="outlined"
            type="time"
            fullWidth
            disabled
            placeholder="Time Picker"
            value={field.value || ''}
          />
        )}

        {/* Slider */}
        {field.type === 'slider' && (
          <Slider
            disabled
            value={typeof field.value === 'number' ? field.value : 0}
            min={field.min || 0}
            max={field.max || 100}
            step={field.step || 1}
            valueLabelDisplay="auto"
          />
        )}

        {/* Custom Table (Nested) */}
        {field.type === 'nested' && (
          <Box sx={{ mt: 2, p: 2, border: '1px dashed #aaa', borderRadius: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              {field.label || 'Custom Table'}
            </Typography>

            {/* We assume the custom table object has "fields" inside it, similarly to your other code */}
            {customTableData?.fields?.map((nestedField, nestedIndex) => {
              // We'll do a minor hack: replicate this "renderField" logic on each nested field
              // or just display them as static placeholders.
              // For a real approach, you might keep "nestedField.value" in your data object if needed.
              return (
                <Box key={nestedIndex} sx={{ ml: 2, mb: 2 }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {nestedField.label}
                  </Typography>
                  {/* Example: show the type of nested field, or replicate logic */}
                  {nestedField.type === 'text' && (
                    <TextField
                      variant="outlined"
                      fullWidth
                      disabled
                      placeholder="Nested Text"
                      value={''}
                    />
                  )}
                  {nestedField.type === 'date' && (
                    <TextField
                      variant="outlined"
                      type="date"
                      fullWidth
                      disabled
                      value={''}
                    />
                  )}
                  {/* etc... replicate more nested field logic as needed */}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      {fields.map((field, index) => renderField(field, index))}
    </Box>
  );
};

// Wrap the component with ErrorBoundary
const WrappedTemplatePreviewModal = (props) => (
  <ErrorBoundary>
    <TemplatePreviewModal {...props} />
  </ErrorBoundary>
);

export default WrappedTemplatePreviewModal;