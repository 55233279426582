import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
  IconButton,
  Collapse,
  Button,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getUserToken } from '../utils/api';
import { styles } from '../styles/styles';
import customAlertStyles from '../styles/CustomAlertStyles';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SaveIcon from '@mui/icons-material/Save';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import AvatarEditor from 'react-avatar-editor'; // Importing react-avatar-editor

// --- Added phone number formatter function ---
function formatPhoneNumber(value) {
  const cleaned = (value || '').replace(/[^\d]/g, '');
  const length = cleaned.length;

  if (length < 4) {
    return cleaned;
  } else if (length < 7) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  } else {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
  }
}

// --- NEW UTILITY FUNCTION: Extracts the blob name from a full Azure URL ---
function extractBlobName(fileUrl) {
  // Remove any query params like '?sv=...'
  const urlWithoutQuery = fileUrl.split('?')[0];
  // Then get the filename portion from the path
  return urlWithoutQuery.split('/').pop();
}

const ProfileScreen = () => {
  const navigate = useNavigate();
  const { permissions = [], loading: authLoading } = useAuth();

  const [user, setUser] = useState({
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    jobTitle: '',
    employmentType: '',
    role: { name: '' },
    status: 'active',
    profilePicture: '',
    emergencyContacts: [
      { firstName: '', lastName: '', relationship: '', phoneNumber: '' },
    ],
    registration: 'Preregistered',
  });

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [scale, setScale] = useState(1); // Scale for image resizing

  const editorRef = useRef(null); // Ref for AvatarEditor

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  const hasViewProfilePermission = permissions.includes('view_profile');
  const hasEditProfilePermission = permissions.includes('edit_profile');

  const fetchUserData = useCallback(async () => {
    if (!hasViewProfilePermission) {
      // If we don't have permission, we'll show a message later in the content area
      setLoading(false);
      return;
    }

    try {
      const token = getUserToken();
      const response = await axios.get(`${API_BASE_URL}/api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        setUser(response.data);
      } else {
        console.error('Failed to fetch user data:', response.status);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch user data. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoading(false);
    }
  }, [hasViewProfilePermission, API_BASE_URL]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // --- Apply phone number formatting only for phoneNumber field ---
    if (name === 'phoneNumber') {
      setUser((prevState) => ({ ...prevState, [name]: formatPhoneNumber(value) }));
    } else {
      setUser((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSaveProfile = async () => {
    if (!hasEditProfilePermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this profile.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    try {
      const token = getUserToken();
      const { _id, ...userData } = user;

      const response = await axios.put(`${API_BASE_URL}/api/users/${_id}`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data) {
        setUser(response.data);
        setEditMode(false);
        Swal.fire({
          title: 'Success',
          text: 'Profile updated successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Failed to update user data.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Error updating user data:', error);
      Swal.fire({
        title: 'Error',
        text: 'An unknown error occurred. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // Utility function to convert dataURL to Blob
  const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      return null;
    }
    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  // New function to handle profile picture upload with deletion of existing picture
  const handleUploadProfilePicture = async () => {
    if (!profileImage) {
      Swal.fire({
        title: 'No file selected',
        text: 'Please choose a file before saving.',
        icon: 'warning',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    try {
      const token = getUserToken();

      // 1. If there is an existing profile picture, delete it from Azure Blob Storage
      if (user.profilePicture) {
        // Extract the blob name from the URL
        const blobName = extractBlobName(user.profilePicture);
        console.log(`Deleting existing blob: ${blobName}`); // Debugging log
        await axios.delete(`${API_BASE_URL}/api/files/delete`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: { blobName }, // Must be in 'data' for DELETE requests
        });
      }

      // 2. Upload the new image to Azure Blob Storage
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      const blob = dataURLtoBlob(canvas);
      const formData = new FormData();
      // Use the original file name but you can rename or sanitize as well
      formData.append('file', blob, profileImage.name);

      const uploadResponse = await axios.post(
        `${API_BASE_URL}/api/files/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (uploadResponse.data && uploadResponse.data.fileUrl) {
        const uploadedUrl = uploadResponse.data.fileUrl;

        // 3. Update the user's profilePicture URL in the database
        const { _id, ...updatedUserData } = user;
        updatedUserData.profilePicture = uploadedUrl;

        const updateResponse = await axios.put(
          `${API_BASE_URL}/api/users/${_id}`,
          updatedUserData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (updateResponse.data) {
          setUser(updateResponse.data);
          setProfileImage(null);
          setPreviewImage(null);

          Swal.fire({
            title: 'Success',
            text: 'Profile picture updated successfully.',
            icon: 'success',
            ...customAlertStyles.sweetAlert,
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Failed to update profile picture.',
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
        }
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Failed to upload profile picture.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to upload profile picture. Please try again.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // New function to handle removing profile picture
  const handleRemoveProfilePicture = async () => {
    if (!user.profilePicture) {
      Swal.fire({
        title: 'No picture to remove',
        text: 'There is no profile picture to remove.',
        icon: 'info',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    try {
      const token = getUserToken();

      // Extract the blob name from the URL using the new utility
      const blobName = extractBlobName(user.profilePicture);
      console.log(`Removing blob: ${blobName}`); // Debugging log

      // 1. Delete the blob from Azure Blob Storage
      await axios.delete(`${API_BASE_URL}/api/files/delete`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: { blobName },
      });

      // 2. Remove the profilePicture URL from the user's data
      const { _id, ...updatedUserData } = user;
      updatedUserData.profilePicture = '';

      const updateResponse = await axios.put(
        `${API_BASE_URL}/api/users/${_id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (updateResponse.data) {
        setUser(updateResponse.data);
        setPreviewImage(null);

        Swal.fire({
          title: 'Success',
          text: 'Profile picture removed successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Failed to remove profile picture.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Error removing profile picture:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to remove profile picture. Please try again.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleScaleChange = (event, newValue) => {
    setScale(newValue);
  };

  return (
    <ErrorBoundary>
      <>
        <Navbar />
        <Box sx={{ display: 'flex' }}>
          <Sidebar />
          <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
            {/* Handle loading and permissions within the content area */}
            {authLoading || loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50vh',
                }}
              >
                <CircularProgress />
              </Box>
            ) : !hasViewProfilePermission ? (
              <Box sx={{ padding: '24px', textAlign: 'center' }}>
                <Typography variant="h6" color="error">
                  You do not have permission to view this page.
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    marginBottom: 2,
                    border: '1px solid #549F93',
                    padding: '8px',
                    borderRadius: '4px',
                    background: 'linear-gradient(to right, #258EA6, #549F93)',
                    position: 'relative',
                  }}
                >
                  <Avatar
                    alt={`${user.firstName} ${user.lastName}`}
                    src={previewImage || user.profilePicture || undefined}
                    sx={{
                      width: 160,
                      height: 160,
                      position: 'absolute',
                      top: '50%',
                      right: '16px',
                      transform: 'translateY(-50%)',
                      border: '4px solid #FFFFFF',
                      boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                      fontSize: '36px',
                      backgroundColor:
                        previewImage || user.profilePicture
                          ? 'transparent'
                          : '#BDBDBD',
                      color: '#FFFFFF',
                    }}
                  >
                    {!user.profilePicture && !previewImage && `${user.firstName[0]}${user.lastName[0]}`}
                  </Avatar>
                  <Box sx={{ marginRight: '180px' }}>
                    <Typography variant="h6" sx={{ color: '#FFFFFF' }}>
                      {user.firstName} {user.lastName}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: '#EAEAEA' }}>
                      Phone: {user.phoneNumber || 'N/A'}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: '#EAEAEA' }}>
                      Address: {user.address || 'N/A'}
                    </Typography>
                  </Box>

                  {expanded && hasEditProfilePermission && (
                    <>
                      <IconButton
                        onClick={() => setEditMode(true)}
                        sx={{ color: '#FFFFFF' }}
                        aria-label="Edit Profile"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={editMode ? handleSaveProfile : null}
                        sx={{ color: '#FFFFFF' }}
                        disabled={!editMode || saving}
                        aria-label="Save Profile"
                      >
                        {saving ? (
                          <CircularProgress size={24} />
                        ) : (
                          <SaveIcon />
                        )}
                      </IconButton>
                    </>
                  )}

                  <IconButton
                    onClick={() => setExpanded(!expanded)}
                    sx={{ color: '#FFFFFF' }}
                    aria-label="Toggle Collapse"
                  >
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Box>
                <Collapse in={expanded}>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Profile Tabs"
                  >
                    <Tab label="Personal Information" />
                    <Tab label="Job Information" />
                    <Tab label="Emergency Contacts" />
                    <Tab label="Profile Picture" />
                  </Tabs>
                  <Box sx={{ marginTop: 2 }}>
                    {activeTab === 0 && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="First Name"
                            name="firstName"
                            value={user.firstName || ''}
                            onChange={handleInputChange}
                            fullWidth
                            sx={styles.input}
                            aria-label="First Name"
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Last Name"
                            name="lastName"
                            value={user.lastName || ''}
                            onChange={handleInputChange}
                            fullWidth
                            sx={styles.input}
                            aria-label="Last Name"
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Email"
                            name="email"
                            value={user.email || ''}
                            onChange={handleInputChange}
                            fullWidth
                            sx={styles.input}
                            aria-label="Email"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            value={user.phoneNumber || ''}
                            onChange={handleInputChange}
                            fullWidth
                            sx={styles.input}
                            aria-label="Phone Number"
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Address"
                            name="address"
                            value={user.address || ''}
                            onChange={handleInputChange}
                            fullWidth
                            sx={styles.input}
                            aria-label="Address"
                            disabled={!editMode}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {activeTab === 1 && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Job Title"
                            name="jobTitle"
                            value={user.jobTitle || ''}
                            onChange={handleInputChange}
                            fullWidth
                            sx={styles.input}
                            aria-label="Job Title"
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Employment Type"
                            name="employmentType"
                            value={user.employmentType || ''}
                            onChange={handleInputChange}
                            fullWidth
                            sx={styles.input}
                            aria-label="Employment Type"
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Role"
                            name="role"
                            value={user.role ? user.role.name : 'Role not assigned'}
                            fullWidth
                            sx={styles.input}
                            aria-label="Role"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth sx={styles.input}>
                            <InputLabel>Status</InputLabel>
                            <Select
                              name="status"
                              value={user.status || ''}
                              onChange={handleInputChange}
                              aria-label="Status"
                              disabled={!editMode}
                            >
                              <MenuItem value="active">Active</MenuItem>
                              <MenuItem value="inactive">Inactive</MenuItem>
                              <MenuItem value="away">Away</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth sx={styles.input}>
                            <InputLabel>Registration Status</InputLabel>
                            <Select
                              name="registration"
                              value={user.registration || ''}
                              onChange={handleInputChange}
                              aria-label="Registration Status"
                              disabled={!editMode}
                            >
                              <MenuItem value="Preregistered">Preregistered</MenuItem>
                              <MenuItem value="Registered">Registered</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}

                    {activeTab === 2 && (
                      <Box>
                        <Typography
                          component="h3"
                          variant="h6"
                          sx={styles.subHeader}
                          aria-label="Emergency Contacts Header"
                        >
                          Emergency Contacts
                        </Typography>
                        {user.emergencyContacts.map((contact, index) => (
                          <React.Fragment key={index}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  label="Contact First Name"
                                  name="firstName"
                                  value={contact.firstName}
                                  onChange={(e) => {
                                    const newContacts = [...user.emergencyContacts];
                                    newContacts[index].firstName = e.target.value;
                                    setUser({
                                      ...user,
                                      emergencyContacts: newContacts,
                                    });
                                  }}
                                  fullWidth
                                  sx={styles.input}
                                  aria-label="Contact First Name"
                                  disabled={!editMode}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  label="Contact Last Name"
                                  name="lastName"
                                  value={contact.lastName}
                                  onChange={(e) => {
                                    const newContacts = [...user.emergencyContacts];
                                    newContacts[index].lastName = e.target.value;
                                    setUser({
                                      ...user,
                                      emergencyContacts: newContacts,
                                    });
                                  }}
                                  fullWidth
                                  sx={styles.input}
                                  aria-label="Contact Last Name"
                                  disabled={!editMode}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  label="Relationship"
                                  name="relationship"
                                  value={contact.relationship}
                                  onChange={(e) => {
                                    const newContacts = [...user.emergencyContacts];
                                    newContacts[index].relationship = e.target.value;
                                    setUser({
                                      ...user,
                                      emergencyContacts: newContacts,
                                    });
                                  }}
                                  fullWidth
                                  sx={styles.input}
                                  aria-label="Relationship"
                                  disabled={!editMode}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  label="Contact Phone Number"
                                  name="phoneNumber"
                                  value={contact.phoneNumber}
                                  onChange={(e) => {
                                    const newContacts = [...user.emergencyContacts];
                                    // --- Apply phone number formatting ---
                                    newContacts[index].phoneNumber = formatPhoneNumber(
                                      e.target.value
                                    );
                                    setUser({
                                      ...user,
                                      emergencyContacts: newContacts,
                                    });
                                  }}
                                  fullWidth
                                  sx={styles.input}
                                  aria-label="Contact Phone Number"
                                  disabled={!editMode}
                                />
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Box>
                    )}

                    {activeTab === 3 && (
                      <Box>
                        <Typography
                          component="h3"
                          variant="h6"
                          sx={styles.subHeader}
                          aria-label="Profile Picture Header"
                        >
                          Profile Picture
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 2,
                          }}
                        >
                          {/* Display Avatar Editor when a new image is selected */}
                          {profileImage && (
                            <AvatarEditor
                              ref={editorRef}
                              image={previewImage}
                              width={250}
                              height={250}
                              border={50}
                              borderRadius={125}
                              color={[255, 255, 255, 0.6]} // RGBA
                              scale={scale}
                              rotate={0}
                            />
                          )}
                          {/* Display current or preview avatar when no editing is needed */}
                          {!profileImage && (
                            <Avatar
                              alt={`${user.firstName} ${user.lastName}`}
                              src={previewImage || user.profilePicture || '/default-avatar.png'}
                              sx={{ width: 140, height: 140, marginBottom: 2 }}
                            />
                          )}
                          {editMode && (
                            <>
                              <Button
                                variant="contained"
                                component="label"
                                startIcon={<PhotoCameraIcon />}
                                sx={{ marginBottom: 2 }}
                                aria-label="Upload New Picture"
                              >
                                {profileImage ? 'Change Picture' : 'Upload New Picture'}
                                <input
                                  type="file"
                                  hidden
                                  accept="image/*"
                                  onChange={handleProfileImageChange}
                                />
                              </Button>
                              {profileImage && (
                                <>
                                  <Box sx={{ width: 200, marginBottom: 2 }}>
                                    <Typography variant="body2" gutterBottom>
                                      Zoom
                                    </Typography>
                                    <Slider
                                      value={scale}
                                      min={1}
                                      max={3}
                                      step={0.1}
                                      onChange={handleScaleChange}
                                      aria-labelledby="Zoom Slider"
                                    />
                                  </Box>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleUploadProfilePicture}
                                    sx={{ marginBottom: 2 }}
                                    disabled={saving}
                                    aria-label="Save Picture"
                                  >
                                    {saving ? (
                                      <CircularProgress size={24} color="inherit" />
                                    ) : (
                                      'Save Picture'
                                    )}
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                          {/* Remove button accessible if the user has a picture */}
                          {user.profilePicture && (
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={handleRemoveProfilePicture}
                              startIcon={<DeleteIcon />}
                              disabled={saving}
                              aria-label="Remove Current Picture"
                            >
                              Remove Current Picture
                            </Button>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Collapse>
              </>
            )}
          </Box>
        </Box>
      </>
    </ErrorBoundary>
  );
};

const WrappedProfileScreen = () => (
  <ErrorBoundary>
    <ProfileScreen />
  </ErrorBoundary>
);

export default WrappedProfileScreen;