/***********************************
 * src/screens/CarePlanTemplateBuilder.jsx
 ***********************************/

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Snackbar,
  Button,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import TemplateBuilder from '../components/templateBuilder/TemplateBuilder';
import CreateScreen from './CreateScreen';
import DefineScreen from './DefineScreen';
import AssignScreen from './AssignScreen';
import WorkflowScreen from './WorkflowScreen';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';
import axios from 'axios';
import FinalReviewModal from '../components/templateBuilder/FinalReviewModal';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';

const steps = ['Create', 'Design', 'Define', 'Assign', 'Workflow'];

const CarePlanTemplateBuilder = () => {
  const [fields, setFields] = useState([]);
  const [templateDetails, setTemplateDetails] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState(null);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);

  const navigate = useNavigate();
  const { permissions = [], loading: authLoading } = useAuth();

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');
  const hasAddPermission = permissions.includes('add_template-management');

  /**
   * Helper function to ensure each field has a `localId`.
   * If the field has `_id` from the DB, use that as localId.
   * Otherwise, generate a new localId for new fields.
   */
  const ensureLocalIds = (incomingFields) => {
    return incomingFields.map((field) => {
      // If field already has localId, keep it
      if (field.localId) return field;

      // If field came from DB and has an _id, use that
      if (field._id) {
        return { ...field, localId: field._id };
      }

      // Otherwise, create a brand new localId
      return {
        ...field,
        localId:
          typeof crypto !== 'undefined' && crypto.randomUUID
            ? crypto.randomUUID()
            : `temp-${Date.now()}-${Math.random().toString(36).substring(2, 7)}`,
      };
    });
  };

  // Load draft from localStorage on mount
  useEffect(() => {
    if (!authLoading && !hasViewPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      navigate('/home');
    } else if (!authLoading) {
      const savedDraft = JSON.parse(localStorage.getItem('carePlanDraft'));
      if (savedDraft) {
        // Ensure localIds exist
        const sanitizedFields = ensureLocalIds(savedDraft.fields || []);
        setTemplateDetails(savedDraft || {});
        setFields(sanitizedFields);
        setActiveStep(savedDraft.activeStep || 0);

        // If no actual DB _id, assign a temporary ID
        if (!savedDraft._id) {
          setTemplateDetails((prev) => ({
            ...prev,
            _id: `temp-${Date.now()}`,
          }));
        }
      } else {
        // If no draft, set a new temporary ID
        setTemplateDetails((prev) => ({
          ...prev,
          _id: `temp-${Date.now()}`,
        }));
      }
    }
  }, [authLoading, hasViewPermission, navigate]);

  // Auto-save the draft at regular intervals
  const saveDraft = useCallback(
    (step) => {
      const draft = {
        ...templateDetails,
        fields,
        activeStep: step,
      };
      localStorage.setItem('carePlanDraft', JSON.stringify(draft));
      setLastSaved(new Date().toLocaleTimeString());
      setSnackbarOpen(true);
    },
    [templateDetails, fields]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      saveDraft(activeStep);
    }, 30000); // Auto-save every 30 seconds

    return () => clearInterval(interval);
  }, [activeStep, saveDraft]);

  // Validate the current step before proceeding
  const validateCurrentStep = () => {
    switch (activeStep) {
      case 0:
        if (!templateDetails.description) {
          Swal.fire({
            icon: 'error',
            title: 'Missing Description',
            text: 'Description is required.',
            ...customAlertStyles.sweetAlert,
          });
          return false;
        }
        break;
      case 3:
        if (
          !templateDetails.assignedClients?.length &&
          !templateDetails.assignedUsers?.length &&
          !templateDetails.assignedCareEntities?.length
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Missing Assignments',
            text: 'You must assign at least one user, client, or care entity.',
            ...customAlertStyles.sweetAlert,
          });
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  };

  // Handle step change
  const handleStepChange = (step) => {
    saveDraft(step);
    setActiveStep(step);
  };

  // Sanitize fields before saving (e.g., removing 'options' key if it exists, or ensuring 'masterList' is set)
  const sanitizeFields = (incomingFields) => {
    return incomingFields.map((field) => {
      const { options, localId, ...rest } = field;

      // For 'select' and 'radio' fields, ensure 'masterList' is set
      if (['select', 'radio'].includes(field.type)) {
        rest.masterList = field.masterList || null;
      }

      // Remove local-only props
      if (rest.isNew) delete rest.isNew;

      // Remove 'options' if any
      if (rest.options) {
        delete rest.options;
      }

      // localId is for front-end reference only.
      // Typically, remove or rename if your server doesn't accept it:
      // delete rest.localId; // Uncomment if needed

      return rest;
    });
  };

  // Save Template
  const handleSaveTemplate = async (isActive = false) => {
    if (!hasAddPermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to save templates.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setIsSaving(true);
    const token = localStorage.getItem('userToken');
    const userId = localStorage.getItem('userId') || '000000000000000000000000';

    const sanitizedFields = sanitizeFields(fields);

    const templateData = {
      ...templateDetails,
      templateID: templateDetails.templateID || `template-${Date.now()}`,
      fields: sanitizedFields,
      isActive,
      isDraft: !isActive,
      version: isActive
        ? templateDetails.version
          ? Number(templateDetails.version) + 1
          : 1
        : templateDetails.version || 1,
      createdBy: userId,
      updatedBy: userId,
      roles: templateDetails.permissionsForRoles,
      assignedProcessLabels: templateDetails.assignedProcessLabels || [],
    };

    // Remove temporary _id if it is a local "temp" ID
    if (templateData._id && templateData._id.toString().startsWith('temp-')) {
      delete templateData._id;
    }

    try {
      const url = isActive
        ? `${API_BASE_URL}/api/careplantemplates/finish`
        : `${API_BASE_URL}/api/careplantemplates/save`;
      const response = await axios.post(url, templateData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // Update local state with new DB _id
      setTemplateDetails((prev) => ({
        ...prev,
        _id: response.data.carePlanTemplate._id,
      }));

      localStorage.removeItem('carePlanDraft');
      navigate('/template-management');
    } catch (error) {
      setIsSaving(false);
      console.error('Error saving template:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error Saving Template',
        text: `Error: ${error.response?.data.error || error.message}`,
        ...customAlertStyles.sweetAlert,
      });
    }
    setIsSaving(false);
  };

  const handleSaveAndClose = async () => {
    await handleSaveTemplate(false);
  };

  const handleFinish = async () => {
    // Show the final review modal
    setReviewModalOpen(true);
  };

  const handleConfirmFinish = async () => {
    setReviewModalOpen(false);
    setIsSaving(true);
    await handleSaveTemplate(true);
    Swal.fire({
      icon: 'success',
      title: 'Template Finished',
      text: 'The care plan template has been successfully finished!',
      ...customAlertStyles.sweetAlert,
    }).then(() => {
      navigate('/template-management');
    });
  };

  const handleExit = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Exit CarePlan Template Builder',
      text: 'Are you sure you want to exit? Unsaved changes will be lost.',
      showCancelButton: true,
      confirmButtonText: 'Exit without saving',
      cancelButtonText: 'Cancel',
      ...customAlertStyles.sweetAlert,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('carePlanDraft');
        navigate('/template-management');
      }
    });
  };

  const handleNext = () => {
    if (!validateCurrentStep()) return;

    handleStepChange(activeStep + 1);
  };

  const handleBack = () => {
    handleStepChange(activeStep - 1);
  };

  useEffect(() => {
    if (activeStep === 4) {
      // Additional logic if needed when reaching Step 4
    }
  }, [activeStep, templateDetails]);

  if (authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <NavigationBar />
      <Box sx={{ display: 'flex' }} aria-label="CarePlan Template Builder Screen">
        <Sidebar />
        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '24px',
            }}
          >
            <Stepper activeStep={activeStep} sx={{ flex: 1 }} aria-label="Template Building Steps">
              {steps.map((label, index) => (
                <Step key={label} completed={activeStep > index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {activeStep > 0 && (
                <Button
                  variant="contained"
                  onClick={handleBack}
                  sx={{ marginRight: '16px', marginTop: '-10px' }}
                  aria-label="Go to previous step"
                >
                  Back
                </Button>
              )}
              {activeStep < steps.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={isSaving}
                  sx={{ marginRight: '16px', marginTop: '-10px' }}
                  aria-label="Go to next step"
                >
                  {isSaving ? <CircularProgress size={20} /> : 'Next'}
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFinish}
                  sx={{ marginRight: '16px', marginTop: '-10px' }}
                  aria-label="Finish template creation"
                >
                  {isSaving ? <CircularProgress size={20} /> : 'Finish'}
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSaveAndClose}
                sx={{ marginRight: '16px', marginTop: '-10px' }}
                aria-label="Save and close template builder"
                disabled={!hasAddPermission}
              >
                Save and Close
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleExit}
                sx={{ marginTop: '-10px' }}
                aria-label="Exit template builder"
              >
                Exit
              </Button>
            </Box>
          </Box>
          {activeStep === 0 && (
            <CreateScreen
              templateDetails={templateDetails}
              setTemplateDetails={setTemplateDetails}
              handleNext={handleNext}
              aria-label="Create Template Screen"
            />
          )}
          {activeStep === 1 && (
            <TemplateBuilder
              fields={fields}
              setFields={setFields}
              aria-label="Template Builder Screen"
            />
          )}
          {activeStep === 2 && (
            <DefineScreen
              templateDetails={templateDetails}
              setTemplateDetails={setTemplateDetails}
              handleNext={() => handleStepChange(3)}
              aria-label="Define Template Screen"
            />
          )}
          {activeStep === 3 && (
            <AssignScreen
              templateDetails={templateDetails}
              setTemplateDetails={setTemplateDetails}
              aria-label="Assign Template Screen"
            />
          )}
          {activeStep === 4 && (
            <WorkflowScreen
              templateDetails={templateDetails}
              setTemplateDetails={setTemplateDetails}
              handleNext={handleFinish}
              aria-label="Workflow Template Screen"
            />
          )}
        </Box>
      </Box>
      {lastSaved && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={`Last saved at ${lastSaved}`}
        />
      )}
      {/* Final Review Modal */}
      <FinalReviewModal
        open={reviewModalOpen}
        onClose={() => setReviewModalOpen(false)}
        onFinish={handleConfirmFinish}
        templateDetails={templateDetails}
        fields={fields}
      />
    </>
  );
};

const WrappedCarePlanTemplateBuilder = () => (
  <ErrorBoundary>
    <CarePlanTemplateBuilder />
  </ErrorBoundary>
);

export default WrappedCarePlanTemplateBuilder;