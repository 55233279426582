// src/screens/UserProfileScreen.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
  IconButton,
  Collapse,
  Button,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slider
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Auth / Context
import { getUserToken } from '../utils/api';
import { useAuth } from '../context/AuthContext';

// Components
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ErrorBoundary from '../components/ErrorBoundary';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SaveIcon from '@mui/icons-material/Save';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// SweetAlert2 + custom styles
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import customAlertStyles from '../styles/CustomAlertStyles';

// Avatar Editor for cropping
import AvatarEditor from 'react-avatar-editor';

// --- Phone number formatter ---
function formatPhoneNumber(value) {
  const cleaned = (value || '').replace(/[^\d]/g, '');
  const length = cleaned.length;

  if (length < 4) {
    return cleaned;
  } else if (length < 7) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  } else {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
  }
}

// --- Extract the blob name from a full Azure blob URL ---
function extractBlobName(fileUrl) {
  if (!fileUrl) return '';
  const urlWithoutQuery = fileUrl.split('?')[0];
  return urlWithoutQuery.split('/').pop();
}

// --- Convert dataURL to Blob for uploading ---
function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',');
  const mimeMatch = arr[0].match(/:(.*?);/);
  if (!mimeMatch) return null;
  const mime = mimeMatch[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const MySwal = withReactContent(Swal);

const UserProfileScreen = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { permissions = [], loading: authLoading } = useAuth();

  // User state
  const [user, setUser] = useState({
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    profilePicture: '',
    jobTitle: '',
    employmentType: '',
    emergencyContacts: [{ firstName: '', lastName: '', relationship: '', phoneNumber: '' }],
    role: { name: '' },
    status: 'active',
  });

  // UI State
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);

  // Profile picture cropping
  const [profileImage, setProfileImage] = useState(null);   // raw file
  const [previewImage, setPreviewImage] = useState(null);   // Base64
  const [scale, setScale] = useState(1);                    // Zoom scaling
  const editorRef = useRef(null);

  // Permissions
  const hasViewPermission = permissions.includes('view_user-profile');
  const hasEditPermission = permissions.includes('edit_user-profile');

  // API Base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // -----------------------
  // Fetch user data
  // -----------------------
  const fetchUserData = useCallback(async () => {
    if (!hasViewPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this profile.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      navigate('/home');
      setLoading(false);
      return;
    }

    try {
      const token = getUserToken();
      const response = await axios.get(`${API_BASE_URL}/api/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data) {
        setUser(response.data);
      } else {
        MySwal.fire({
          title: 'Error',
          text: 'Failed to fetch user data.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      MySwal.fire({
        title: 'Error',
        text: 'An error occurred while fetching user data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoading(false);
    }
  }, [hasViewPermission, navigate, userId, API_BASE_URL]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  // -----------------------
  // Handle tabs
  // -----------------------
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // -----------------------
  // Handle input changes
  // -----------------------
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      setUser((prevState) => ({ ...prevState, [name]: formatPhoneNumber(value) }));
    } else {
      setUser((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // -----------------------
  // Save (general info)
  // -----------------------
  const handleSave = async () => {
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this profile.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    try {
      const token = getUserToken();
      const { _id, ...userData } = user;

      // PUT /api/users/:id
      const response = await axios.put(`${API_BASE_URL}/api/users/${_id}`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data) {
        setUser(response.data);
        setEditMode(false);
        MySwal.fire({
          title: 'Success',
          text: 'Profile updated successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      } else {
        MySwal.fire({
          title: 'Error',
          text: 'Failed to update user data.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Error updating user data:', error);
      MySwal.fire({
        title: 'Error',
        text: 'An unknown error occurred. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // -----------------------
  // Profile Picture + Cropping
  // -----------------------
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setProfileImage(file);

    // Create Base64 preview
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleScaleChange = (event, newValue) => {
    setScale(newValue);
  };

  // Upload with cropping
  const handleUploadProfilePicture = async () => {
    if (!profileImage) {
      MySwal.fire({
        title: 'No file selected',
        text: 'Please choose a file before saving.',
        icon: 'warning',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this profile.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    try {
      const token = getUserToken();

      // 1) If there's an existing profile picture, remove it from Azure
      if (user.profilePicture) {
        const blobName = extractBlobName(user.profilePicture);
        if (blobName) {
          await axios.delete(`${API_BASE_URL}/api/files/delete`, {
            headers: { Authorization: `Bearer ${token}` },
            data: { blobName },
          });
        }
      }

      // 2) Crop/scale the image from AvatarEditor
      if (!editorRef.current) {
        MySwal.fire({
          title: 'Error',
          text: 'Image editor not available. Please try again.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
        setSaving(false);
        return;
      }
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      const blob = dataURLtoBlob(canvas);

      // 3) Upload to Azure
      const formData = new FormData();
      formData.append('file', blob, profileImage.name);

      const uploadResponse = await axios.post(`${API_BASE_URL}/api/files/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      // 4) Update user's profilePicture in DB
      if (uploadResponse.data && uploadResponse.data.fileUrl) {
        const uploadedUrl = uploadResponse.data.fileUrl;
        const { _id, ...updatedUser } = user;
        updatedUser.profilePicture = uploadedUrl;

        const updateResponse = await axios.put(
          `${API_BASE_URL}/api/users/${_id}`,
          updatedUser,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (updateResponse.data) {
          setUser(updateResponse.data);
          setProfileImage(null);
          setPreviewImage(null);
          MySwal.fire({
            title: 'Success',
            text: 'Profile picture updated successfully.',
            icon: 'success',
            ...customAlertStyles.sweetAlert,
          });
        } else {
          MySwal.fire({
            title: 'Error',
            text: 'Failed to update profile picture in the database.',
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
        }
      } else {
        MySwal.fire({
          title: 'Error',
          text: 'Failed to upload profile picture.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to upload profile picture. Please try again.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleRemoveProfilePicture = async () => {
    if (!user.profilePicture) {
      MySwal.fire({
        title: 'No picture to remove',
        text: 'There is no current profile picture set.',
        icon: 'info',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this profile.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    try {
      const token = getUserToken();
      const blobName = extractBlobName(user.profilePicture);

      // 1) Delete from Azure
      if (blobName) {
        await axios.delete(`${API_BASE_URL}/api/files/delete`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { blobName },
        });
      }

      // 2) Update user doc
      const { _id, ...updatedUser } = user;
      updatedUser.profilePicture = '';

      const updateResponse = await axios.put(
        `${API_BASE_URL}/api/users/${_id}`,
        updatedUser,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (updateResponse.data) {
        setUser(updateResponse.data);
        setPreviewImage(null);
        setProfileImage(null);
        MySwal.fire({
          title: 'Success',
          text: 'Profile picture removed successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      } else {
        MySwal.fire({
          title: 'Error',
          text: 'Failed to remove profile picture.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Error removing profile picture:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to remove profile picture. Please try again.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // -----------------------
  // Early returns if loading/no permission
  // -----------------------
  if (loading || authLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!hasViewPermission) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" color="error">
          You do not have permission to view this page.
        </Typography>
      </Box>
    );
  }

  // -----------------------
  // Main Render
  // -----------------------
  return (
    <ErrorBoundary>
      <Navbar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
          <Box
            sx={{
              marginBottom: 2,
              border: '1px solid #549F93',
              padding: '8px',
              borderRadius: '4px',
              background: 'linear-gradient(to right, #258EA6, #549F93)',
              position: 'relative',
            }}
          >
            {/* Avatar (with or without preview) */}
            <Avatar
              alt={`${user.firstName} ${user.lastName}`}
              src={previewImage || user.profilePicture || undefined}
              sx={{
                width: 160,
                height: 160,
                position: 'absolute',
                top: '50%',
                right: '16px',
                transform: 'translateY(-50%)',
                border: '4px solid #FFFFFF',
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                fontSize: '36px',
                backgroundColor: (previewImage || user.profilePicture) ? 'transparent' : '#BDBDBD',
                color: '#FFFFFF',
              }}
            >
              {/* Show initials if no pic */}
              {!user.profilePicture && !previewImage &&
                `${user.firstName?.[0] || ''}${user.lastName?.[0] || ''}`
              }
            </Avatar>

            <Box sx={{ marginRight: '180px' }}>
              <Typography variant="h6" sx={{ color: '#FFFFFF' }}>
                {user.firstName} {user.lastName}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#EAEAEA' }}>
                Phone: {user.phoneNumber || 'N/A'}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#EAEAEA' }}>
                Address: {user.address || 'N/A'}
              </Typography>
            </Box>

            {expanded && hasEditPermission && (
              <>
                <IconButton
                  onClick={() => setEditMode(true)}
                  sx={{ color: '#FFFFFF' }}
                  aria-label="Edit Profile"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={handleSave}
                  sx={{ color: '#FFFFFF' }}
                  disabled={!editMode || saving}
                  aria-label="Save Profile"
                >
                  {saving ? <CircularProgress size={24} color="inherit" /> : <SaveIcon />}
                </IconButton>
              </>
            )}

            <IconButton
              onClick={() => setExpanded(!expanded)}
              sx={{ color: '#FFFFFF' }}
              aria-label="Toggle Collapse"
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>

          {/* Collapsible section with tabs */}
          <Collapse in={expanded}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="Profile Tabs">
              <Tab label="Personal Information" />
              <Tab label="Job Information" />
              <Tab label="Emergency Contacts" />
              <Tab label="Profile Picture" />
            </Tabs>

            <Box sx={{ marginTop: 2 }}>
              {/* Personal Information */}
              {activeTab === 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="First Name"
                      name="firstName"
                      value={user.firstName || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Last Name"
                      name="lastName"
                      value={user.lastName || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Email"
                      name="email"
                      value={user.email || ''}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      value={user.phoneNumber || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Address"
                      name="address"
                      value={user.address || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                </Grid>
              )}

              {/* Job Information */}
              {activeTab === 1 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Job Title"
                      name="jobTitle"
                      value={user.jobTitle || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Employment Type"
                      name="employmentType"
                      value={user.employmentType || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth disabled>
                      <InputLabel>Role</InputLabel>
                      <Select
                        name="role"
                        value={user.role?.name || 'Role not assigned'}
                        label="Role"
                      >
                        <MenuItem value={user.role?.name || ''}>
                          {user.role?.name || 'Role not assigned'}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth disabled={!editMode}>
                      <InputLabel>Status</InputLabel>
                      <Select
                        name="status"
                        value={user.status || ''}
                        onChange={handleInputChange}
                        label="Status"
                      >
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                        <MenuItem value="away">Away</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {/* Emergency Contacts */}
              {activeTab === 2 && (
                <Box>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    Emergency Contacts
                  </Typography>
                  {user.emergencyContacts?.map((contact, index) => (
                    <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Contact First Name"
                          value={contact.firstName || ''}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setUser((prev) => {
                              const updated = { ...prev };
                              updated.emergencyContacts[index].firstName = newValue;
                              return updated;
                            });
                          }}
                          fullWidth
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Contact Last Name"
                          value={contact.lastName || ''}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setUser((prev) => {
                              const updated = { ...prev };
                              updated.emergencyContacts[index].lastName = newValue;
                              return updated;
                            });
                          }}
                          fullWidth
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Relationship"
                          value={contact.relationship || ''}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setUser((prev) => {
                              const updated = { ...prev };
                              updated.emergencyContacts[index].relationship = newValue;
                              return updated;
                            });
                          }}
                          fullWidth
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Contact Phone Number"
                          value={contact.phoneNumber || ''}
                          onChange={(e) => {
                            const newValue = formatPhoneNumber(e.target.value);
                            setUser((prev) => {
                              const updated = { ...prev };
                              updated.emergencyContacts[index].phoneNumber = newValue;
                              return updated;
                            });
                          }}
                          fullWidth
                          disabled={!editMode}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              )}

              {/* Profile Picture (with cropping) */}
              {activeTab === 3 && (
                <Box>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    Profile Picture
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* If new file selected, show editor */}
                    {profileImage && (
                      <>
                        <AvatarEditor
                          ref={editorRef}
                          image={previewImage}
                          width={250}
                          height={250}
                          border={50}
                          borderRadius={125}
                          color={[255, 255, 255, 0.6]}
                          scale={scale}
                          rotate={0}
                        />
                        <Box sx={{ width: 200, marginTop: 2, marginBottom: 2 }}>
                          <Typography variant="body2" gutterBottom>
                            Zoom
                          </Typography>
                          <Slider
                            value={scale}
                            min={1}
                            max={3}
                            step={0.1}
                            onChange={handleScaleChange}
                          />
                        </Box>
                      </>
                    )}

                    {/* If no new file is selected, just show existing/fallback avatar */}
                    {!profileImage && (
                      <Avatar
                        alt={`${user.firstName} ${user.lastName}`}
                        src={user.profilePicture || '/default-avatar.png'}
                        sx={{ width: 140, height: 140, marginBottom: 2 }}
                      >
                        {!user.profilePicture && `${user.firstName?.[0] || ''}${user.lastName?.[0] || ''}`}
                      </Avatar>
                    )}

                    {/* Edit Mode controls */}
                    {editMode && (
                      <>
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<PhotoCameraIcon />}
                          sx={{ marginBottom: 2 }}
                        >
                          {profileImage ? 'Change Picture' : 'Upload New Picture'}
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleProfileImageChange}
                          />
                        </Button>

                        {/* If we do have a new image, show "Save Picture" */}
                        {profileImage && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUploadProfilePicture}
                            sx={{ marginBottom: 2 }}
                            disabled={saving}
                          >
                            {saving ? <CircularProgress size={24} color="inherit" /> : 'Save Picture'}
                          </Button>
                        )}

                        {/* Remove existing picture button, if user has a picture and hasn't chosen a new one */}
                        {user.profilePicture && !profileImage && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleRemoveProfilePicture}
                            startIcon={<DeleteIcon />}
                            disabled={saving}
                          >
                            Remove Current Picture
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

const WrappedUserProfileScreen = () => (
  <ErrorBoundary>
    <UserProfileScreen />
  </ErrorBoundary>
);

export default WrappedUserProfileScreen;