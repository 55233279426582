// src/screens/UserManagementScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Typography, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent,
  DialogTitle, TextField, TableSortLabel, TablePagination, IconButton, FormControl, InputLabel, Select, MenuItem, Chip, InputAdornment, CircularProgress,
} from '@mui/material';
import {
  Search as SearchIcon,
  AddCircle as AddCircleIcon,
  Upload as UploadIcon,
  Edit as EditIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
} from '@mui/icons-material';
import { purple, red, green, orange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';
import { styles } from '../styles/styles';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const UserManagementScreen = () => {
  const navigate = useNavigate();

  // User data and total count from server
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);

  const [openAddUser, setOpenAddUser] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [file, setFile] = useState(null);

  // Search and Filters
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');

  // Pagination and Sorting
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Form Fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('active');

  // Loading states
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [rolesList, setRolesList] = useState([]);

  // Permissions
  const { permissions = [], loading: authLoading } = useAuth();
  const hasViewPermission = permissions.includes('view_user-management');
  const hasAddPermission = permissions.includes('add_user-management');
  const hasEditPermission = permissions.includes('edit_user-management');
  const hasDeletePermission = permissions.includes('delete_user-management');
  const hasViewUserProfilePermission = permissions.includes('view_user-profile');

  // Fetch roles
  const fetchRoles = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/roles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRolesList(response.data.roles || response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, []);

  // Fetch users from server-side with pagination & filtering
  const fetchUsers = useCallback(async () => {
    if (!hasViewPermission) return;

    setLoading(true);
    try {
      const token = localStorage.getItem('userToken');
      const offset = page * rowsPerPage;

      const response = await axios.get(`${API_BASE_URL}/api/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          populate: 'role',
          search: debouncedSearch,
          statusFilter: statusFilter,
          limit: rowsPerPage,
          offset: offset,
          orderBy: orderBy,
          order: order,
        },
      });

      setUsers(response.data.users || []);
      setTotalUsers(response.data.total || 0);
    } catch (error) {
      console.error('Error fetching users:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch users. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoading(false);
    }
  }, [hasViewPermission, debouncedSearch, statusFilter, rowsPerPage, page, orderBy, order]);

  // Debounce search
  useEffect(() => {
    const timer = setTimeout(() => {
      setPage(0);
      setDebouncedSearch(searchTerm.trim());
    }, 300);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    // Fetch roles right away if we have permissions or are still loading them
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    if (hasViewPermission) {
      fetchUsers();
    }
  }, [fetchUsers, hasViewPermission]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImport = async () => {
    if (!file) {
      Swal.fire({
        title: 'Error',
        text: 'Please select a file to import.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!hasAddPermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to import users.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');
      await axios.post(`${API_BASE_URL}/api/users/import`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchUsers();
      setOpenImport(false);
      setFile(null);

      Swal.fire({
        title: 'Users Imported',
        text: 'The users have been imported successfully!',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      console.error('Error importing users:', error);
      Swal.fire({
        title: 'Error!',
        text: 'There was a problem importing the users.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleAddUser = async () => {
    if (!hasAddPermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to add users.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!firstName || !lastName || !email || !role || !status) {
      Swal.fire({
        title: 'Error',
        text: 'All fields are required.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      Swal.fire({
        title: 'Error',
        text: 'Please enter a valid email address.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');
      await axios.post(
        `${API_BASE_URL}/api/users/preregister`,
        { firstName, lastName, email, role, status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchUsers();
      setFirstName('');
      setLastName('');
      setEmail('');
      setRole('');
      setStatus('active');
      setOpenAddUser(false);

      Swal.fire({
        title: 'User Added',
        text: 'The user has been added successfully!',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      console.error('Error adding user:', error);
      Swal.fire({
        title: 'Error!',
        text: 'There was a problem adding the user.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleDeactivateUser = async (userId, isActive) => {
    if (!hasDeletePermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to deactivate users.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const actionText = isActive ? 'deactivate' : 'activate';
    const confirmationText = isActive
      ? 'This user will be marked as inactive.'
      : 'This user will be reactivated.';
    const confirmButtonText = isActive ? 'Yes, deactivate!' : 'Yes, activate!';
    const successTitle = isActive ? 'Deactivated!' : 'Activated!';
    const successText = isActive
      ? 'The user has been marked as inactive.'
      : 'The user has been reactivated.';

    const result = await Swal.fire({
      title: `Are you sure?`,
      text: confirmationText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: 'Cancel',
      ...customAlertStyles.sweetAlert,
    });

    if (result.isConfirmed) {
      setSaving(true);
      try {
        const token = localStorage.getItem('userToken');
        const url = `${API_BASE_URL}/api/users/${userId}/${isActive ? 'inactive' : 'active'}`;
        await axios.put(url, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        fetchUsers();

        Swal.fire({
          title: successTitle,
          text: successText,
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      } catch (error) {
        console.error(`Error ${actionText} user:`, error);
        Swal.fire({
          title: 'Error!',
          text: `There was a problem trying to ${actionText} the user.`,
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <ErrorBoundary>
      <>
        <NavigationBar />
        <Box sx={{ display: 'flex' }}>
          <Sidebar />
          <Box flexGrow={1}>
            <Box sx={{ padding: '24px', marginTop: '64px' }}>
              {/* Handle loading and permissions within the content area */}
              {authLoading || loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                  <CircularProgress aria-label="Loading" />
                </Box>
              ) : !hasViewPermission ? (
                <Box sx={{ padding: '24px', textAlign: 'center' }}>
                  <Typography variant="h6" color="error">
                    You do not have permission to view this page.
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography component="h1" variant="h5" sx={{ marginBottom: '24px' }} aria-label="User Management">
                    User Management
                  </Typography>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}
                  >
                    <TextField
                      variant="outlined"
                      placeholder="Search Users"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      sx={{ width: '60%' }}
                      aria-label="Search Users"
                    />
                    <FormControl sx={{ minWidth: 150 }}>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={statusFilter}
                        label="Status"
                        onChange={handleStatusFilterChange}
                        aria-label="Filter Users by Status"
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                        <MenuItem value="preregistered">Preregistered</MenuItem>
                        <MenuItem value="registered">Registered</MenuItem>
                      </Select>
                    </FormControl>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleIcon />}
                        onClick={() => setOpenAddUser(true)}
                        aria-label="Add User"
                        disabled={!hasAddPermission}
                      >
                        Add User
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<UploadIcon />}
                        onClick={() => setOpenImport(true)}
                        aria-label="Import Users"
                        disabled={!hasAddPermission}
                      >
                        Import
                      </Button>
                    </Box>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="users table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'firstName'}
                              direction={orderBy === 'firstName' ? order : 'asc'}
                              onClick={() => handleSortRequest('firstName')}
                            >
                              First Name
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'lastName'}
                              direction={orderBy === 'lastName' ? order : 'asc'}
                              onClick={() => handleSortRequest('lastName')}
                            >
                              Last Name
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'role'}
                              direction={orderBy === 'role' ? order : 'asc'}
                              onClick={() => handleSortRequest('role')}
                            >
                              Role
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'registration'}
                              direction={orderBy === 'registration' ? order : 'asc'}
                              onClick={() => handleSortRequest('registration')}
                            >
                              Registration Status
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'status'}
                              direction={orderBy === 'status' ? order : 'asc'}
                              onClick={() => handleSortRequest('status')}
                            >
                              Status
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users.length > 0 ? (
                          users.map((user) => (
                            <TableRow key={user._id} hover>
                              <TableCell>{user.firstName}</TableCell>
                              <TableCell>{user.lastName}</TableCell>
                              <TableCell>{user.email}</TableCell>
                              <TableCell>{user.role ? user.role.name : 'N/A'}</TableCell>
                              <TableCell>
                                <Chip
                                  label={
                                    user.registration
                                      ? user.registration.charAt(0).toUpperCase() + user.registration.slice(1)
                                      : 'N/A'
                                  }
                                  sx={{
                                    backgroundColor:
                                      user.registration === 'preregistered'
                                        ? orange[500]
                                        : user.registration === 'registered'
                                        ? green[500]
                                        : 'default',
                                    color: 'white',
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Chip
                                  label={user.status.charAt(0).toUpperCase() + user.status.slice(1)}
                                  sx={{
                                    backgroundColor:
                                      user.status === 'active'
                                        ? green[500]
                                        : user.status === 'inactive'
                                        ? red[500]
                                        : 'default',
                                    color: 'white',
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  sx={{ color: purple[500] }}
                                  onClick={() =>
                                    hasEditPermission && hasViewUserProfilePermission
                                      ? navigate(`/profile/${user._id}`)
                                      : null
                                  }
                                  aria-label={`Edit ${user.firstName} ${user.lastName}`}
                                  disabled={!(hasEditPermission && hasViewUserProfilePermission)}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  sx={{ color: red[500] }}
                                  onClick={() => handleDeactivateUser(user._id, user.status === 'active')}
                                  aria-label={`${user.status === 'active' ? 'Deactivate' : 'Activate'} ${
                                    user.firstName
                                  } ${user.lastName}`}
                                  disabled={!hasDeletePermission}
                                >
                                  {user.status === 'active' ? <ArchiveIcon /> : <UnarchiveIcon />}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              No users found.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={totalUsers}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer>

                  {/* Add User Dialog */}
                  <Dialog
                    open={openAddUser}
                    onClose={() => setOpenAddUser(false)}
                    aria-labelledby="add-user-dialog-title"
                    maxWidth="sm"
                    fullWidth
                  >
                    <DialogTitle id="add-user-dialog-title">Add New User</DialogTitle>
                    <DialogContent dividers>
                      <Box sx={{ padding: 2 }}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          label="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          sx={{ marginBottom: '16px' }}
                          aria-label="First Name"
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          label="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          sx={{ marginBottom: '16px' }}
                          aria-label="Last Name"
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          label="Email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          sx={{ marginBottom: '16px' }}
                          aria-label="Email"
                        />
                        <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                          <InputLabel>Role</InputLabel>
                          <Select
                            value={role}
                            label="Role"
                            onChange={(e) => setRole(e.target.value)}
                            aria-label="Role"
                          >
                            {(rolesList || []).map((roleOption) => (
                              <MenuItem key={roleOption._id} value={roleOption._id}>
                                {roleOption.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select
                            value={status}
                            label="Status"
                            onChange={(e) => setStatus(e.target.value)}
                            aria-label="Status"
                          >
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="inactive">Inactive</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenAddUser(false)} aria-label="Cancel Adding User">
                        Cancel
                      </Button>
                      <Button
                        onClick={handleAddUser}
                        aria-label="Confirm Adding User"
                        disabled={
                          !firstName.trim() ||
                          !lastName.trim() ||
                          !email.trim() ||
                          !role.trim() ||
                          !status.trim() ||
                          saving
                        }
                      >
                        {saving ? <CircularProgress size={24} /> : 'Add User'}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* Import Users Dialog */}
                  <Dialog
                    open={openImport}
                    onClose={() => setOpenImport(false)}
                    aria-labelledby="import-users-dialog-title"
                    maxWidth="sm"
                    fullWidth
                  >
                    <DialogTitle id="import-users-dialog-title">Import Users</DialogTitle>
                    <DialogContent dividers>
                      <Box sx={{ padding: 2, textAlign: 'center' }}>
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadIcon />}
                          aria-label="Choose File"
                        >
                          Choose File
                          <input type="file" hidden onChange={handleFileChange} accept=".csv, .xlsx" />
                        </Button>
                        {file && (
                          <Typography variant="body2" sx={{ marginTop: '8px' }}>
                            Selected File: {file.name}
                          </Typography>
                        )}
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenImport(false)} aria-label="Cancel Importing Users">
                        Cancel
                      </Button>
                      <Button
                        onClick={handleImport}
                        aria-label="Confirm Importing Users"
                        disabled={!file || saving}
                      >
                        {saving ? <CircularProgress size={24} /> : 'Import'}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </>
    </ErrorBoundary>
  );
};

export default UserManagementScreen;