// src/screens/ReportsScreen.js

import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ErrorBoundary from '../components/ErrorBoundary';
import ReportBuilder from '../components/ReportBuilder';

const ReportsScreen = () => {
  return (
    <ErrorBoundary>
      <NavigationBar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1} sx={{ p: 3, marginTop: '64px' }}>
          <Paper sx={{ p: 2 }}>
            <ReportBuilder />
          </Paper>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default ReportsScreen;