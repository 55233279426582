// src/screens/DynamicCarePlan.jsx

import React, { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  Pagination,
  CircularProgress
} from '@mui/material';
import MuiSlider from '@mui/material/Slider';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import customAlertStyles from '../styles/CustomAlertStyles';
import ErrorBoundary from '../components/ErrorBoundary';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const DynamicCarePlan = () => {
  // --------------------------------------------------
  // 1) Detect client vs. care entity
  // --------------------------------------------------
  const { templateID, clientID, careEntityID } = useParams();
  const isEntityMode = Boolean(careEntityID);
  const identifier = careEntityID || clientID;

  const navigate = useNavigate();

  // --------------------------------------------------
  // 2) State for Template, Data, and Custom Tables
  // --------------------------------------------------
  const [carePlanTemplate, setCarePlanTemplate] = useState({ fields: [] });
  const [activeFields, setActiveFields] = useState([]);
  const [archivedFields, setArchivedFields] = useState([]);

  // `carePlanData` is the entire array of records
  const [carePlanData, setCarePlanData] = useState([]);

  // `newEntry` holds the current unsaved data: { [fieldID]: value }
  const [newEntry, setNewEntry] = useState({});

  const [isViewingPastRecord, setIsViewingPastRecord] = useState(false);
  const [createdAt, setCreatedAt] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [version, setVersion] = useState('1.0');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 15;
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // Custom tables
  const [customTables, setCustomTables] = useState([]);
  const [loadingCustomTables, setLoadingCustomTables] = useState(false);

  // --------------------------------------------------
  // 3) Fetch Custom Tables
  // --------------------------------------------------
  const fetchCustomTables = useCallback(async () => {
    try {
      setLoadingCustomTables(true);
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/customtables`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCustomTables(response.data);
    } catch (error) {
      console.error('Error fetching custom tables:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch custom tables.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoadingCustomTables(false);
    }
  }, []);

  // --------------------------------------------------
  // 4) Helpers for Searching / Loading Existing Data
  // --------------------------------------------------
  const getSearchEndpoint = () => {
    return isEntityMode
      ? '/api/careplandata/searchByCareEntityAndTemplate'
      : '/api/careplandata/searchByClientAndTemplate';
  };
  const getSearchParams = () => {
    return isEntityMode
      ? { careEntityID: identifier, templateID }
      : { clientID: identifier, templateID };
  };

  // --------------------------------------------------
  // 5) Fetch the Care Plan Template + Data
  // --------------------------------------------------
  const fetchCarePlan = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('userToken');
      const headers = { Authorization: `Bearer ${token}` };

      // A) Fetch the template
      const templateRes = await axios.get(
        `${API_BASE_URL}/api/careplantemplates/${templateID}`,
        { headers }
      );

      if (templateRes.data && Array.isArray(templateRes.data.fields)) {
        setCarePlanTemplate(templateRes.data);
        const active = templateRes.data.fields.filter((f) => f.state !== 'Archived');
        const archived = templateRes.data.fields.filter((f) => f.state === 'Archived');
        setActiveFields(active);
        setArchivedFields(archived);
        setVersion(templateRes.data.version || '1.0');
      } else {
        setCarePlanTemplate({ fields: [] });
        setActiveFields([]);
        setArchivedFields([]);
      }

      // B) Fetch existing data
      const searchEndpoint = getSearchEndpoint();
      const resp = await axios.get(`${API_BASE_URL}${searchEndpoint}`, {
        headers,
        params: getSearchParams(),
      });

      if (Array.isArray(resp.data)) {
        // Sort descending by createdAt
        const sorted = resp.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setCarePlanData(sorted);
        setFilteredData(sorted);
      } else {
        setCarePlanData([]);
        setFilteredData([]);
      }
    } catch (error) {
      console.error('Error fetching care plan data:', error);
      // ---- REMOVED THE ERROR POP-UP AS REQUESTED ----
    } finally {
      setLoading(false);
    }
  }, [templateID, identifier, isEntityMode]);

  // --------------------------------------------------
  // 6) useEffects
  // --------------------------------------------------
  useEffect(() => {
    fetchCarePlan();
    fetchCustomTables();
  }, [fetchCarePlan, fetchCustomTables]);

  // --------------------------------------------------
  // 7) Handle Saving a New Entry (including deferred file uploads)
  // --------------------------------------------------
  const handleSave = async () => {
    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');
      const headers = { Authorization: `Bearer ${token}` };

      // Collect data from activeFields: create an array of { fieldID, value }
      const formattedData = activeFields.map((field) => ({
        fieldID: field._id.toString(),
        value: newEntry[field._id.toString()] || '',
      }));

      // Gather nested field IDs from custom tables (if applicable)
      const allNestedFieldIDs = [];
      activeFields.forEach((fld) => {
        if (fld.type === 'nested') {
          const tableId =
            typeof fld.customTable === 'object' ? fld.customTable?._id : fld.customTable;
          const tableData = customTables.find((t) => t._id === tableId);
          if (tableData?.fields) {
            tableData.fields.forEach((nested) => allNestedFieldIDs.push(nested._id.toString()));
          }
        }
      });

      const nestedData = allNestedFieldIDs.map((nestedId) => ({
        fieldID: nestedId,
        value: newEntry[nestedId] || '',
      }));

      // Combine top-level and nested data
      const combinedData = [...formattedData, ...nestedData];

      // PROCESS FILE UPLOADS:
      // Iterate through combinedData and, for any entry where value is a File,
      // upload the file and replace the value with the returned file URL.
      for (let i = 0; i < combinedData.length; i++) {
        const record = combinedData[i];
        if (record.value && record.value instanceof File) {
          const fileToUpload = record.value;
          const formData = new FormData();
          formData.append('file', fileToUpload, fileToUpload.name);

          try {
            const uploadResponse = await axios.post(
              `${API_BASE_URL}/api/files/upload`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
            if (uploadResponse.data && uploadResponse.data.fileUrl) {
              record.value = uploadResponse.data.fileUrl;
            } else {
              throw new Error('File upload did not return a valid URL.');
            }
          } catch (uploadError) {
            console.error('Error uploading file for field', record.fieldID, uploadError);
            Swal.fire({
              title: 'Error',
              text: 'Failed to upload one or more files. Please try again.',
              icon: 'error',
              ...customAlertStyles.sweetAlert,
            });
            setSaving(false);
            return;
          }
        }
      }

      // Send the combined data to the backend
      await axios.post(
        `${API_BASE_URL}/api/careplandata`,
        {
          templateID,
          ...(isEntityMode ? { careEntityID: identifier } : { clientID: identifier }),
          data: combinedData,
        },
        { headers }
      );

      Swal.fire({
        title: 'Success',
        text: 'New entry added successfully!',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });

      // Reset state and refetch entries
      setNewEntry({});
      setIsViewingPastRecord(false);
      setCreatedAt(null);
      setLastUpdate(null);
      setVersion('1.0');
      setCurrentPage(1);
      await fetchCarePlan();
    } catch (error) {
      console.error('Error saving entry:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while saving the entry.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // --------------------------------------------------
  // 8) Handle Field Changes
  // --------------------------------------------------
  const handleFieldChange = (fieldID, value) => {
    if (isViewingPastRecord) return;
    setNewEntry((prev) => ({ ...prev, [fieldID]: value }));
  };

  const handleDateChange = () => {
    if (startDate && endDate) {
      const filtered = carePlanData.filter((entry) => {
        const entryDate = new Date(entry.createdAt);
        return entryDate >= startDate && entryDate <= endDate;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(carePlanData);
    }
    setCurrentPage(1);
  };

  // --------------------------------------------------
  // 9) Load a Past Entry (View Only)
  // --------------------------------------------------
  const handleLoadEntry = async (entry) => {
    setIsViewingPastRecord(true);
    try {
      const token = localStorage.getItem('userToken');
      const headers = { Authorization: `Bearer ${token}` };

      const detail = await axios.get(
        `${API_BASE_URL}/api/careplandata/byCarePlanID/${entry.carePlanID}`,
        { headers }
      );

      if (!detail.data || !detail.data.data) {
        return;
      }

      const entryData = {};
      detail.data.data.forEach((f) => {
        entryData[f.fieldID.toString()] = f.value;
      });

      setNewEntry(entryData);
      setCreatedAt(detail.data.createdAt);
      setLastUpdate(detail.data.updatedAt);
      setVersion(detail.data.version || '1.0');
    } catch (error) {
      console.error('Error loading entry:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to load the selected record. Please try again.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  // --------------------------------------------------
  // 10) Back Button
  // --------------------------------------------------
  const handleBackClick = () => {
    if (!isViewingPastRecord) {
      if (Object.keys(newEntry).length > 0) {
        Swal.fire({
          title: 'Unsaved Changes',
          text: 'You have unsaved changes. Would you like to save them before leaving?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Discard',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
          },
          ...customAlertStyles.sweetAlert,
        }).then((result) => {
          if (result.isConfirmed) {
            handleSave();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            navigate(-1);
          }
        });
      } else {
        navigate(-1);
      }
    } else {
      setIsViewingPastRecord(false);
      setNewEntry({});
      setCreatedAt(null);
      setLastUpdate(null);
      setVersion('1.0');
    }
  };

  // --------------------------------------------------
  // 11) Timeline / Pagination (for past entries)
  // --------------------------------------------------
  if (loading || loadingCustomTables) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!carePlanTemplate || !Array.isArray(carePlanTemplate.fields)) {
    return (
      <Typography sx={{ textAlign: 'center', marginTop: 4 }}>
        No care plan template found.
      </Typography>
    );
  }

  const archivedFieldsWithData = archivedFields.filter((field) => {
    return newEntry[field._id?.toString()] !== undefined && newEntry[field._id?.toString()] !== '';
  });

  const totalPages = Math.ceil(filteredData.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

  const renderTimeline = () => {
    if (!filteredData.length) {
      return <Typography>No previous records found.</Typography>;
    }

    return (
      <Box sx={{ marginTop: 2 }}>
        {currentRecords.map((entry) => {
          const entryDate = new Date(entry.createdAt);
          const dayOfWeek = entryDate.toLocaleDateString('en-US', { weekday: 'long' });

          return (
            <Box
              key={entry.carePlanID}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px 16px',
                backgroundColor: '#f9f9f9',
                borderRadius: '8px',
                marginBottom: 2,
                cursor: 'pointer',
                '&:hover': { backgroundColor: '#e0f7fa' },
              }}
              onClick={() => handleLoadEntry(entry)}
            >
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {entryDate.toLocaleString()}
                </Typography>
                <Typography variant="body2" sx={{ color: '#666' }}>
                  {dayOfWeek}
                </Typography>
                <Typography variant="body2" sx={{ color: '#666' }}>
                  {entry.updatedAt ? new Date(entry.updatedAt).toLocaleString() : 'No Date'}
                </Typography>
              </Box>
            </Box>
          );
        })}

        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
          />
        )}
      </Box>
    );
  };

  // --------------------------------------------------
  // 12) Rendering Fields (Including Nested Fields)
  // --------------------------------------------------

  // Helper to render nested fields for custom tables
  const renderNestedField = (nestedField, isEditable) => {
    const nestedID = nestedField._id.toString();
    const nestedValue = newEntry[nestedID] || '';

    switch (nestedField.type) {
      case 'text':
      case 'number':
        return (
          <TextField
            key={nestedID}
            label={nestedField.label}
            value={nestedValue}
            onChange={(e) => handleFieldChange(nestedID, e.target.value)}
            fullWidth
            margin="normal"
            type={nestedField.type === 'number' ? 'number' : 'text'}
            InputProps={{ readOnly: !isEditable }}
            disabled={!isEditable}
          />
        );
      case 'textarea':
        return (
          <TextField
            key={nestedID}
            label={nestedField.label}
            value={nestedValue}
            onChange={(e) => handleFieldChange(nestedID, e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={nestedField.numLines || 4}
            InputProps={{ readOnly: !isEditable }}
            disabled={!isEditable}
          />
        );
      case 'checkbox':
        return (
          <FormControlLabel
            key={nestedID}
            control={
              <Checkbox
                checked={!!nestedValue}
                onChange={(e) => handleFieldChange(nestedID, e.target.checked)}
                disabled={!isEditable}
              />
            }
            label={nestedField.label}
          />
        );
      case 'date':
        return (
          <TextField
            key={nestedID}
            label={nestedField.label}
            type="date"
            value={nestedValue}
            onChange={(e) => handleFieldChange(nestedID, e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: !isEditable }}
            disabled={!isEditable}
          />
        );
      case 'time':
        return (
          <TextField
            key={nestedID}
            label={nestedField.label}
            type="time"
            value={nestedValue}
            onChange={(e) => handleFieldChange(nestedID, e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: !isEditable }}
            disabled={!isEditable}
          />
        );
      default:
        return (
          <Typography key={nestedID} sx={{ fontStyle: 'italic' }}>
            {nestedField.label} ({nestedField.type}) — Not implemented
          </Typography>
        );
    }
  };

  // Main render function for top-level fields.
  // Note: We now handle both "file" and "fileUpload" types.
  const renderField = (field, index, isArchived = false) => {
    const fieldID = field._id?.toString();
    const fieldValue = newEntry[fieldID] || '';
    const isEditable = !isViewingPastRecord && !isArchived;

    const customTableId =
      typeof field.customTable === 'object' ? field.customTable?._id : field.customTable;
    const customTableData = customTables.find((table) => table._id === customTableId);

    switch (field.type) {
      case 'header':
        return (
          <Typography key={index} variant="h5" sx={{ marginTop: 2 }}>
            {field.label}
          </Typography>
        );
      case 'bodyText':
        return (
          <Typography key={index} variant="body1" sx={{ marginTop: 1 }}>
            {field.label}
          </Typography>
        );
      case 'text':
      case 'number':
        return (
          <TextField
            key={index}
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldID, e.target.value)}
            fullWidth
            margin="normal"
            type={field.type === 'number' ? 'number' : 'text'}
            InputProps={{ readOnly: !isEditable }}
            disabled={!isEditable}
          />
        );
      case 'textarea':
        return (
          <TextField
            key={index}
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldID, e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={field.numLines || 4}
            InputProps={{ readOnly: !isEditable }}
            disabled={!isEditable}
          />
        );
      case 'checkbox':
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={!!fieldValue}
                onChange={(e) => handleFieldChange(fieldID, e.target.checked)}
                disabled={!isEditable}
              />
            }
            label={field.label}
          />
        );
      case 'date':
        return (
          <TextField
            key={index}
            label={field.label}
            type="date"
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldID, e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: !isEditable }}
            disabled={!isEditable}
          />
        );
      case 'time':
        return (
          <TextField
            key={index}
            label={field.label}
            type="time"
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldID, e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: !isEditable }}
            disabled={!isEditable}
          />
        );
      case 'select': {
        const selectOptions = field.masterList?.options || [];
        return (
          <FormControl key={index} fullWidth margin="normal" disabled={!isEditable}>
            <InputLabel>{field.label}</InputLabel>
            <Select value={fieldValue} onChange={(e) => handleFieldChange(fieldID, e.target.value)}>
              {selectOptions.map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
      case 'radio': {
        const radioOptions = field.masterList?.options || [];
        return (
          <FormControl key={index} component="fieldset" margin="normal" disabled={!isEditable}>
            <FormLabel component="legend">{field.label}</FormLabel>
            <RadioGroup value={fieldValue} onChange={(e) => handleFieldChange(fieldID, e.target.value)}>
              {radioOptions.map((option, i) => (
                <FormControlLabel
                  key={i}
                  value={option.value}
                  control={<Radio disabled={!isEditable} />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      }
      case 'slider':
        return (
          <Box key={index} sx={{ marginBottom: 2 }}>
            <Typography gutterBottom>{field.label}</Typography>
            <MuiSlider
              value={Number(fieldValue) || 0}
              onChange={(e, val) => handleFieldChange(fieldID, val)}
              disabled={!isEditable}
            />
          </Box>
        );
      // Handle both "file" and "fileUpload" types
      case 'file':
      case 'fileUpload': {
        return (
          <Box key={index} sx={{ marginY: 2 }}>
            <Typography variant="body1">{field.label}</Typography>
            {fieldValue && typeof fieldValue === 'string' ? (
              <>
                <a href={fieldValue} target="_blank" rel="noopener noreferrer">
                  View Uploaded File
                </a>
                <Button variant="outlined" onClick={() => handleFieldChange(fieldID, '')} sx={{ ml: 2 }}>
                  Remove
                </Button>
              </>
            ) : fieldValue && fieldValue instanceof File ? (
              <>
                <Typography variant="body2">
                  {fieldValue.name} (not yet uploaded)
                </Typography>
                <Button variant="outlined" onClick={() => handleFieldChange(fieldID, '')} sx={{ ml: 2 }}>
                  Remove
                </Button>
              </>
            ) : (
              <Button variant="contained" component="label">
                Choose File
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    const selectedFile = e.target.files[0];
                    if (selectedFile) {
                      handleFieldChange(fieldID, selectedFile);
                    }
                  }}
                />
              </Button>
            )}
          </Box>
        );
      }
      // If the field is "nested"
      case 'nested':
        return (
          <Box key={index} sx={{ padding: 2, border: '1px dashed #ccc', marginY: 2 }}>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              {field.label} (Nested Fields)
            </Typography>
            {customTableData?.fields?.map((nestedField) => (
              <Box key={nestedField._id} sx={{ marginLeft: 2 }}>
                {renderNestedField(nestedField, isEditable)}
              </Box>
            ))}
          </Box>
        );
      default:
        return null;
    }
  };

  // --------------------------------------------------
  // 13) Final JSX Rendering
  // --------------------------------------------------
  return (
    <Container maxWidth={false} sx={{ paddingLeft: 0, paddingRight: 0 }}>
      <Box sx={{ padding: 2 }}>
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ marginRight: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            {carePlanTemplate.description || 'Dynamic Care Plan'}
          </Typography>
        </Box>

        {/* Creation & Update Timestamps */}
        {createdAt && (
          <Typography variant="body1">
            Created: {new Date(createdAt).toLocaleString()}
          </Typography>
        )}
        {lastUpdate && (
          <>
            <Typography variant="body1">
              Last Update: {new Date(lastUpdate).toLocaleString()}
            </Typography>
            <Typography variant="body1">Version: {version}</Typography>
          </>
        )}

        <Grid container spacing={2}>
          {/* Left side: new entry or view past record */}
          <Grid item xs={12} md={8}>
            <Typography variant="h6">
              {isViewingPastRecord ? 'Viewing Past Record' : 'New Entry'}
            </Typography>

            {/* Render active fields */}
            {activeFields.map((field, index) => renderField(field, index, false))}

            {/* Render archived fields (when viewing past record and if there is data) */}
            {isViewingPastRecord && archivedFieldsWithData.length > 0 && (
              <Box sx={{ marginTop: 4 }}>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                  Archived Fields
                </Typography>
                {archivedFieldsWithData.map((field, i) => renderField(field, i, true))}
              </Box>
            )}

            {/* Save Button (only if not viewing a past record) */}
            {!isViewingPastRecord && (
              <Button variant="contained" sx={{ marginTop: 2 }} onClick={handleSave} disabled={saving}>
                {saving ? 'Saving...' : 'Save New Entry'}
              </Button>
            )}
          </Grid>

          {/* Right side: timeline/pagination for previous records */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Previous Records</Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start date"
                value={startDate}
                onChange={(val) => {
                  setStartDate(val);
                  handleDateChange();
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth sx={{ marginBottom: 2 }} />
                )}
              />
              <DatePicker
                label="End date"
                value={endDate}
                onChange={(val) => {
                  setEndDate(val);
                  handleDateChange();
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth sx={{ marginBottom: 2 }} />
                )}
              />
            </LocalizationProvider>

            {renderTimeline()}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

// Wrap the component with ErrorBoundary for robust error handling.
const WrappedDynamicCarePlan = (props) => (
  <ErrorBoundary>
    <DynamicCarePlan {...props} />
  </ErrorBoundary>
);

export default WrappedDynamicCarePlan;