/**********************************************
 * src/components/templateBuilder/TemplatePreview.jsx
 **********************************************/

import React from 'react';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  Slider,
  Button,
} from '@mui/material';
import ErrorBoundary from '../ErrorBoundary';
import { useAuth } from '../../context/AuthContext';
import Swal from 'sweetalert2';
import customAlertStyles from '../../styles/CustomAlertStyles';

const TemplatePreview = ({ fields, masterLists = [], customTables = [] }) => {
  const { permissions = [], loading: authLoading } = useAuth();

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');

  if (authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (!hasViewPermission) {
    Swal.fire({
      title: 'Access Denied',
      text: 'You do not have permission to view this page.',
      icon: 'error',
      ...customAlertStyles.sweetAlert,
    });
    return null;
  }

  return (
    <Box>
      {fields
        .filter((field) => field.state !== 'Archived') // Only display fields that are not archived
        .map((field) => {
          // Safely handle if masterList is object or string
          const masterListId =
            typeof field.masterList === 'object'
              ? field.masterList?._id
              : field.masterList;
          const masterListData = masterLists.find((list) => list._id === masterListId);

          // Safely handle if customTable is object or string
          const customTableId =
            typeof field.customTable === 'object'
              ? field.customTable?._id
              : field.customTable;
          const customTableData = customTables.find((table) => table._id === customTableId);

          return (
            <Box key={field.localId} sx={{ marginBottom: '16px' }}>
              {/* HEADER */}
              {field.type === 'header' && (
                <Typography variant="h4" sx={{ marginBottom: '8px' }}>
                  {field.label}
                </Typography>
              )}
              {/* BODY TEXT */}
              {field.type === 'bodyText' && (
                <Typography variant="body1" sx={{ marginBottom: '8px' }}>
                  {field.label}
                </Typography>
              )}
              {/* TEXT FIELD */}
              {field.type === 'text' && (
                <TextField
                  fullWidth
                  label={field.label}
                  placeholder={field.placeholder || ''}
                  defaultValue={field.defaultValue || ''}
                />
              )}
              {/* DATE */}
              {field.type === 'date' && (
                <TextField
                  type="date"
                  fullWidth
                  label={field.label}
                  defaultValue={field.defaultValue || ''}
                  InputLabelProps={{ shrink: true }}
                />
              )}
              {/* CHECKBOX */}
              {field.type === 'checkbox' && (
                <FormControl>
                  <FormLabel>{field.label}</FormLabel>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={field.defaultValue} />}
                    label={field.checkListOption || ''}
                  />
                </FormControl>
              )}
              {/* NUMBER */}
              {field.type === 'number' && (
                <TextField
                  type="number"
                  fullWidth
                  label={field.label}
                  placeholder={field.placeholder || ''}
                  defaultValue={field.defaultValue || ''}
                />
              )}
              {/* TEXTAREA */}
              {field.type === 'textarea' && (
                <TextField
                  multiline
                  rows={field.numLines || 4}
                  fullWidth
                  label={field.label}
                  placeholder={field.placeholder || ''}
                  defaultValue={field.defaultValue || ''}
                />
              )}
              {/* SELECT */}
              {field.type === 'select' && (
                <FormControl fullWidth>
                  <InputLabel>{field.label}</InputLabel>
                  <Select
                    defaultValue={field.defaultValue || ''}
                    label={field.label}
                  >
                    {masterListData?.options?.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {/* RADIO */}
              {field.type === 'radio' && (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{field.label}</FormLabel>
                  <RadioGroup defaultValue={field.defaultValue || ''}>
                    {masterListData?.options?.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
              {/* SLIDER */}
              {field.type === 'slider' && (
                <Box>
                  <Typography gutterBottom>{field.label}</Typography>
                  <Slider defaultValue={50} aria-labelledby="continuous-slider" />
                </Box>
              )}
              {/* FILE UPLOAD */}
              {field.type === 'fileUpload' && (
                <Box>
                  <Typography variant="body1">{field.label}</Typography>
                  <Button variant="contained" component="label">
                    {field.buttonText || 'UPLOAD FILE'}
                    <input type="file" hidden />
                  </Button>
                </Box>
              )}
              {/* TIME */}
              {field.type === 'time' && (
                <TextField
                  type="time"
                  fullWidth
                  label={field.label}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              {/* CUSTOM TABLE (NESTED) */}
              {field.type === 'nested' && (
                <Box>
                  <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                    {field.label}
                  </Typography>
                  {customTableData?.fields?.map((nestedField, idx) => {
                    // For each nestedField, we can again safely handle references
                    const nestedMasterListId =
                      typeof nestedField.masterList === 'object'
                        ? nestedField.masterList?._id
                        : nestedField.masterList;
                    const nestedMasterListData = masterLists.find(
                      (list) => list._id === nestedMasterListId
                    );

                    return (
                      <Box key={nestedField._id || idx} sx={{ marginBottom: '8px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          {nestedField.label} ({nestedField.type})
                        </Typography>

                        {nestedField.type === 'text' && (
                          <TextField
                            fullWidth
                            label={nestedField.label}
                            placeholder={nestedField.placeholder || ''}
                            defaultValue={nestedField.defaultValue || ''}
                          />
                        )}
                        {nestedField.type === 'date' && (
                          <TextField
                            type="date"
                            fullWidth
                            label={nestedField.label}
                            defaultValue={nestedField.defaultValue || ''}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                        {nestedField.type === 'checkbox' && (
                          <FormControl>
                            <FormLabel>{nestedField.label}</FormLabel>
                            <FormControlLabel
                              control={<Checkbox defaultChecked={nestedField.defaultValue} />}
                              label={nestedField.checkListOption || ''}
                            />
                          </FormControl>
                        )}
                        {nestedField.type === 'number' && (
                          <TextField
                            type="number"
                            fullWidth
                            label={nestedField.label}
                            placeholder={nestedField.placeholder || ''}
                            defaultValue={nestedField.defaultValue || ''}
                          />
                        )}
                        {nestedField.type === 'textarea' && (
                          <TextField
                            multiline
                            rows={nestedField.numLines || 4}
                            fullWidth
                            label={nestedField.label}
                            placeholder={nestedField.placeholder || ''}
                            defaultValue={nestedField.defaultValue || ''}
                          />
                        )}
                        {nestedField.type === 'select' && (
                          <FormControl fullWidth>
                            <InputLabel>{nestedField.label}</InputLabel>
                            <Select defaultValue={nestedField.defaultValue || ''}>
                              {nestedMasterListData?.options?.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {nestedField.type === 'radio' && (
                          <FormControl component="fieldset">
                            <FormLabel component="legend">{nestedField.label}</FormLabel>
                            <RadioGroup defaultValue={nestedField.defaultValue || ''}>
                              {nestedMasterListData?.options?.map((option, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={option.value}
                                  control={<Radio />}
                                  label={option.label}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        )}
                        {nestedField.type === 'fileUpload' && (
                          <Box>
                            <Typography variant="body1">{nestedField.label}</Typography>
                            <Button variant="contained" component="label">
                              {nestedField.buttonText || 'UPLOAD FILE'}
                              <input type="file" hidden />
                            </Button>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          );
        })}
    </Box>
  );
};

const WrappedTemplatePreview = (props) => (
  <ErrorBoundary>
    <TemplatePreview {...props} />
  </ErrorBoundary>
);

export default WrappedTemplatePreview;