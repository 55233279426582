// src/screens/StaticCarePlans.jsx

import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  IconButton,
  Slider,
  FormLabel,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  CircularProgress
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';
import ErrorBoundary from '../components/ErrorBoundary';

// This is your server base URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

/**
 * StaticCarePlans Screen
 * 
 * This screen renders a care plan form based on a care plan template.
 * It supports deferred file uploads. Files selected by the user are stored 
 * in state as a File object. Upon save, any File objects are uploaded to
 * Azure Blob Storage and replaced with the returned file URL.
 */
const StaticCarePlans = () => {
  // 1) Detect client vs. care entity from URL parameters
  const { templateID, clientID, careEntityID } = useParams();
  const isEntityMode = Boolean(careEntityID);
  const identifier = careEntityID || clientID;
  const navigate = useNavigate();

  // 2) State Variables
  const [carePlanTemplate, setCarePlanTemplate] = useState({ fields: [] });
  const [activeFields, setActiveFields] = useState([]);
  const [archivedFields, setArchivedFields] = useState([]);
  const [carePlanData, setCarePlanData] = useState({ data: [] });
  const [initialData, setInitialData] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // -- State for custom tables
  const [customTables, setCustomTables] = useState([]);
  const [loadingCustomTables, setLoadingCustomTables] = useState(false);

  // ----------------------------------------------------------------
  // 3) Fetch the care plan template + data
  // ----------------------------------------------------------------
  const fetchCarePlan = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('userToken');
      const headers = { Authorization: `Bearer ${token}` };

      // A) Fetch the care plan template by ID
      const templateResponse = await axios.get(
        `${API_BASE_URL}/api/careplantemplates/${templateID}`,
        { headers }
      );

      if (templateResponse.data && templateResponse.data.fields) {
        setCarePlanTemplate(templateResponse.data);
        const active = templateResponse.data.fields.filter((f) => f.state !== 'Archived');
        const archived = templateResponse.data.fields.filter((f) => f.state === 'Archived');
        setActiveFields(active);
        setArchivedFields(archived);
      } else {
        setCarePlanTemplate({ fields: [] });
        setActiveFields([]);
        setArchivedFields([]);
      }

      // B) Fetch existing care plan data
      const params = {
        templateID,
        ...(isEntityMode ? { careEntityID: identifier } : { clientID: identifier }),
      };
      const dataResponse = await axios.get(`${API_BASE_URL}/api/careplandata/search`, {
        headers,
        params,
      });

      if (dataResponse.data && Array.isArray(dataResponse.data.data)) {
        setCarePlanData(dataResponse.data);
        setInitialData(dataResponse.data);
        setCreatedAt(dataResponse.data.createdAt);
        setLastUpdate(dataResponse.data.updatedAt);
      } else {
        setCarePlanData({ data: [] });
        setInitialData(null);
      }
    } catch (error) {
      console.error('Error fetching care plan data:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to load care plan data. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoading(false);
    }
  }, [templateID, identifier, isEntityMode]);

  // 3B) Fetch custom tables if applicable
  const fetchCustomTables = useCallback(async () => {
    try {
      setLoadingCustomTables(true);
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/customtables`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCustomTables(response.data);
    } catch (error) {
      console.error('Error fetching custom tables:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch custom tables.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoadingCustomTables(false);
    }
  }, []);

  useEffect(() => {
    fetchCarePlan();
    fetchCustomTables();
  }, [fetchCarePlan, fetchCustomTables]);

  // ----------------------------------------------------------------
  // 4) Handle Save (including deferred file uploads)
  // ----------------------------------------------------------------
  const handleSave = async () => {
    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');
      const headers = { Authorization: `Bearer ${token}` };

      if (!carePlanData._id) {
        Swal.fire({
          title: 'Error',
          text: 'Cannot update care plan data. Please try again.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
        setSaving(false);
        return;
      }

      // Create a deep copy of the current data to process file uploads.
      const updatedDataArray = [...carePlanData.data];

      // Process each field in the carePlanData.
      for (let i = 0; i < updatedDataArray.length; i++) {
        const fieldEntry = updatedDataArray[i];

        // If this field value is a File instance, we need to upload it.
        if (fieldEntry && fieldEntry.value instanceof File) {
          const fileToUpload = fieldEntry.value;
          const formData = new FormData();
          formData.append('file', fileToUpload, fileToUpload.name);

          try {
            // Upload the file to Azure Blob Storage.
            const uploadResponse = await axios.post(
              `${API_BASE_URL}/api/files/upload`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data',
                },
              }
            );

            if (uploadResponse.data && uploadResponse.data.fileUrl) {
              // Replace the File object with the uploaded file URL.
              updatedDataArray[i].value = uploadResponse.data.fileUrl;
            } else {
              throw new Error('File upload did not return a valid URL.');
            }
          } catch (uploadError) {
            console.error('Error uploading file for field', fieldEntry.fieldID, uploadError);
            Swal.fire({
              title: 'Error',
              text: 'Failed to upload one or more files. Please try again.',
              icon: 'error',
              ...customAlertStyles.sweetAlert,
            });
            setSaving(false);
            return;
          }
        }
      }

      // Create the updated care plan data object (with file fields now containing URLs).
      const updatedCarePlanData = {
        ...carePlanData,
        data: updatedDataArray,
        ...(isEntityMode ? { careEntityID: identifier } : { clientID: identifier }),
      };

      // Send the updated care plan data to the backend.
      await axios.put(`${API_BASE_URL}/api/careplandata/${carePlanData._id}`, updatedCarePlanData, {
        headers,
      });

      setLastUpdate(new Date());
      setInitialData(updatedCarePlanData);

      Swal.fire({
        title: 'Success',
        text: 'Care plan updated successfully!',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      console.error('Error updating care plan:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while updating the care plan.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // ----------------------------------------------------------------
  // 5) Field Change Handler
  // ----------------------------------------------------------------
  const handleFieldChange = (fieldID, value) => {
    // Update the `carePlanData.data` array with new or updated values.
    setCarePlanData((prev) => {
      const updatedData = prev.data.map((item) =>
        item.fieldID === fieldID ? { ...item, value } : item
      );

      // If the field was not present in data, add it.
      if (!updatedData.some((item) => item.fieldID === fieldID)) {
        updatedData.push({ fieldID, value });
      }

      return { ...prev, data: updatedData };
    });
  };

  // ----------------------------------------------------------------
  // 6) Back Button Logic (warn unsaved changes)
  // ----------------------------------------------------------------
  const handleBackClick = () => {
    // Compare initial and current care plan data.
    if (JSON.stringify(initialData) === JSON.stringify(carePlanData)) {
      navigate(-1);
    } else {
      Swal.fire({
        title: 'Unsaved Changes',
        text: 'You have unsaved changes. Would you like to save them before leaving?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save',
        cancelButtonText: 'Discard',
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
        },
        ...customAlertStyles.sweetAlert,
      }).then((result) => {
        if (result.isConfirmed) {
          handleSave();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          navigate(-1);
        }
      });
    }
  };

  // ----------------------------------------------------------------
  // 7) Render Field (including nested fields)
  // ----------------------------------------------------------------

  /**
   * Renders a nested field. The same logic used by top-level fields is reused.
   */
  const renderNestedField = (nestedField, isArchived = false) => {
    const nestedFieldID = nestedField._id.toString();
    const fieldValue =
      carePlanData.data.find((item) => item.fieldID === nestedFieldID)?.value || '';
    const readOnly = isArchived ? { readOnly: true } : {};

    switch (nestedField.type) {
      case 'text':
      case 'number':
        return (
          <TextField
            key={nestedFieldID}
            label={nestedField.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(nestedFieldID, e.target.value)}
            fullWidth
            margin="normal"
            type={nestedField.type === 'number' ? 'number' : 'text'}
            InputProps={readOnly}
            disabled={isArchived}
          />
        );
      case 'date':
        return (
          <TextField
            key={nestedFieldID}
            label={nestedField.label}
            type="date"
            value={fieldValue}
            onChange={(e) => handleFieldChange(nestedFieldID, e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={readOnly}
            disabled={isArchived}
          />
        );
      case 'checkbox':
        return (
          <FormControlLabel
            key={nestedFieldID}
            control={
              <Checkbox
                checked={!!fieldValue}
                onChange={(e) => handleFieldChange(nestedFieldID, e.target.checked)}
                disabled={isArchived}
              />
            }
            label={nestedField.label}
          />
        );
      case 'time':
        return (
          <TextField
            key={nestedFieldID}
            label={nestedField.label}
            type="time"
            value={fieldValue}
            onChange={(e) => handleFieldChange(nestedFieldID, e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={readOnly}
            disabled={isArchived}
          />
        );
      case 'textarea':
        return (
          <TextField
            key={nestedFieldID}
            label={nestedField.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(nestedFieldID, e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={nestedField.numLines || 4}
            InputProps={readOnly}
            disabled={isArchived}
          />
        );
      default:
        return (
          <Typography key={nestedFieldID} sx={{ fontStyle: 'italic' }}>
            {nestedField.label} ({nestedField.type}) - Not yet implemented
          </Typography>
        );
    }
  };

  /**
   * Renders a top-level field based on its type. Added support for deferred file uploads.
   * Note: We now handle both "file" and "fileUpload" types.
   */
  const renderField = (field, index, isArchived = false) => {
    const fieldID = field._id.toString();
    const fieldValue = carePlanData.data.find((item) => item.fieldID === fieldID)?.value || '';
    const customTableId =
      typeof field.customTable === 'object' ? field.customTable?._id : field.customTable;
    const customTableData = customTables.find((table) => table._id === customTableId);

    switch (field.type) {
      case 'header':
        return (
          <Typography key={index} variant="h5" sx={{ marginTop: 2 }}>
            {field.label}
          </Typography>
        );
      case 'bodyText':
        return (
          <Typography key={index} variant="body1" sx={{ marginTop: 1 }}>
            {field.label}
          </Typography>
        );
      case 'text':
      case 'number':
        return (
          <TextField
            key={index}
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldID, e.target.value)}
            fullWidth
            margin="normal"
            type={field.type === 'number' ? 'number' : 'text'}
            InputProps={{ readOnly: isArchived }}
          />
        );
      case 'textarea':
        return (
          <TextField
            key={index}
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldID, e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={field.numLines || 4}
            InputProps={{ readOnly: isArchived }}
          />
        );
      case 'checkbox':
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={!!fieldValue}
                onChange={(e) => handleFieldChange(fieldID, e.target.checked)}
                disabled={isArchived}
              />
            }
            label={field.label}
          />
        );
      case 'date':
        return (
          <TextField
            key={index}
            label={field.label}
            type="date"
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldID, e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: isArchived }}
          />
        );
      case 'time':
        return (
          <TextField
            key={index}
            label={field.label}
            type="time"
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldID, e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: isArchived }}
          />
        );
      case 'select': {
        const selectOptions = field.masterList?.options || [];
        return (
          <FormControl key={index} fullWidth margin="normal" disabled={isArchived}>
            <InputLabel>{field.label}</InputLabel>
            <Select value={fieldValue} onChange={(e) => handleFieldChange(fieldID, e.target.value)}>
              {selectOptions.length > 0 ? (
                selectOptions.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  No options available
                </MenuItem>
              )}
            </Select>
          </FormControl>
        );
      }
      case 'radio': {
        const radioOptions = field.masterList?.options || [];
        return (
          <FormControl key={index} component="fieldset" margin="normal" disabled={isArchived}>
            <FormLabel component="legend">{field.label}</FormLabel>
            <RadioGroup
              value={fieldValue}
              onChange={(e) => handleFieldChange(fieldID, e.target.value)}
            >
              {radioOptions.length > 0 ? (
                radioOptions.map((option, i) => (
                  <FormControlLabel
                    key={i}
                    value={option.value}
                    control={<Radio disabled={isArchived} />}
                    label={option.label}
                  />
                ))
              ) : (
                <Typography>No options available</Typography>
              )}
            </RadioGroup>
          </FormControl>
        );
      }
      case 'slider':
        return (
          <Box key={index} sx={{ marginBottom: 2 }}>
            <Typography gutterBottom>{field.label}</Typography>
            <Slider
              value={Number(fieldValue) || 0}
              onChange={(e, val) => handleFieldChange(fieldID, val)}
              disabled={isArchived}
            />
          </Box>
        );
      // Handle both "file" and "fileUpload" types in the same case.
      case 'file':
      case 'fileUpload': {
        return (
          <Box key={index} sx={{ marginY: 2 }}>
            <Typography variant="body1">{field.label}</Typography>
            {fieldValue && typeof fieldValue === 'string' ? (
              <>
                <a href={fieldValue} target="_blank" rel="noopener noreferrer">
                  View Uploaded File
                </a>
                <Button variant="outlined" onClick={() => handleFieldChange(fieldID, '')} sx={{ ml: 2 }}>
                  Remove
                </Button>
              </>
            ) : fieldValue && fieldValue instanceof File ? (
              <>
                <Typography variant="body2">
                  {fieldValue.name} (not yet uploaded)
                </Typography>
                <Button variant="outlined" onClick={() => handleFieldChange(fieldID, '')} sx={{ ml: 2 }}>
                  Remove
                </Button>
              </>
            ) : (
              <Button variant="contained" component="label">
                Choose File
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    const selectedFile = e.target.files[0];
                    if (selectedFile) {
                      handleFieldChange(fieldID, selectedFile);
                    }
                  }}
                />
              </Button>
            )}
          </Box>
        );
      }
      // -----------------------------
      // If the field is "nested":
      // -----------------------------
      case 'nested':
        return (
          <Box key={index} sx={{ padding: 2, border: '1px dashed #ccc', marginY: 2 }}>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
              {field.label} (Nested Fields)
            </Typography>
            {customTableData?.fields?.map((nestedField) => (
              <Box key={nestedField._id} sx={{ marginLeft: 3 }}>
                {renderNestedField(nestedField, isArchived)}
              </Box>
            ))}
          </Box>
        );
      default:
        return null;
    }
  };

  // ----------------------------------------------------------------
  // 8) Rendering the Component
  // ----------------------------------------------------------------
  if (loading || loadingCustomTables) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!carePlanTemplate || !Array.isArray(carePlanTemplate.fields)) {
    return (
      <Typography sx={{ textAlign: 'center', marginTop: 4 }}>
        No care plan template found.
      </Typography>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <IconButton onClick={handleBackClick} sx={{ marginRight: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          {carePlanTemplate.description || 'Static Care Plan'}
        </Typography>
      </Box>

      {/* Creation & Update Timestamps */}
      {createdAt && (
        <Typography variant="body1">
          Created: {new Date(createdAt).toLocaleString()}
        </Typography>
      )}
      {lastUpdate && (
        <>
          <Typography variant="body1">
            Last Update: {new Date(lastUpdate).toLocaleString()}
          </Typography>
          <Typography variant="body1">Version: {carePlanTemplate.version}</Typography>
        </>
      )}

      {/* Active Fields */}
      <Box sx={{ marginTop: 2 }}>
        {activeFields.map((field, index) => renderField(field, index, false))}
      </Box>

      {/* Archived Fields */}
      {archivedFields.length > 0 && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Archived Fields
          </Typography>
          {archivedFields.map((field, index) => renderField(field, index, true))}
        </Box>
      )}

      {/* Save Button */}
      <Button variant="contained" sx={{ marginTop: 2 }} onClick={handleSave} disabled={saving}>
        {saving ? 'Saving...' : 'Save'}
      </Button>
    </Box>
  );
};

// Wrap the component with an ErrorBoundary for robust error handling.
const WrappedStaticCarePlans = (props) => (
  <ErrorBoundary>
    <StaticCarePlans {...props} />
  </ErrorBoundary>
);

export default WrappedStaticCarePlans;