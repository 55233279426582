// src/screens/CareEntityProfileScreen.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Collapse,
  Button,
  Avatar,
  Slider,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

// Components
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ErrorBoundary from '../components/ErrorBoundary';
import CareEntityCarePlans from '../components/CareEntityCarePlans';

// Auth / Context
import { useAuth } from '../context/AuthContext';

// Alerting
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import customAlertStyles from '../styles/CustomAlertStyles';

import AvatarEditor from 'react-avatar-editor';

const MySwal = withReactContent(Swal);

// --- Phone number formatter ---
function formatPhoneNumber(value) {
  const cleaned = (value || '').replace(/[^\d]/g, '');
  const length = cleaned.length;

  if (length < 4) {
    return cleaned;
  } else if (length < 7) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  } else {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
  }
}

// --- Extract blob name from Azure URL (remove query params) ---
function extractBlobName(fileUrl) {
  if (!fileUrl) return '';
  const urlWithoutQuery = fileUrl.split('?')[0];
  return urlWithoutQuery.split('/').pop();
}

// --- Utility to convert dataURL to Blob for upload ---
function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',');
  const mimeMatch = arr[0].match(/:(.*?);/);
  if (!mimeMatch) return null;
  const mime = mimeMatch[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const CareEntityProfileScreen = () => {
  // ID from URL
  const { id: careEntityId } = useParams();

  // Auth & Permissions
  const { permissions = [], loading: authLoading } = useAuth();
  const hasViewPermission = permissions.includes('view_care-entity-profile');
  const hasEditPermission = permissions.includes('edit_care-entity-profile');

  // API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // State
  const [careEntity, setCareEntity] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // For uploading profile pictures with cropping
  const [profileImage, setProfileImage] = useState(null); // Raw File
  const [previewImage, setPreviewImage] = useState(null); // Base64 preview for the Editor
  const [scale, setScale] = useState(1); // Zoom scale
  const editorRef = useRef(null); // Ref to AvatarEditor

  // ---------------------------
  //  Fetch Data
  // ---------------------------
  const fetchCareEntity = useCallback(async () => {
    try {
      if (!hasViewPermission) {
        MySwal.fire({
          title: 'Access Denied',
          text: 'You do not have permission to view this page.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
        setLoading(false);
        return;
      }

      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/careentities/${careEntityId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCareEntity(response.data);
    } catch (error) {
      console.error('Error fetching care entity:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch care entity data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoading(false);
    }
  }, [careEntityId, API_BASE_URL, hasViewPermission]);

  useEffect(() => {
    fetchCareEntity();
  }, [fetchCareEntity]);

  // ---------------------------
  //  Expand / Edit / Save
  // ---------------------------
  const toggleExpand = () => setExpanded((prev) => !prev);

  const handleEditClick = () => {
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this care entity.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    setEditMode(true);
  };

  const handleSaveClick = async () => {
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to save changes.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    setSaving(true);

    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.put(
        `${API_BASE_URL}/api/careentities/${careEntityId}`,
        careEntity,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Log the server response to inspect its shape
      console.log('Update response:', response.data);

      // Remember your route returns { message, careEntity }, so we may need:
      if (response.data && response.data.careEntity) {
        // Use the updated careEntity from the response
        setCareEntity(response.data.careEntity);
        MySwal.fire({
          title: 'Success',
          text: 'Care entity profile saved successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
        setEditMode(false);
      } else {
        MySwal.fire({
          title: 'Error',
          text: 'Failed to save care entity data (no careEntity in the response).',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Error saving care entity data:', error);
      MySwal.fire({
        title: 'Error',
        text: 'An unknown error occurred. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // ---------------------------
  //  Tabs
  // ---------------------------
  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  // ---------------------------
  //  Handle Form Inputs
  // ---------------------------
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      setCareEntity((prev) => ({ ...prev, [name]: formatPhoneNumber(value) }));
    } else {
      setCareEntity((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleManagerSelect = (e) => {
    const { value } = e.target;
    setCareEntity((prev) => ({ ...prev, manager: value }));
  };

  // ---------------------------
  //  Profile Picture + Cropping
  // ---------------------------
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Optional checks: size limit, mime type, etc.
    setProfileImage(file);

    // Create a preview
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleScaleChange = (event, newValue) => {
    setScale(newValue);
  };

  const handleProfileImageUpload = async () => {
    if (!profileImage) {
      MySwal.fire({
        title: 'No file selected',
        text: 'Please choose a file before saving.',
        icon: 'warning',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this care entity.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    try {
      const token = localStorage.getItem('userToken');

      // 1) If there's an existing profile picture, remove from Azure first
      if (careEntity.profilePicture) {
        const oldBlobName = extractBlobName(careEntity.profilePicture);
        if (oldBlobName) {
          await axios.delete(`${API_BASE_URL}/api/files/delete`, {
            headers: { Authorization: `Bearer ${token}` },
            data: { blobName: oldBlobName },
          });
        }
      }

      // 2) Get the cropped image from AvatarEditor
      if (!editorRef.current) {
        MySwal.fire({
          title: 'Error',
          text: 'Image editor not available. Please try again.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
        return;
      }
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      const blob = dataURLtoBlob(canvas);

      // 3) Upload the new file
      const formData = new FormData();
      formData.append('file', blob, profileImage.name);

      const uploadResponse = await axios.post(
        `${API_BASE_URL}/api/files/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (uploadResponse.data && uploadResponse.data.fileUrl) {
        // 4) Update DB with new URL
        const fileUrl = uploadResponse.data.fileUrl;
        const dbResponse = await axios.put(
          `${API_BASE_URL}/api/careentities/${careEntityId}`,
          { profilePicture: fileUrl },
          {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          }
        );

        if (dbResponse.data && dbResponse.data.careEntity) {
          setCareEntity(dbResponse.data.careEntity);
          MySwal.fire({
            title: 'Success',
            text: 'Profile picture updated successfully.',
            icon: 'success',
            ...customAlertStyles.sweetAlert,
          });
          setProfileImage(null);
          setPreviewImage(null);
        } else {
          MySwal.fire({
            title: 'Error',
            text: 'Failed to update profile picture in the database.',
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
        }
      } else {
        MySwal.fire({
          title: 'Error',
          text: 'Failed to upload profile picture to Azure.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      MySwal.fire({
        title: 'Error',
        text: 'An unknown error occurred. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  const handleProfileImageRemove = async () => {
    if (!careEntity.profilePicture) {
      MySwal.fire({
        title: 'No picture to remove',
        text: 'There is no current profile picture set.',
        icon: 'info',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this care entity.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      const oldBlobName = extractBlobName(careEntity.profilePicture);
      if (oldBlobName) {
        await axios.delete(`${API_BASE_URL}/api/files/delete`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { blobName: oldBlobName },
        });
      }

      // Update DB: set profilePicture to ''
      const dbResponse = await axios.put(
        `${API_BASE_URL}/api/careentities/${careEntityId}`,
        { profilePicture: '' },
        {
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        }
      );

      if (dbResponse.data && dbResponse.data.careEntity) {
        setCareEntity(dbResponse.data.careEntity);
        setPreviewImage(null);
        setProfileImage(null);
        MySwal.fire({
          title: 'Success',
          text: 'Profile picture removed successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      } else {
        MySwal.fire({
          title: 'Error',
          text: 'Failed to remove profile picture from the database.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Error removing profile picture:', error);
      MySwal.fire({
        title: 'Error',
        text: 'An unknown error occurred while removing the picture. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  // ---------------------------
  //  Render Conditions
  // ---------------------------
  if (!hasViewPermission && !loading && !authLoading) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" color="error">
          You do not have permission to view this page.
        </Typography>
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <Navbar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
          {loading || authLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  marginBottom: 2,
                  border: '1px solid #549F93',
                  padding: '16px',
                  borderRadius: '4px',
                  background: 'linear-gradient(to right, #258EA6, #549F93)',
                  position: 'relative',
                }}
              >
                <Avatar
                  alt={careEntity.name || 'Care Entity'}
                  src={previewImage || careEntity.profilePicture || undefined}
                  sx={{
                    width: 160,
                    height: 160,
                    position: 'absolute',
                    top: '50%',
                    right: '24px',
                    transform: 'translateY(-50%)',
                    border: '4px solid #FFFFFF',
                    boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                    fontSize: '36px',
                    backgroundColor: previewImage || careEntity.profilePicture ? 'transparent' : '#BDBDBD',
                    color: '#FFFFFF',
                  }}
                >
                  {/* Show initial if no pic */}
                  {!careEntity.profilePicture && !previewImage && careEntity.name?.[0]}
                </Avatar>

                <Box sx={{ marginRight: '220px' }}>
                  <Typography variant="h6" sx={{ color: '#FFFFFF', marginBottom: '10px' }}>
                    {careEntity.name || 'Unnamed Care Entity'}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: '#EAEAEA', marginBottom: '8px' }}>
                    Phone: {careEntity.phoneNumber || 'N/A'}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: '#EAEAEA', marginBottom: '20px' }}>
                    Address: {careEntity.address || 'N/A'}
                  </Typography>
                </Box>

                <IconButton
                  onClick={toggleExpand}
                  sx={{ color: '#FFFFFF', position: 'absolute', bottom: '8px', left: '12px' }}
                  aria-label={expanded ? 'Collapse details' : 'Expand details'}
                >
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                {expanded && hasEditPermission && (
                  <>
                    <IconButton
                      onClick={handleEditClick}
                      sx={{ color: '#FFFFFF', position: 'absolute', bottom: '8px', left: '52px' }}
                      aria-label="Edit care entity"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleSaveClick}
                      sx={{ color: '#FFFFFF', position: 'absolute', bottom: '8px', left: '92px' }}
                      disabled={saving}
                      aria-label="Save care entity"
                    >
                      {saving ? <CircularProgress size={24} /> : <SaveIcon />}
                    </IconButton>
                  </>
                )}
              </Box>

              <Collapse in={expanded}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="Care Entity Profile Tabs"
                >
                  <Tab label="General Information" />
                  <Tab label="Assigned Staff" />
                  <Tab label="Assigned Clients" />
                  <Tab label="Profile Picture" />
                </Tabs>
                <Box sx={{ marginTop: 2 }}>
                  {/* General Info */}
                  {activeTab === 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Name"
                          name="name"
                          value={careEntity.name || ''}
                          onChange={handleInputChange}
                          fullWidth
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Type"
                          name="type"
                          value={careEntity.type || ''}
                          onChange={handleInputChange}
                          fullWidth
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Description"
                          name="description"
                          value={careEntity.description || ''}
                          onChange={handleInputChange}
                          fullWidth
                          rows={3}
                          multiline
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Phone Number"
                          name="phoneNumber"
                          value={careEntity.phoneNumber || ''}
                          onChange={handleInputChange}
                          fullWidth
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Address"
                          name="address"
                          value={careEntity.address || ''}
                          onChange={handleInputChange}
                          fullWidth
                          disabled={!editMode}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {/* Assigned Staff */}
                  {activeTab === 1 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Assigned Staff</Typography>
                        <FormControl fullWidth sx={{ marginTop: 2 }}>
                          <InputLabel>Manager</InputLabel>
                          <Select
                            label="Manager"
                            name="manager"
                            value={careEntity.manager?._id || ''}
                            onChange={handleManagerSelect}
                            disabled={!editMode}
                          >
                            <MenuItem value="">None</MenuItem>
                            {careEntity.assignedStaff?.map((staff) => (
                              <MenuItem key={staff._id} value={staff._id}>
                                {staff.firstName} {staff.lastName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Job Title</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {careEntity.assignedStaff?.length ? (
                                careEntity.assignedStaff.map((staff) => (
                                  <TableRow key={staff._id}>
                                    <TableCell>{staff.firstName} {staff.lastName}</TableCell>
                                    <TableCell>{staff.phoneNumber}</TableCell>
                                    <TableCell>{staff.email}</TableCell>
                                    <TableCell>{staff.jobTitle}</TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={4} align="center">
                                    No assigned staff
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  )}

                  {/* Assigned Clients */}
                  {activeTab === 2 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Assigned Clients</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Org Client ID</TableCell>
                                <TableCell>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {careEntity.assignedClients?.length ? (
                                careEntity.assignedClients.map((client) => (
                                  <TableRow key={client._id}>
                                    <TableCell>{client.firstName}</TableCell>
                                    <TableCell>{client.lastName}</TableCell>
                                    <TableCell>{client.orgClientId}</TableCell>
                                    <TableCell>{client.status}</TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={4} align="center">
                                    No assigned clients
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  )}

                  {/* Profile Picture with Cropping */}
                  {activeTab === 3 && (
                    <Box>
                      <Typography variant="h6">Profile Picture</Typography>
                      <Box
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2 }}
                      >
                        {/* If user selected a new image, show cropping editor */}
                        {profileImage && (
                          <>
                            <AvatarEditor
                              ref={editorRef}
                              image={previewImage}
                              width={250}
                              height={250}
                              border={50}
                              borderRadius={125}
                              color={[255, 255, 255, 0.6]}
                              scale={scale}
                              rotate={0}
                            />
                            <Box sx={{ width: 200, marginTop: 2 }}>
                              <Typography variant="body2" gutterBottom>
                                Zoom
                              </Typography>
                              <Slider
                                value={scale}
                                min={1}
                                max={3}
                                step={0.1}
                                onChange={handleScaleChange}
                              />
                            </Box>
                          </>
                        )}

                        {/* If user hasn't selected a new image, just show the current picture or fallback */}
                        {!profileImage && (
                          <Avatar
                            alt={careEntity.name || 'Care Entity'}
                            src={careEntity.profilePicture || undefined}
                            sx={{ width: 140, height: 140, marginBottom: 2 }}
                          >
                            {/* Show initial if no pic */}
                            {!careEntity.profilePicture && careEntity.name?.[0]}
                          </Avatar>
                        )}

                        {editMode && (
                          <>
                            <Button
                              variant="contained"
                              component="label"
                              startIcon={<PhotoCameraIcon />}
                            >
                              {profileImage ? 'Change Picture' : 'Upload New Picture'}
                              <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleProfileImageChange}
                              />
                            </Button>

                            {/* Show "Save Picture" if there's a new image in the editor */}
                            {profileImage && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleProfileImageUpload}
                                sx={{ marginTop: 2 }}
                              >
                                Save Picture
                              </Button>
                            )}

                            {/* Remove existing picture if set */}
                            {careEntity.profilePicture && !profileImage && (
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleProfileImageRemove}
                                startIcon={<DeleteIcon />}
                                sx={{ marginTop: 2 }}
                              >
                                Remove Current Picture
                              </Button>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Collapse>

              {/* Display related care plans */}
              <CareEntityCarePlans careEntityID={careEntityId} />
            </>
          )}
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

const WrappedCareEntityProfileScreen = () => (
  <ErrorBoundary>
    <CareEntityProfileScreen />
  </ErrorBoundary>
);

export default WrappedCareEntityProfileScreen;